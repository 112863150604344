import React from "react";

const FAQ = () => {
  return (
    <div className="faq-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="">Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="accordion projects-boxx" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header ">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    How do I create an account on Sam's Matrimony?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    To create an account on the matrimonial site, you typically
                    need to visit the site's homepage and look for a "Sign Up"
                    or "Register" button. Clicking on this button will usually
                    take you to a registration form where you'll be asked to
                    provide details such as your name, email address, gender,
                    date of birth, and sometimes additional information like
                    religion, caste, and occupation.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    What information should I provide while creating a profile?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    While creating a profile, you should provide accurate and
                    honest information about yourself. This usually includes
                    basic details such as your name, age, gender, education,
                    occupation, height, and other relevant information like
                    hobbies, interests, and family background. Additionally, you
                    may be asked to upload a recent photograph.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    WhatIs it necessary to upload a photo to my profile?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    While it's not always mandatory to upload a photo, having a
                    profile picture significantly increases your chances of
                    getting responses from potential matches. Profiles with
                    photos are generally perceived as more genuine and
                    trustworthy. It's recommended to upload a clear and recent
                    photo that accurately represents you.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header ">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    How can I search for potential matches on the site?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sams Matrimony offers search filters that allow you to
                    refine your search based on specific criteria such as age,
                    location, religion, caste, education, occupation, and more.
                    You can use these filters to narrow down your search and
                    find profiles that match your preferences.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header ">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    Can I specify preferences such as age, religion, or caste
                    while searching for matches?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Yes, you can specify your preferences while searching for
                    matches on the matrimonial site. Most sites offer advanced
                    search options where you can filter profiles based on
                    criteria such as age range, religion, caste, ethnicity,
                    language, education, occupation, and more. This helps you
                    find matches that align with your preferences and
                    requirements.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header ">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                    style={{ backgroundColor: "#f8f8fc", fontWeight: "600" }}
                  >
                    How do I express interest in a profile?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    To express interest in a profile, you can usually find an
                    option like "Express Interest" or "Send Interest" on the
                    profile page of the member you're interested in. Clicking on
                    this option will typically notify the member that you're
                    interested in them, and they can choose to accept or decline
                    your interest. Some sites may also offer features like
                    sending a personalized message along with your interest.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
