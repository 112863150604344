import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import BasicDetails from "../../pages/BasicDetails";
import ReligionDetails from "../../pages/ReligionDetails";
import EducationDetails from "../../pages/EducationDetails";
import FamilyDetails from "../../pages/FamilyDetails";
import { Link } from "react-router-dom";

function StepperForm({ onUpdateUserLogin }) {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  // const prevStep = () => {
  //     setStep(step - 1);
  // };

  const calculateProgress = () => {
    return Math.floor((step / 4) * 100);
  };
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={12}>
            <div className="stepper-top-bar">
              <div>
                <Link to="/">
                  <img src="assets/images/logo/newLogo.png" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="stepper-below-bar">
              <p>
                Great! You have completed{" "}
                <span style={{ fontSize: "30px", fontWeight: 600 }}>
                  {calculateProgress()}%
                </span>
              </p>
            </div>
            <Form>
              {step === 1 && <BasicDetails nextStep={nextStep} />}
              {step === 2 && <ReligionDetails nextStep={nextStep} />}
              {step === 3 && <EducationDetails nextStep={nextStep} />}
              {step === 4 && (
                <FamilyDetails onUpdateUserLogin={onUpdateUserLogin} />
              )}
              {/* {step === 5 && <AboutDetails onSubmit={handleSubmit} />} */}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default StepperForm;
