// export const castes = [
//   "Brahmin",
//   "Kshatriya",
//   "Vaishya",
//   "Shudra",
//   "24 Manai Telugu Chettiar",
//   "96 Kuli maratha",
//   "Achira Pakkam Chettiar",
//   "Adi Andhra",
//   "Agamudayar/ Arcot/ Tuluva Vellala",
//   "Agaram Vellan Chettiar",
//   "Agnikula Kshatriya",
//   "Ahirwar",
//   "Aramari/ Gabit",
//   "Arekatika",
//   "Arya Vysya",
//   "Ayira Vysya",
//   "Ayyaraka",
//   "Bairwa",
//   "Balai",
//   "Balija",
//   "Balija Naidu",
//   "Balija Reddy",
//   "Beri Chettiar",
//   "Besta",
//   "Bhatraju",
//   "Brahmin-Anaviln Desai",
//   "Brahmin-Baidhiki/Vaidhiki",
//   "Brahmin-Bardai",
//   "Brahmin-Bhargav",
//   "Brahmin-Dravida",
//   "Brahmin-Khadayata",
//   "Brahmin-Koteshwara/Kota(Madhwa)",
//   "Brahmin-Madhwa",
//   "Brahmin-Mevada",
//   "Brahmin-Niyogi",
//   "Brahmin-Others",
//   "Brahmin-Rajgor",
//   "Brahmin-Rarhi/Radhi",
//   "Brahmin-Sarua",
//   "Brahmin-Shivalli(Madhwa)",
//   "Brahmin-Shri Gaud",
//   "Brahmin-Shri Mali",
//   "Brahmin-Shukla Yajurvedi",
//   "Brahmin-Smartha",
//   "Brahmin-Sri Vaishnava",
//   "Brahmin-Tapodhan",
//   "Brahmin-Vaidiki",
//   "Brahmin-Vaikhanasa",
//   "Brahmin-Valam",
//   "Brahmin-Velanadu",
//   "Brahmin-Zalora",
//   "Chattada Sri Vaishnava",
//   "Chettiar",
//   "Chippolu(Mera)",
//   "Darji",
//   "Deshmukh",
//   "Devanga",
//   "Devanga Chettiar",
//   "Dhanak",
//   "Dommala",
//   "Dravida Brahmin",
//   "Ediga/Goud(Balija)",
//   "Elur Chetty",
//   "Gajula / Kavarai",
//   "Gandla",
//   "Gandla/Ganiga",
//   "Gavara",
//   "Gond",
//   "Goud",
//   "Gowda",
//   "Gowda-Kuruba Gowda",
//   "Intercaste",
//   "Isai Vellalar",
//   "Jaalari",
//   "Jandra",
//   "Jangam",
//   "Jetty/Malla",
//   "Jhadav",
//   "Julaha",
//   "kachara",
//   "Kaikaala",
//   "Kalinga",
//   "Kalinga Vaisya",
//   "Kamma",
//   "Kamma Naidu",
//   "Kanakkan Padanna",
//   "Kandara",
//   "Kapu",
//   "Kapu Naidu",
//   "Kapu Reddy",
//   "Karakala Bhakthula",
//   "Karni Bhakthula",
//   "Kasukara",
//   "Kavara",
//   "Kerala Mudali",
//   "Khatik",
//   "Kongu Chettiar",
//   "Kongu Nadar",
//   "Kori / Koli",
//   "Kumaoni rajput",
//   "Kummari",
//   "Kunbi Lonari",
//   "Kunbi Maratha",
//   "Kunbi tarale",
//   "Kurni",
//   "Kuruba",
//   "Kuruhini Chetty",
//   "kuruva",
//   "Lingayath",
//   "Madhwa Brahmin",
//   "Madiga",
//   "Mahendra",
//   "Mair Rajput Swarnakar",
//   "Mala",
//   "Malwani",
//   "Manjapudur Chetteiar",
//   "Mannan / velan / Vannan",
//   "Maratha",
//   "Maratha Kshatriya",
//   "Meghwal",
//   "Mera",
//   "Meru Darji",
//   "Modikarlu",
//   "Mudaliyar",
//   "Mudiraj",
//   "Munnuru Kapu",
//   "Musukama",
//   "Naagavamsam",
//   "Nadar",
//   "Nagaralu",
//   "Nai/Nayi Brahmin",
//   "Naidu",
//   "Naika",
//   "Namdarlu",
//   "Nattukottai Chettiar",
//   "Neeli",
//   "Neli Saali",
//   "Nessi",
//   "Niyogi Brahmin",
//   "Ontari",
//   "Padmasali",
//   "Padmashali",
//   "Pagadala",
//   "Pallan/Devandra Kula Vellaian",
//   "Pannirandam Chettiar",
//   "Paravan/ Bharatar",
//   "Parit",
//   "partraj",
//   "Parvatha Rajakulam",
//   "Paswan/Dusadh",
//   "Patil",
//   "Patnaik/Sistakaranam",
//   "Pattinavar",
//   "Poundra",
//   "Pulaya/Cheruman",
//   "Rajaka/Vannar",
//   "Rajput",
//   "Reddy",
//   "Relli",
//   "Rohit/Chamar",
//   "SC",
//   "ST",
//   "Sadhu Chetty",
//   "Sagara (Uppara)",
//   "Saiva Pillai Tirunelveli",
//   "Samagar",
//   "Sambava",
//   "Satnami",
//   "Savji",
//   "Sengunthar/Kaikolar",
//   "Setti Balija",
//   "Shettigar",
//   "Shilpkar",
//   "Smartha Brahmin",
//   "Sonkar",
//   "Sozhia Chetty",
//   "Sri Vaishnava",
//   "Srisayana",
//   "Sugali (Naika)",
//   "Surya Balija",
//   "Tammali",
//   "Telaga",
//   "Telugu Patti",
//   "Thandan",
//   "Thogata Veerakshatriya",
//   "Thondai Mandala Vellalar",
//   "Thota",
//   "Thogata",
//   "Turpu Kapu",
//   "Vada Balija",
//   "Vadambar",
//   "Vaddera",
//   "Vadugan",
//   "Vaidiki Brahmin",
//   "Vaikhanasa Brahmin",
//   "Vaishnava",
//   "Valmiki",
//   "Vani",
//   "Vaniya Chettiar",
//   "Vanjara",
//   "Vanyakulakshatriya",
//   "Veerakodi Vellala",
//   "velama",
//   "Velanadu Brahmin",
//   "Vellan Chettiars",
//   "Vishwa Brahmin",
//   "Velama",
//   "Velanadu Brahmin",
//   "Vellan Chettiars",
//   "Vishwa Brahmin",
//   "Vokkaliga",
//   "Yadav",
//   "Yadava Naidu",
//   "Yellapu",
//   "Sunni",
//   "Shia",
//   "Adventist",
//   "Anglican / Episcopal",
//   "Anglo-Indian",
//   "Apostolic",
//   "Assembly of God (AG)",
//   "Baptist",
//   "Born Again",
//   "Brethren",
//   "Calvinist",
//   "Chaldean Syrian (Assyrian)",
//   "Church of Christ",
//   "Church of God",
//   "Church of North India",
//   "Church of South India",
//   "Congregational",
//   "Evangelist",
//   "Jacobite",
//   "Jehovah's Witnesses",
//   "Knanaya",
//   "Knanaya Catholic",
//   "Knanaya Jacobite",
//   "Malabar Independent Syrian Church",
//   "Reformed Baptist",
//   "St. Thomas Evangelical",
//   "Christian - Others",
//   "Don't wish to specify",
// ];

export const religionsWithCastes = [
  {
    religion: "Hindu",
    castes: [
      "Brahmin",
      "Kshatriya",
      "Vaishya",
      "Shudra",
      "24 Manai Telugu Chettiar",
      "96 Kuli maratha",
      "Achira Pakkam Chettiar",
      "Adi Andhra",
      "Agamudayar/ Arcot/ Tuluva Vellala",
      "Agaram Vellan Chettiar",
      "Agnikula Kshatriya",
      "Ahirwar",
      "Aramari/ Gabit",
      "Arekatika",
      "Arya Vysya",
      "Ayira Vysya",
      "Ayyaraka",
      "Bairwa",
      "Balai",
      "Balija",
      "Balija Naidu",
      "Balija Reddy",
      "Beri Chettiar",
      "Besta",
      "Bhatraju",
      "Brahmin-Anaviln Desai",
      "Brahmin-Baidhiki/Vaidhiki",
      "Brahmin-Bardai",
      "Brahmin-Bhargav",
      "Brahmin-Dravida",
      "Brahmin-Khadayata",
      "Brahmin-Koteshwara/Kota(Madhwa)",
      "Brahmin-Madhwa",
      "Brahmin-Mevada",
      "Brahmin-Niyogi",
      "Brahmin-Others",
      "Brahmin-Rajgor",
      "Brahmin-Rarhi/Radhi",
      "Brahmin-Sarua",
      "Brahmin-Shivalli(Madhwa)",
      "Brahmin-Shri Gaud",
      "Brahmin-Shri Mali",
      "Brahmin-Shukla Yajurvedi",
      "Brahmin-Smartha",
      "Brahmin-Sri Vaishnava",
      "Brahmin-Tapodhan",
      "Brahmin-Vaidiki",
      "Brahmin-Vaikhanasa",
      "Brahmin-Valam",
      "Brahmin-Velanadu",
      "Brahmin-Zalora",
      "Chattada Sri Vaishnava",
      "Chettiar",
      "Chippolu(Mera)",
      "Darji",
      "Deshmukh",
      "Devanga",
      "Devanga Chettiar",
      "Dhanak",
      "Dommala",
      "Dravida Brahmin",
      "Ediga/Goud(Balija)",
      "Elur Chetty",
      "Gajula / Kavarai",
      "Gandla",
      "Gandla/Ganiga",
      "Gavara",
      "Gond",
      "Goud",
      "Gowda",
      "Gowda-Kuruba Gowda",
      "Intercaste",
      "Isai Vellalar",
      "Jaalari",
      "Jandra",
      "Jangam",
      "Jetty/Malla",
      "Jhadav",
      "Julaha",
      "kachara",
      "Kaikaala",
      "Kalinga",
      "Kalinga Vaisya",
      "Kamma",
      "Kamma Naidu",
      "Kanakkan Padanna",
      "Kandara",
      "Kapu",
      "Kapu Naidu",
      "Kapu Reddy",
      "Karakala Bhakthula",
      "Karni Bhakthula",
      "Kasukara",
      "Kavara",
      "Kerala Mudali",
      "Khatik",
      "Kongu Chettiar",
      "Kongu Nadar",
      "Kori / Koli",
      "Kumaoni rajput",
      "Kummari",
      "Kunbi Lonari",
      "Kunbi Maratha",
      "Kunbi tarale",
      "Kurni",
      "Kuruba",
      "Kuruhini Chetty",
      "kuruva",
      "Lingayath",
      "Madhwa Brahmin",
      "Madiga",
      "Mahendra",
      "Mair Rajput Swarnakar",
      "Mala",
      "Malwani",
      "Manjapudur Chetteiar",
      "Mannan / velan / Vannan",
      "Maratha",
      "Maratha Kshatriya",
      "Meghwal",
      "Mera",
      "Meru Darji",
      "Modikarlu",
      "Mudaliyar",
      "Mudiraj",
      "Munnuru Kapu",
      "Musukama",
      "Naagavamsam",
      "Nadar",
      "Nagaralu",
      "Nai/Nayi Brahmin",
      "Naidu",
      "Naika",
      "Namdarlu",
      "Nattukottai Chettiar",
      "Neeli",
      "Neli Saali",
      "Nessi",
      "Niyogi Brahmin",
      "Ontari",
      "Padmasali",
      "Padmashali",
      "Pagadala",
      "Pallan/Devandra Kula Vellaian",
      "Pannirandam Chettiar",
      "Paravan/ Bharatar",
      "Parit",
      "partraj",
      "Parvatha Rajakulam",
      "Paswan/Dusadh",
      "Patil",
      "Patnaik/Sistakaranam",
      "Pattinavar",
      "Poundra",
      "Pulaya/Cheruman",
      "Rajaka/Vannar",
      "Rajput",
      "Reddy",
      "Relli",
      "Rohit/Chamar",
      "SC",
      "ST",
      "Sadhu Chetty",
      "Sagara (Uppara)",
      "Saiva Pillai Tirunelveli",
      "Samagar",
      "Sambava",
      "Satnami",
      "Savji",
      "Sengunthar/Kaikolar",
      "Setti Balija",
      "Shettigar",
      "Shilpkar",
      "Smartha Brahmin",
      "Sonkar",
      "Sozhia Chetty",
      "Sri Vaishnava",
      "Srisayana",
      "Sugali (Naika)",
      "Surya Balija",
      "Tammali",
      "Telaga",
      "Telugu Patti",
      "Thandan",
      "Thogata Veerakshatriya",
      "Thondai Mandala Vellalar",
      "Thota",
      "Thogata",
      "Turpu Kapu",
      "Vada Balija",
      "Vadambar",
      "Vaddera",
      "Vadugan",
      "Vaidiki Brahmin",
      "Vaikhanasa Brahmin",
      "Vaishnava",
      "Valmiki",
      "Vani",
      "Vaniya Chettiar",
      "Vanjara",
      "Vanyakulakshatriya",
      "Veerakodi Vellala",
      "velama",
      "Velanadu Brahmin",
      "Vellan Chettiars",
      "Vishwa Brahmin",
      "Velama",
      "Velanadu Brahmin",
      "Vellan Chettiars",
      "Vishwa Brahmin",
      "Vokkaliga",
      "Yadav",
      "Yadava Naidu",
      "Yellapu",
    ],
  },
  {
    religion: "Muslim",
    castes: ["Sunni", "Shia"],
  },
  {
    religion: "Christian",
    castes: [
      "Adventist",
      "Anglican / Episcopal",
      "Anglo-Indian",
      "Apostolic",
      "Assembly of God (AG)",
      "Baptist",
      "Born Again",
      "Brethren",
      "Calvinist",
      "Chaldean Syrian (Assyrian)",
      "Church of Christ",
      "Church of God",
      "Church of North India",
      "Church of South India",
      "Congregational",
      "Evangelist",
      "Jacobite",
      "Jehovah's Witnesses",
      "Knanaya",
      "Knanaya Catholic",
      "Knanaya Jacobite",
      "Malabar Independent Syrian Church",
      "Reformed Baptist",
      "St. Thomas Evangelical",
      "Christian - Others",
      "Don't wish to specify",
    ],
  },
  // Add more objects for additional religions
];
