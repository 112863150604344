import React, { Component } from "react";
import { Link } from "react-router-dom";

const subtitle = "Explore our success stories";
const title = "Discover sweet tales from our beloved COUPLES";

let StoryContentList = [
  {
    imgUrl: "assets/images/story/01.jpg",
    imgAlt: "Story Thumb",
    title: "Shalini & Abraham",
    desc: "A good marriage is one in which each partner secretly suspects they got the better deal.",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/story/02.jpg",
    imgAlt: "Story Thumb",
    title: "Uday Joseph & Ramya",
    desc: "A perfect marriage is simply just two imperfect people who refuse to give up on each other.",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/story/03.jpg",
    imgAlt: "Story Thumb",
    title: "Rajesh & Snehitha",
    desc: "True love is finding someone whose flaws you can embrace and whose virtues you admire.",
    btnText: "Read More",
  },
];

class StorySection extends Component {
  state = {};
  render() {
    return (
      <section className="story-section padding-tb bg-img">
        <div className=" container">
          <div className="section-header">
            <h4>{subtitle}</h4>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row justify-content-center g-4">
              {StoryContentList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="story-item lab-item">
                    <div className="lab-inner">
                      <div className="lab-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="lab-content">
                        <h4>
                          <Link to="/blog-single">{val.title}</Link>
                        </h4>
                        <p>{val.desc}</p>
                        <Link to="/blog-single" className="lab-btn">
                          <i className="icofont-circled-right"></i>
                          {val.btnText}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default StorySection;
