import React, { useEffect, useState } from "react";
import Instence from "../../Utils/api";

const BasicProfileEdit = ({ userprofileData }) => {
  console.log(userprofileData);

  const [basicInfo, setBasicInfo] = useState({
    onBehalf: "",
    firstName: "",
    lastName: "",
    gender: "",
    age: "",
    dob: "",
    physicalStatus: "",
    height: "",
    weight: "",
    bodyType: "",
    martialStatus: "",
    motherTonge: "",
    eatingHabbit: "",
    smokingHabit: "",
    drinkingHabit: "",
    livesIn: "",
    citizenship: "",
    hobbiesIntrest: "",
  });

  useEffect(() => {
    if (userprofileData && userprofileData.tblBasicInfos.length > 0) {
      const basicInfoData = userprofileData.tblBasicInfos[0];
      setBasicInfo({
        onBehalf: basicInfoData.onBehalf || "",
        firstName: basicInfoData.firstName || "",
        lastName: basicInfoData.lastName || "",
        gender: basicInfoData.gender || "",
        age: basicInfoData.age || "",
        dob: basicInfoData.dob || "",
        physicalStatus: basicInfoData.physicalStatus || "",
        height: basicInfoData.height || "",
        weight: basicInfoData.weight || "",
        bodyType: basicInfoData.bodyType || "",
        martialStatus: basicInfoData.martialStatus || "",
        motherTonge: basicInfoData.motherTonge || "",
        eatingHabbit: basicInfoData.eatingHabbit || "",
        smokingHabit: basicInfoData.smokingHabit || "",
        drinkingHabit: basicInfoData.drinkingHabit || "",
        livesIn: basicInfoData.livesIn || "",
        citizenship: basicInfoData.citizenship || "",
        hobbiesIntrest: basicInfoData.hobbiesIntrest || "",
      });
    }
  }, [userprofileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [basic, setBasic] = useState(false);

  const handleBasicToggleEdit = () => {
    setBasic(true);
  };
  const handleBasicCancel = () => {
    setBasic(false);
  };

  const UserId = JSON.parse(localStorage.getItem("userData"));

  const handleBasicDetailsUpdate = async () => {
    try {
      const response = await Instence.post(
        `User/UpdateBasicInfo/${UserId.id}`,
        basicInfo
      );
      if (response.status === 200) {
        setBasic(false);
        console.log("Update successful:", response.data);
      } else {
        // error("Failed to update Basic Details");
        console.log("Failed to update Basic Details");
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div>
      <div className="row">
        {basic ? (
          <div className="info-div">
            <div className="first-div">
              <p>Basic Details</p>
            </div>
            <div className="input-group">
              <label htmlFor="onBehalf">
                On Behalf <span className="mandatory">*</span>
              </label>
              <select
                id="onBehalf"
                name="onBehalf"
                required
                value={basicInfo.onBehalf}
                onChange={handleChange}
              >
                <option value="">Select</option>
               
                            <option value="Myself">Myself</option>
                            <option value="Friend">Friend</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Daughter/Son">Daughter/Son</option>
              </select>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="firstName">
                  First Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={basicInfo.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="lastName">
                  Last Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={basicInfo.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="gender">
                  Gender <span className="mandatory">*</span>
                </label>
                <select
                  id="gender"
                  name="gender"
                  onChange={handleChange}
                  required
                  value={basicInfo.gender}
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Other</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="dob">
                  Date of Birth <span className="mandatory">*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  onChange={handleChange}
                  required
                  value={
                    basicInfo.dob
                      ? new Date(basicInfo.dob).toISOString().split("T")[0]
                      : ""
                  }
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="age">
                  Age <span className="mandatory">*</span>
                </label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  placeholder="Enter your age"
                  value={basicInfo.age}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="physicalStatus">
                  Physical Status <span className="mandatory">*</span>
                </label>
                <select
                  id="physicalStatus"
                  name="physicalStatus"
                  onChange={handleChange}
                  required
                  value={basicInfo.physicalStatus}
                >
                  <option value="">Select</option>
                  <option value="No Disability">No Disability</option>
                  <option value="physicallyChallenged">
                    Physically Challenged
                  </option>
                </select>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="height">Height (Feet/Inches)</label>
                <select
                  value={basicInfo.height}
                  onChange={handleChange}
                  id="height"
                  name="height"
                  placeholder="Enter your height"
                >
                  <option value="">Select</option>
                  <option value="4ft6in">4ft 6In / 137cm</option>
                  <option value="4ft7in">4ft 7In / 139cm</option>
                  <option value="4ft8in">4ft 8In / 142cm</option>
                  <option value="4ft9in">4ft 9In / 144cm</option>
                  <option value="4ft10in">4ft 10In / 147cm</option>
                  <option value="4ft11in">4ft 11In / 150cm</option>
                  <option value="5ft0in">5ft 0In / 152cm</option>
                  <option value="5ft1in">5ft 1In / 155cm</option>
                  <option value="5ft2in">5ft 2In / 157cm</option>
                  <option value="5ft3in">5ft 3In / 160cm</option>
                  <option value="5ft4in">5ft 4In / 163cm</option>
                  <option value="5ft5in">5ft 5In / 165cm</option>
                  <option value="5ft6in">5ft 6In / 168cm</option>
                  <option value="5ft7in">5ft 7In / 170cm</option>
                  <option value="5ft8in">5ft 8In / 173cm</option>
                  <option value="5ft9in">5ft 9In / 175cm</option>
                  <option value="5ft10in">5ft 10In / 178cm</option>
                  <option value="5ft11in">5ft 11In / 180cm</option>
                  <option value="6ft0in">6ft 0In / 183cm</option>
                  <option value="6ft1in">6ft 1In / 185cm</option>
                  <option value="6ft2in">6ft 2In / 188cm</option>
                  <option value="6ft3in">6ft 3In / 190cm</option>
                  <option value="6ft4in">6ft 4In / 193cm</option>
                  <option value="6ft5in">6ft 5In / 196cm</option>
                  <option value="6ft6in">6ft 6In / 198cm</option>
                  <option value="6ft7in">6ft 7In / 201cm</option>
                  <option value="6ft8in">6ft 8In / 203cm</option>
                  <option value="6ft9in">6ft 9In / 206cm</option>
                  <option value="6ft10in">6ft 10In / 208cm</option>
                  <option value="6ft11in">6ft 11In / 211cm</option>
                  <option value="7ft0in">7ft 0In / 213cm</option>
                  <option value="7ft1in">7ft 1In / 216cm</option>
                  <option value="7ft2in">7ft 2In / 218cm</option>
                  <option value="7ft3in">7ft 3In / 221cm</option>
                  <option value="7ft4in">7ft 4In / 224cm</option>
                  <option value="7ft5in">7ft 5In / 226cm</option>
                  <option value="7ft6in">7ft 6In / 229cm</option>
                  <option value="7ft7in">7ft 7In / 231cm</option>
                  <option value="7ft8in">7ft 8In / 234cm</option>
                  <option value="7ft9in">7ft 9In / 236cm</option>
                  <option value="7ft10in">7ft 10In / 239cm</option>
                  <option value="7ft11in">7ft 11In / 241cm</option>
                  <option value="8ft0in">8ft 0In / 244cm</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="weight">
                  Weight<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="weight"
                  name="weight"
                  placeholder="Enter your weight"
                  value={basicInfo.weight}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="bodyType">
                  Body Type<span className="mandatory">*</span>
                </label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="slim"
                    name="bodyType"
                    value="slim"
                    checked={basicInfo.bodyType === "slim"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="slim">Slim</label>
                  <input
                    type="radio"
                    id="average"
                    name="bodyType"
                    value="average"
                    checked={basicInfo.bodyType === "average"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="average">Average</label>
                  <input
                    type="radio"
                    id="athletic"
                    name="bodyType"
                    value="athletic"
                    checked={basicInfo.bodyType === "athletic"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="athletic">Athletic</label>
                  <input
                    type="radio"
                    id="heavy"
                    name="bodyType"
                    value="heavy"
                    checked={basicInfo.bodyType === "heavy"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="heavy">Heavy</label>
                </div>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="eatingHabit">
                  Eating Habit<span className="mandatory">*</span>
                </label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="non-veg"
                    name="eatingHabbit"
                    value="non-vegetarian"
                    checked={basicInfo.eatingHabbit === "non-vegetarian"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="non-veg">Non-Vegetarian</label>
                  <input
                    type="radio"
                    id="veg"
                    name="eatingHabbit"
                    value="vegetarian"
                    checked={basicInfo.eatingHabbit === "vegetarian"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="veg">Vegetarian</label>
                  <input
                    type="radio"
                    id="egg"
                    name="eatingHabbit"
                    value="eggitarian"
                    checked={basicInfo.eatingHabbit === "eggitarian"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="egg">Eggitarian</label>
                </div>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="drinkingHabit">
                  Drinking Habit<span className="mandatory">*</span>
                </label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="yes"
                    name="drinkingHabit"
                    value="yes"
                    checked={basicInfo.drinkingHabit === "yes"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="yes">Yes</label>
                  <input
                    type="radio"
                    id="no"
                    name="drinkingHabit"
                    value="no"
                    checked={basicInfo.drinkingHabit === "no"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="no">No</label>
                  <input
                    type="radio"
                    id="drink-socially"
                    name="drinkingHabit"
                    value="drink-socially"
                    checked={basicInfo.drinkingHabit === "drink-socially"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="drink-socially">Drink Socially</label>
                </div>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="smokingHabit">
                  Smoking Habit<span className="mandatory">*</span>
                </label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="smoking-yes"
                    name="smokingHabit"
                    value="Yes"
                    checked={basicInfo.smokingHabit === "Yes"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="smoking-yes">Yes</label>
                  <input
                    type="radio"
                    id="smoking-no"
                    name="smokingHabit"
                    value="No"
                    checked={basicInfo.smokingHabit === "No"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="smoking-no">No</label>
                  <input
                    type="radio"
                    id="smoking-occasionally"
                    name="smokingHabit"
                    value="Occasionally"
                    checked={basicInfo.smokingHabit === "Occasionally"}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="smoking-occasionally">Occasionally</label>
                </div>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="maritalStatus">
                  Marital Status <span className="mandatory">*</span>
                </label>
                <select
                  id="maritalStatus"
                  name="martialStatus"
                  required
                  value={basicInfo.martialStatus}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Never Married">Never Married</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Awaiting Divorce">Awaiting Divorce</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="motherTongue">
                  Mother Tongue<span className="mandatory">*</span>
                </label>
                <select
                  id="motherTongue"
                  name="motherTonge"
                  value={basicInfo.motherTonge}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="telugu">Telugu</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="tamil">Tamil</option>
                  <option value="kannada">Kannada</option>
                  <option value="malayalam">Malayalam</option>
                  <option value="urdu">Urdu</option>
                </select>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="livesIn">
                  Lives In <span className="mandatory">*</span>
                </label>
                <select
                  id="livesIn"
                  name="livesIn"
                  onChange={handleChange}
                  required
                  value={basicInfo.livesIn}
                >
                  <option value="">Select</option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="numberOfChildren">
                  CitizenShip<span className="mandatory">*</span>
                </label>
                <select
                  id="citizenShip"
                  required
                  name="citizenship"
                  value={basicInfo.citizenship}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="numberOfChildren">
                  Habits<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="habbits"
                  required
                  name="hobbiesIntrest"
                  placeholder="Enter your habbits"
                  value={basicInfo.hobbiesIntrest}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="profile-btn-div">
              <button className="cancel-btn" onClick={handleBasicCancel}>
                Cancel
              </button>
              <button
                className="profile-btn"
                onClick={handleBasicDetailsUpdate}
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-12">
                <div className="high">
                  <p>BASIC DETAILS</p>
                  <button
                    className="profile-btn"
                    onClick={handleBasicToggleEdit}
                  >
                    Edit
                  </button>
                </div>
                <div className="low">
                  <div className="left-div">
                    <p>
                      <span>First Name : </span>{" "}
                      <span className="span-two">{basicInfo.firstName}</span>
                    </p>
                    <p>
                      <span>Profile Created for :</span>{" "}
                      <span className="span-two"> {basicInfo.onBehalf}</span>{" "}
                    </p>
                    <p>
                      <span>Date of Birth :</span>
                      <span className="span-two">
                        {" "}
                        {basicInfo.dob ? basicInfo.dob.split("T")[0] : ""}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Weight :</span>{" "}
                      <span className="span-two"> {basicInfo.weight}</span>{" "}
                    </p>
                    <p>
                      <span>Body Type :</span>{" "}
                      <span className="span-two"> {basicInfo.bodyType}</span>{" "}
                    </p>
                    <p>
                      <span>Physical Staus :</span>{" "}
                      <span className="span-two">
                        {basicInfo.physicalStatus}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Martial Status :</span>{" "}
                      <span className="span-two">
                        {basicInfo.martialStatus}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Drinking Habits :</span>{" "}
                      <span className="span-two">
                        {basicInfo.drinkingHabit}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Citizenship :</span>{" "}
                      <span className="span-two">{basicInfo.citizenship}</span>{" "}
                    </p>
                  </div>
                  <div className="right-div">
                    <p>
                      <span>Last Name :</span>{" "}
                      <span className="span-two"> {basicInfo.lastName}</span>{" "}
                    </p>
                    <p>
                      <span>Gender :</span>{" "}
                      <span className="span-two"> {basicInfo.gender}</span>{" "}
                    </p>
                    <p>
                      <span>Age :</span>{" "}
                      <span className="span-two"> {basicInfo.age}</span>{" "}
                    </p>
                    <p>
                      <span>Height :</span>{" "}
                      <span className="span-two"> {basicInfo.height}</span>{" "}
                    </p>
                    <p>
                      <span>Mother Tongue :</span>{" "}
                      <span className="span-two"> {basicInfo.motherTonge}</span>{" "}
                    </p>
                    <p>
                      <span>Eating Habits :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {basicInfo.eatingHabbit}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Smoking Habits :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {basicInfo.smokingHabit}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Lives In :</span>{" "}
                      <span className="span-two"> {basicInfo.livesIn}</span>{" "}
                    </p>
                    <p>
                      <span>Hobbies :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {basicInfo.hobbiesIntrest}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicProfileEdit;
