import React, { useEffect, useState } from "react";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import dummy from "../../../assets/images/profile/dummy.jpg";
import { MdOutlineCancel } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import userImg from "../../../assets/images/blank-profile.png";
import "../Matches.css";
import { ImSad } from "react-icons/im";
import Instence from "../../../Utils/api";
import { Link } from "react-router-dom";

const ViewedByYou = () => {

  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const profilesPerPage = 4;

  const [users, setUsers] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [error, setError] = useState("");


  const filterId = userdata && userdata;
  const intrestedUserIds = filterId && filterId.map(item => item.userId);

  // Filter users based on intrestedUserIds if it's defined
  const matchedUsers = intrestedUserIds ? users.filter(user => intrestedUserIds.includes(user.id)) : [];
  console.log(matchedUsers)


  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/ViewedByYou/${id}`);
      setUserData(response.data);
      console.log(response.data)
    } catch (error) {
      setError(error);
    }
  };




  // ! local storage data for all matches
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userDatagender =
    userData.tblBasicInfos[0].gender === "male" ? "female" : "male";

  // ! data fetching from api using axios
  const fetchUsers = async () => {
    try {
      const response = await Instence.get(`/User?gender=${userDatagender}`);
      setUsers(response.data);
      console.log(response.data)
    } catch (error) {
      setError(error);
    }
  };


  useEffect(() => {
    fetchUsers()
    fetchUserData()
  }, [])







  // Calculate total number of pages
  const totalPages = Math.ceil(matchedUsers.length / profilesPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Get current profiles
  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = matchedUsers.slice(indexOfFirstProfile, indexOfLastProfile);;






  return (
    <div className="ViewedBy-container">
      <div className="container profiles-flex">
        {currentProfiles.length === 0 ? (
          <div>
            <div class="aiz-pagination">
              <div class=" text-center pt-10 text-black">
                <ImSad className="Icons_chat" />
                <h4 className="text-black">Nothing Found</h4>
              </div>
            </div>
          </div>
        ) : (
          <>
            {currentProfiles.map((user) => {
              return (
                <div key={user.id} className="row profile-card">
                  <div className="col-md-4 profile-photo">
                    <Link to={`/user_profile/${user.id}`}>
                      {user.tblGalleries &&
                        user.tblGalleries.length > 0 &&
                        user.tblGalleries[0].profilePic ? (
                        <img
                          src={`https://sams.drmarins.cloud/${user.tblGalleries[0].profilePic}`}
                          alt=""
                        />
                      ) : (
                        <img src={userImg} alt="" /> // Provide a fallback image if profilePic is not available
                      )}
                    </Link>
                  </div>
                  <div className="col-md-8 profile-vivaralu">
                    <p className="id-verify">
                      {user.tblDocuments && user.tblDocuments.length > 0 ? (
                        <>
                          <RiVerifiedBadgeFill className="verified-coni" />
                          ID verified
                        </>
                      ) : (
                        <p></p>
                      )}
                    </p>
                    <div>
                      <p className="name-p">
                        {user.tblBasicInfos[0].firstName}
                      </p>
                      {/* <p className="last-seen">Last Seen: {user.lastSeen}</p> */}
                      <div className="base-details-flex">
                        <p> {user.tblBasicInfos[0].age} years</p>
                        <p>{user.tblBasicInfos[0].physicalStatus}</p>
                        <p>{user.tblReligions[0].religion}</p>
                        <p>{user.tblReligions[0].caste}</p>
                        <p>{user.tblProffessionInformations[0].occupation}</p>
                      </div>
                      <div className="prof-details-flex">
                        <p>
                          {user.tblBasicInfos[0].martialStatus}
                        </p>
                        <p>{user.tblFamilyInfos[0].familyStatus}</p>
                      </div>
                      <div className="interested">
                        <p>Interested in her/him?</p>
                        {/* <p>Connect now</p> */}
                        <div className="connect-flex">
                          <button className="dont-show-btn">
                            <MdOutlineCancel className="button-coni" />
                            Don't Show
                          </button>
                          <button className="send-interest-btn">
                            <FaPhone className="button-coni" />
                            Call Now
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </>

        )}
      </div>
      <div className="paginations">
        <ul className="lab-ul d-flex flex-wrap justify-content-center mb-1">
          <li>
            <a onClick={() => handlePageChange(1)}>
              <i className="icofont-rounded-double-left"></i>
            </a>
          </li>
          {currentPage > 1 && (
            <li>
              <a onClick={() => handlePageChange(currentPage - 1)}>
                <i className="icofont-rounded-left"></i>
              </a>
            </li>
          )}
          {Array.from({ length: totalPages }).map((_, index) => {
            if (
              index + 1 === currentPage || // current page
              index + 1 === 1 || // first page
              index + 1 === totalPages || // last page
              (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) // pages around the current page
            ) {
              return (
                <li key={index} className={currentPage === index + 1 ? "active" : ""}>
                  <a onClick={() => handlePageChange(index + 1)}>{index + 1}</a>
                </li>
              );
            } else if (
              index === 0 || // first index
              index === totalPages - 1 || // last index
              (index === currentPage - 3 || index === currentPage + 3) // pages just before or after the pages around the current page
            ) {
              return (
                <li key={index}>
                  <a>...</a>
                </li>
              );
            }
            return null;
          })}
          {currentPage < totalPages && (
            <li>
              <a onClick={() => handlePageChange(currentPage + 1)}>
                <i className="icofont-rounded-right"></i>
              </a>
            </li>
          )}
          <li>
            <a onClick={() => handlePageChange(totalPages)}>
              <i className="icofont-rounded-double-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ViewedByYou