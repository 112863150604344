import { async } from "q";
import React, { useEffect, useState } from "react";
import Instence from "../../Utils/api";

const AddressEdit = ({ addressData }) => {
  console.log(addressData);

  const [address, setAddress] = useState(false);

  const [addData, setAddData] = useState({
    countryLivingIn: "",
    residingState: "",
    residingCityDistrict: "",
    native: "",
  });

  useEffect(() => {
    if (
      addressData &&
      addressData.tblLocations &&
      addressData.tblLocations.length > 0
    ) {
      const locationDetails = addressData.tblLocations[0];
      setAddData({
        countryLivingIn: locationDetails.countryLivingIn || "",
        residingState: locationDetails.residingState || "",
        residingCityDistrict: locationDetails.residingCityDistrict || "",
        native: locationDetails.native || "",
      });
    }
  }, [addressData]);

  const hanleLocationUpdate = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("userData"));

      const response = await Instence.post(
        `/User/UpdateLocationInfo/${userId.id}`,
        addData
      );

      if (response.data) {
        setAddress(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddData({
      ...addData,
      [id]: value,
    });
  };

  const handleAddressCancel = () => {
    setAddress(false);
  };
  const handleAddressToggleEdit = () => {
    setAddress(true);
  };

  return (
    <div>
      <div className="row">
        {address ? (
          <div className="info-div">
            <div className="first-div">
              <p>Address</p>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="country">
                  Country<span className="mandatory">*</span>
                </label>
                <select
                  id="countryLivingIn"
                  value={addData.countryLivingIn}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="residingState">
                  Residing State<span className="mandatory">*</span>
                </label>
                <input
                  id="residingState"
                  value={addData.residingState}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="residingCityDistrict">
                  Residing City/District<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="residingCityDistrict"
                  required
                  value={addData.residingCityDistrict}
                  onChange={handleChange}
                  placeholder="Enter residing city or district"
                />
              </div>
              <div className="input-group">
                <label htmlFor="native">
                  Native<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="native"
                  required
                  value={addData.native}
                  onChange={handleChange}
                  placeholder="Enter native place"
                />
              </div>
            </div>
            <div className="profile-btn-div">
              <button className="cancel-btn" onClick={handleAddressCancel}>
                Cancel
              </button>
              <button onClick={hanleLocationUpdate} className="profile-btn">
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-12">
                <div className="high">
                  <p>ADDRESS</p>
                  <button
                    className="profile-btn"
                    onClick={handleAddressToggleEdit}
                  >
                    Edit
                  </button>
                </div>
                <div className="low">
                  <div className="left-div">
                    <p>
                      <span> Country :</span>{" "}
                      <span className="span-two">
                        {addData.countryLivingIn}
                      </span>
                    </p>
                    <p>
                      <span>Residing City / District :</span>{" "}
                      <span className="span-two">
                        {addData.residingCityDistrict}
                      </span>
                    </p>
                  </div>
                  <div className="right-div">
                    <p>
                      <span>Residing State :</span>{" "}
                      <span className="span-two">{addData.residingState}</span>
                    </p>
                    <p>
                      <span>Native :</span>{" "}
                      <span className="span-two">{addData.native}</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressEdit;
