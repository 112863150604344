import React, { Component } from "react";
import { Link } from "react-router-dom";
import MemberItem from "../items/memberitem";

const title = "New Members in SamsMatrimony";
const subtitle = "Meet New People Today!";

class MemberSection extends Component {
  render() {
    return (
      <section className="member-section padding-tb">
        <div className="container">
          <div className="section-header">
            <h4 className="theme-color">{subtitle}</h4>
            <h2> {title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row justify-content-center g-3 g-md-4">
              <MemberItem />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MemberSection;
