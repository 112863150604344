import * as Yup from "yup";

export const basicDetailsSchema = Yup.object().shape({
  dateOfBirth: Yup.date().required("Date of Birth is required"),
  age: Yup.number()
    .required("Age is required")
    .positive("Age must be positive"),
  language: Yup.string().required("Language is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});
