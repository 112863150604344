import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import userImg from "../../assets/images/blank-profile.png";
import Instence from "../../Utils/api";

const MemberItem = () => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get(
          "https://sams.drmarins.cloud/api/User/GetAllProfiles"
        );

        if (response.status === 200) {
          // Assuming response.data is an array of profiles
          const sortedProfiles = response.data.sort((a, b) => {
            console.log(a, b);
            // Assuming there's a property named 'joinDate' in each profile
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          // Set only the six newest profiles
          setProfiles(sortedProfiles.slice(0, 6));
        } else {
          console.error("Failed to fetch profiles");
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, []);

  console.log(profiles);

  return (
    <>
      {profiles.map((val, i) => {
        console.log(val);
        return (

          <div className="col-xl-2 col-lg-3 col-md-4 col-6" key={i}>
            <Link to="/login">
              <div className="lab-item member-item style-1">
                <div className="lab-inner">
                  <div className="lab-thumb member-item-img-div">
                    <img
                      className="Card_Profile_Imges"
                      src={userImg}
                      alt="member-img"
                    />

                  </div>
                  <div className="lab-content">
                    <h6>
                      <Link>{val.tblBasicInfos[0].firstName}</Link>{" "}
                    </h6>
                    <p>{val.tblProffessionInformations[0].occupation}</p>
                    {/* <p>{val.tblProffessionInformations[0].highestEducation}</p> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>

        );
      })}
    </>
  );
};

export default MemberItem;
