import React, { useCallback, useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import useRazorpay from "react-razorpay";
import Instence from "../Utils/api";
import logo from "../assets/images/Favicon.png";

const Package = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  console.log(userData.id);

  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await Instence.get(`/User/${userData.id}`);
        setUser(response.data);
      } catch (error) {
        setError(error);
      }
    };
    fetchUser();
  }, []);

  const basicprofile = user && user.tblBasicInfos && user.tblBasicInfos[0];

  const subscribePackage = async () => {
    try {
      const response = await Instence.post("/User/package", {
        userId: userData.id,
        packageId: 1,
      });
      if (response.status !== 200) {
        throw new Error("Failed to subscribe to package");
      }
      console.log(response);
      // Additional logic after subscribing to package
    } catch (error) {
      console.error("Error subscribing to package:", error);
      throw error;
    }
  };

  const [Razorpay] = useRazorpay();

  const createOrder = async (amount, receipt) => {
    try {
      const response = await Instence.post("/Razorpay", {
        amount: amount,
        receipt: receipt,
      });
      if (response.status != 200) {
        throw new Error("Failed to create Razorpay order");
      }
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      throw error;
    }
  };

  const handlePayment = useCallback(async () => {
    // console.log("clicked");
    try {
      const order = await createOrder(4000, "Premium package"); // 10000 paise = ₹100

      const options = {
        key: "rzp_live_yLddEg0ZQcv8A2", // This is real Razorpay API key
        amount: 4000 * 100,
        currency: "INR",
        name: "Sams Matrimony",
        description: "Premium Package subscription",
        image: logo,
        order_id: order.id,
        handler: (res) => {
          subscribePackage();
        },
        prefill: {
          name: basicprofile.firstName,
          email: user && user.userName,
          contact: user && user.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error handling Razorpay payment:", error);
    }
  }, [createOrder, Razorpay]);
  return (
    <div>
      <section className="pt-9 pb-4  text-center">
        <div className="container">
          <h1 className="pt-5    fw-600 text-white">Select Your Package</h1>
        </div>
      </section>

      <section className="py-5 ">
        <div className="container">
          <div
            className="aiz-carousel"
            data-items="4"
            data-xl-items="3"
            data-md-items="2"
            data-sm-items="1"
            data-dots="true"
            data-infinite="true"
            data-autoplay="true"
          >
            <div className="carousel-box bg-white">
              <div className="overflow-hidden shadow-none border-right">
                <div className="card-body">
                  <div className="text-center mb-4 mt-3">
                    <img
                      className="mw-100 mx-auto mb-4"
                      src="https://samsmatrimony.com/public/uploads/all/DiTca3qQ2VOE2V84VhA97TIIOp8tVKa7VkaDTvNJ.png"
                      height="130"
                    />
                    <h5 className="mb-3 h5 fw-600 text-dark">
                      Premium Package
                    </h5>
                  </div>
                  <ul className="list-group list-group-raw fs-15 mb-5">
                    <li className="list-group-item py-2">
                      <TiTick className="tick-coni" />2 Express Interests
                    </li>
                    <li className="list-group-item py-2">
                      <TiTick className="tick-coni" />2 Gallery Photo Upload
                    </li>
                    <li className="list-group-item py-2">
                      <TiTick className="tick-coni" />2 Contact Info view
                    </li>
                    <li className="list-group-item py-2 text-line-through">
                      <TiTick className="tick-coni" />
                      Show Auto Profile Match
                    </li>
                  </ul>
                  <div className="mb-5 text-dark text-center">
                    <span className="display-4 fw-600 lh-1 mb-0">
                      Rs.4000.00/-
                    </span>
                    <span className="text-secondary d-block">
                      Life Long Membership
                    </span>
                  </div>
                  <div className="text-center">
                    <button onClick={handlePayment} className="profile-btn">
                      Purchase This Package
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Package;
