import React, { useState } from "react";
import Select from "react-select";

const ReligionDetails = ({ nextStep }) => {
  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");
  const [gothram, setGothram] = useState("");
  const [dosham, setDosham] = useState("");

  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedCaste, setSelectedCaste] = useState(null);

  console.log(selectedReligion);

  const localstoragedata = localStorage.getItem("formData");
  const formrelDataaa = JSON.parse(localstoragedata);
  console.log(formrelDataaa);

  const handleClickNext = () => {
    const religionData = {
      caste,
      subCaste,
      gothram,
      dosham,
      selectedCaste,
      religion: selectedReligion,
    };

    // Merge localStorage data with new religion data
    const mergedData = { ...formrelDataaa, ...religionData };

    // Store merged data back into localStorage
    localStorage.setItem("formData", JSON.stringify(mergedData));

    nextStep();
  };

  const religions = [
    {
      religion: "Hindu",
      castes: [
        "Brahmin",
        "Kshatriya",
        "Vaishya",
        "Shudra",
        "24 Manai Telugu Chettiar",
        "96 Kuli maratha",
        "Achira Pakkam Chettiar",
        "Adi Andhra",
        "Agamudayar/ Arcot/ Tuluva Vellala",
        "Agaram Vellan Chettiar",
        "Agnikula Kshatriya",
        "Ahirwar",
        "Aramari/ Gabit",
        "Arekatika",
        "Arya Vysya",
        "Ayira Vysya",
        "Ayyaraka",
        "Bairwa",
        "Balai",
        "Balija",
        "Balija Naidu",
        "Balija Reddy",
        "Beri Chettiar",
        "Besta",
        "Bhatraju",
        "Brahmin-Anaviln Desai",
        "Brahmin-Baidhiki/Vaidhiki",
        "Brahmin-Bardai",
        "Brahmin-Bhargav",
        "Brahmin-Dravida",
        "Brahmin-Khadayata",
        "Brahmin-Koteshwara/Kota(Madhwa)",
        "Brahmin-Madhwa",
        "Brahmin-Mevada",
        "Brahmin-Niyogi",
        "Brahmin-Others",
        "Brahmin-Rajgor",
        "Brahmin-Rarhi/Radhi",
        "Brahmin-Sarua",
        "Brahmin-Shivalli(Madhwa)",
        "Brahmin-Shri Gaud",
        "Brahmin-Shri Mali",
        "Brahmin-Shukla Yajurvedi",
        "Brahmin-Smartha",
        "Brahmin-Sri Vaishnava",
        "Brahmin-Tapodhan",
        "Brahmin-Vaidiki",
        "Brahmin-Vaikhanasa",
        "Brahmin-Valam",
        "Brahmin-Velanadu",
        "Brahmin-Zalora",
        "Chattada Sri Vaishnava",
        "Chettiar",
        "Chippolu(Mera)",
        "Darji",
        "Deshmukh",
        "Devanga",
        "Devanga Chettiar",
        "Dhanak",
        "Dommala",
        "Dravida Brahmin",
        "Ediga/Goud(Balija)",
        "Elur Chetty",
        "Gajula / Kavarai",
        "Gandla",
        "Gandla/Ganiga",
        "Gavara",
        "Gond",
        "Goud",
        "Gowda",
        "Gowda-Kuruba Gowda",
        "Intercaste",
        "Isai Vellalar",
        "Jaalari",
        "Jandra",
        "Jangam",
        "Jetty/Malla",
        "Jhadav",
        "Julaha",
        "kachara",
        "Kaikaala",
        "Kalinga",
        "Kalinga Vaisya",
        "Kamma",
        "Kamma Naidu",
        "Kanakkan Padanna",
        "Kandara",
        "Kapu",
        "Kapu Naidu",
        "Kapu Reddy",
        "Karakala Bhakthula",
        "Karni Bhakthula",
        "Kasukara",
        "Kavara",
        "Kerala Mudali",
        "Khatik",
        "Kongu Chettiar",
        "Kongu Nadar",
        "Kori / Koli",
        "Kumaoni rajput",
        "Kummari",
        "Kunbi Lonari",
        "Kunbi Maratha",
        "Kunbi tarale",
        "Kurni",
        "Kuruba",
        "Kuruhini Chetty",
        "kuruva",
        "Lingayath",
        "Madhwa Brahmin",
        "Madiga",
        "Mahendra",
        "Mair Rajput Swarnakar",
        "Mala",
        "Malwani",
        "Manjapudur Chetteiar",
        "Mannan / velan / Vannan",
        "Maratha",
        "Maratha Kshatriya",
        "Meghwal",
        "Mera",
        "Meru Darji",
        "Modikarlu",
        "Mudaliyar",
        "Mudiraj",
        "Munnuru Kapu",
        "Musukama",
        "Naagavamsam",
        "Nadar",
        "Nagaralu",
        "Nai/Nayi Brahmin",
        "Naidu",
        "Naika",
        "Namdarlu",
        "Nattukottai Chettiar",
        "Neeli",
        "Neli Saali",
        "Nessi",
        "Niyogi Brahmin",
        "Ontari",
        "Padmasali",
        "Padmashali",
        "Pagadala",
        "Pallan/Devandra Kula Vellaian",
        "Pannirandam Chettiar",
        "Paravan/ Bharatar",
        "Parit",
        "partraj",
        "Parvatha Rajakulam",
        "Paswan/Dusadh",
        "Patil",
        "Patnaik/Sistakaranam",
        "Pattinavar",
        "Poundra",
        "Pulaya/Cheruman",
        "Rajaka/Vannar",
        "Rajput",
        "Reddy",
        "Relli",
        "Rohit/Chamar",
        "SC",
        "ST",
        "Sadhu Chetty",
        "Sagara (Uppara)",
        "Saiva Pillai Tirunelveli",
        "Samagar",
        "Sambava",
        "Satnami",
        "Savji",
        "Sengunthar/Kaikolar",
        "Setti Balija",
        "Shettigar",
        "Shilpkar",
        "Smartha Brahmin",
        "Sonkar",
        "Sozhia Chetty",
        "Sri Vaishnava",
        "Srisayana",
        "Sugali (Naika)",
        "Surya Balija",
        "Tammali",
        "Telaga",
        "Telugu Patti",
        "Thandan",
        "Thogata Veerakshatriya",
        "Thondai Mandala Vellalar",
        "Thota",
        "Thogata",
        "Turpu Kapu",
        "Vada Balija",
        "Vadambar",
        "Vaddera",
        "Vadugan",
        "Vaidiki Brahmin",
        "Vaikhanasa Brahmin",
        "Vaishnava",
        "Valmiki",
        "Vani",
        "Vaniya Chettiar",
        "Vanjara",
        "Vanyakulakshatriya",
        "Veerakodi Vellala",
        "velama",
        "Velanadu Brahmin",
        "Vellan Chettiars",
        "Vishwa Brahmin",
        "Velama",
        "Velanadu Brahmin",
        "Vellan Chettiars",
        "Vishwa Brahmin",
        "Vokkaliga",
        "Yadav",
        "Yadava Naidu",
        "Yellapu",
        // Add more caste values here if available
      ],
    },
    { religion: "Muslim", castes: ["Sunni", "Shia"] },
    {
      religion: "Christian",
      castes: [
        "Adventist",
        "Anglican / Episcopal",
        "Anglo-Indian",
        "Apostolic",
        "Assembly of God (AG)",
        "Baptist",
        "Born Again",
        "Brethren",
        "Calvinist",
        "Chaldean Syrian (Assyrian)",
        "Church of Christ",
        "Church of God",
        "Church of North India",
        "Church of South India",
        "Congregational",
        "Evangelist",
        "Jacobite",
        "Jehovah's Witnesses",
        "Knanaya",
        "Knanaya Catholic",
        "Knanaya Jacobite",
        "Malabar Independent Syrian Church",
        "Reformed Baptist",
        "St. Thomas Evangelical",
        "Christian - Others",
        "Don't wish to specify",
        // Add more caste values here if available
      ],
    },
  ];

  const handleReligionChange = (selectedReligion) => {
    setSelectedReligion(selectedReligion);
    setSelectedCaste(null); // Reset caste selection when religion changes
  };

  const handleCasteChange = (selectedCaste) => {
    setSelectedCaste(selectedCaste);
  };

  const getCastesForSelectedReligion = () => {
    if (selectedReligion) {
      const religion = religions.find(
        (rel) => rel.religion === selectedReligion.value
      );
      return religion
        ? religion.castes.map((caste) => ({ value: caste, label: caste }))
        : [];
    }
    return [];
  };

  return (
    <div className="basic-details-container">
      <div className="container-fluid">
        <div className="row ">
          <div className="container">
            <div className="row basic-screen">
              <div className="col-md-4 loving-companion">
                <img
                  src="../assets/images/banner/couple-logo.png"
                  alt="couple logo"
                />
              </div>
              <div className="col-md-8 bg-white right-form">
                <div className="heading-details">
                  <p className="text-black">
                    RELIGION DETAILS CAN FIND THE RIGHT MATCH
                  </p>
                </div>
                <div className="basic-input-form">
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="religion">
                        Religion<span className="mandatory">*</span>
                      </label>
                      <Select
                        className="w-100"
                        value={selectedReligion}
                        onChange={handleReligionChange}
                        options={religions.map((religion) => ({
                          value: religion.religion,
                          label: religion.religion,
                        }))}
                        isSearchable
                        placeholder="Select religion.."
                      />
                    </div>
                  </div>
                  {selectedReligion && (
                    <div className="input-row">
                      <div className="input-group">
                        <label htmlFor="caste">
                          {selectedReligion.value === "Christian"
                            ? "Division"
                            : "Caste"}
                          <span className="mandatory">*</span>
                        </label>
                        <Select
                          className="w-100"
                          value={selectedCaste}
                          onChange={handleCasteChange}
                          options={getCastesForSelectedReligion()}
                          isSearchable
                          placeholder="Search for caste.."
                        />
                      </div>
                    </div>
                  )}
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="subCaste">
                        {selectedReligion &&
                        selectedReligion.value === "Christian"
                          ? "Caste"
                          : "Sub Caste"}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="subCaste"
                        placeholder="Enter Sub Caste"
                        value={
                          selectedReligion &&
                          selectedReligion.value === "Christian"
                            ? caste
                            : subCaste
                        }
                        onChange={(e) =>
                          selectedReligion &&
                          selectedReligion.value === "Christian"
                            ? setCaste(e.target.value)
                            : setSubCaste(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {/* Additional fields for Hindu religion */}
                  {selectedReligion && selectedReligion.value === "Hindu" && (
                    <div className="input-row">
                      <div className="input-group">
                        <label htmlFor="gothram">
                          Gothram<span className="mandatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="gothram"
                          placeholder="Enter gothram"
                          value={gothram}
                          onChange={(e) => setGothram(e.target.value)}
                        />
                      </div>
                      <div className="input-group">
                        <label htmlFor="dosham">
                          Dosham<span className="mandatory">*</span>
                        </label>
                        <input
                          type="text"
                          id="dosham"
                          placeholder="Enter dosham"
                          value={dosham}
                          onChange={(e) => setDosham(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="profile-btn-div">
                    <button onClick={handleClickNext} className="profile-btn">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReligionDetails;
