import React, { useEffect, useState } from "react";
import Instence from "../../Utils/api";
import { RiXingLine } from "react-icons/ri";

const EducationalEdit = ({ eduData }) => {
  console.log(eduData);

  const [education, setEducation] = useState(false);
  const [formData, setFormData] = useState({
    highestEducation: "",
    educationalInDetails: "",
    collegeInstitution: "",
    employeedIn: "",
    occupation: "",
    occupationInDetail: "",
    organaization: "",
    annualIncome: "",
  });

  useEffect(() => {
    if (eduData && eduData.tblProffessionInformations.length > 0) {
      const educationDetails = eduData.tblProffessionInformations[0];
      setFormData({
        highestEducation: educationDetails.highestEducation || "",
        educationalInDetails: educationDetails.educationalInDetails || "",
        collegeInstitution: educationDetails.collegeInstitution || "",
        employeedIn: educationDetails.employeedIn || "",
        occupation: educationDetails.occupation || "",
        occupationInDetail: educationDetails.occupationInDetail || "",
        organaization: educationDetails.organaization || "",
        annualIncome: educationDetails.annualIncome || "",
      });
    }
  }, [eduData]);

  const handleEducationCancel = () => {
    setEducation(false);
  };

  const handleEducationToggleEdit = () => {
    setEducation(true);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleEducationDetailsUpdate = async () => {
    try {
      const UserId = JSON.parse(localStorage.getItem("userData"));

      const response = await Instence.post(
        `User/UpdateProfessionalInfo/${UserId.id}`,
        formData
      );
      console.log("Update successful:", response.data);
      setEducation(false);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div>
      <div className="row">
        {education ? (
          <div className="info-div">
            <div className="first-div">
              <p>Education Details</p>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="highestEducation">Highest Education</label>
                <select
                  id="highestEducation"
                  onChange={handleInputChange}
                  value={formData.highestEducation}
                >
                  <option value="">Select</option>
                  <option value="SchoolLevel">School Level</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="diploma">Diploma</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Postgraduate">Postgraduate</option>
                  <option value="Doctorate">Doctorate</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="educationDetails">Education Details</label>
                <input
                  type="text"
                  id="educationalInDetails"
                  placeholder="Enter education details"
                  onChange={handleInputChange}
                  value={formData.educationalInDetails}
                />
              </div>
              <div className="input-group">
                <label htmlFor="college">College/University</label>
                <input
                  type="text"
                  id="collegeInstitution"
                  placeholder="Enter college/university"
                  onChange={handleInputChange}
                  value={formData.collegeInstitution}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="employed">Employed</label>
                <select
                  id="employeedIn"
                  onChange={handleInputChange}
                  value={formData.employeedIn}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="occupation">Occupation</label>
                <select
                  id="occupation"
                  onChange={handleInputChange}
                  value={formData.occupation}
                >
                  <option value="Administration">Administration</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Architecture & Design">
                    Architecture & Design
                  </option>
                  <option value="Banking-Finance">Banking & Finance</option>
                  <option value="Beauty-Fashion">Beauty & Fashion</option>
                  <option value="BPO-CustomerService">
                    BPO & Customer Service
                  </option>
                  <option value="Corporate-Professionals">
                    Corporate Professionals
                  </option>
                  <option value="Doctor">Doctor</option>
                  <option value="Education-Training">
                    Education & Training
                  </option>
                  <option value="Engineering">Engineering</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="IT-Software">IT & Software</option>
                  <option value="Media-Entertainment">
                    Media & Entertainment
                  </option>
                  <option value="Medical-Healthcare">
                    Medical & Healthcare
                  </option>
                  <option value="Merchant-Navy">Merchant Navy</option>
                  <option value="Others">Others</option>
                  <option value="Not-Working">Not Working</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="occupationDetails">Occupation Details</label>
                <input
                  type="text"
                  id="occupationInDetail"
                  placeholder="Enter occupation details"
                  onChange={handleInputChange}
                  value={formData.occupationInDetail}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="organization">Organization</label>
                <input
                  type="text"
                  id="organaization"
                  placeholder="Enter organization name"
                  onChange={handleInputChange}
                  value={formData.organaization}
                />
              </div>
              <div className="input-group">
                <label htmlFor="annualIncome">Annual Income</label>
                <input
                  type="text"
                  id="annualIncome"
                  placeholder="Enter annual income"
                  onChange={handleInputChange}
                  value={formData.annualIncome}
                />
              </div>
            </div>
            <div className="profile-btn-div">
              <button className="cancel-btn" onClick={handleEducationCancel}>
                Cancel
              </button>
              <button
                onClick={handleEducationDetailsUpdate}
                className="profile-btn"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-12">
                <div className="high">
                  <p>EDUCATION DETAILS</p>
                  <button
                    className="profile-btn"
                    onClick={handleEducationToggleEdit}
                  >
                    Edit
                  </button>
                </div>
                <div className="low">
                  <div className="left-div">
                    <p>
                      <span>Highest Education :</span>{" "}
                      <span className="span-two">
                        {formData.highestEducation}
                      </span>
                    </p>
                    <p>
                      <span>College/University :</span>{" "}
                      <span className="span-two">
                        {formData.collegeInstitution}
                      </span>
                    </p>
                    <p>
                      <span> Occupation :</span>{" "}
                      <span className="span-two">{formData.occupation}</span>
                    </p>
                    <p>
                      <span>Organization :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.organaization}
                      </span>
                    </p>
                  </div>
                  <div className="right-div">
                    <p>
                      <span>Education Details :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.educationalInDetails}
                      </span>
                    </p>
                    <p>
                      <span> Employed :</span>
                      <span className="span-two">
                        {" "}
                        {formData.employeedIn}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Occupation Details :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.occupationInDetail}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Annual Income :</span>{" "}
                      <span className="span-two"> {formData.annualIncome}</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationalEdit;
