import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/newLogo.png";
import Instence from "../../Utils/api";

import icons from "../../assets/images/footer/icons/01.png"
import icons1 from "../../assets/images/footer/icons/02.png"
import icons2 from "../../assets/images/footer/icons/03.png"
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const posttitle = "our Recent news";
const abouttitle = "About Sams Matrimony";
const aboutpara =
  "At Sams Matrimony India PVT.LTD Privacy assured, secure & trusted matrimonial site, Register and contact members for Free. Register free, fill in the details required to submit your matrimonial profile now.Find your Life partner.Send Marriage Proposals.";
const newstitle = "Our Newsletter Signup";
const newspara =
  "By subscribing to our mailing list you will always be update with the latest news from us.";

const FooterSection = () => {

  const [email, setEmail] = useState("");


  const verificationMessage = `
    <html>
      <head>
        <style>
          .container {
            max-width: 600px;
            margin: 0 auto;
            font-family: Arial, sans-serif;
            color: #333;
          }
          .message {
            margin-bottom: 20px;
          }
          .footer {
            margin-top: 20px;
            font-size: 14px;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="message">
            <p>By subscribing to our mailing list you will always be update with the latest news from us. related content</p>
          </div>
          <div class="footer">
            <p>Thank you.</p>
          </div>
        </div>
      </body>
    </html>
  `;

  const postData = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('To', email);
      formData.append('Subject', "Subcribed to Sams matrimony");
      formData.append('Body', verificationMessage);

      const response = await Instence.post('/Email/SendMail', formData);
      console.log(response.data)
      setEmail("")
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Email sent successfully!',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle any errors that occur during the API call
    }
  };


  return (
    <footer className="footer-section">
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner address-divs">
                  <div className="">
                    <img
                      src={icons}
                      // src="assets/images/footer/icons/01.png"
                      alt="Phone-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>Phone Number : +91 7702201206</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner address-divs">
                  <div className="">
                    <img
                      src={icons1}
                      // src="assets/images/footer/icons/02.png"
                      alt="email-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>Email : samsmatrimony@gmail.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner address-divs">
                  <div className="">
                    <img
                      src={icons2}
                      // src="assets/images/footer/icons/03.png"
                      alt="location-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>Address : Mansurabad, Hyderabad-500068.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer-middle padding-tb"
        style={{ backgroundImage: "url(/assets/images/footer/bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-lg-0">
                  <div className="fm-item-title">
                    <h4>{abouttitle}</h4>
                  </div>
                  <div className="fm-item-content">
                    <img src={logo} alt="logo" className="footer-abt-img" />
                    <p className="mb-4">{aboutpara}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 text-left">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-lg-0">
                  <div className="fm-item-title px-3">
                    <h4>Information</h4>
                  </div>
                  <div className="">
                    <div className="fm-item-widget lab-item">
                      <div className="lab-inner ">
                        <div className="text-left px-3">
                          <p>
                            <Link to={"/support"}>Support</Link>
                          </p>
                          <p>
                            <Link to={"/terms_conditions"}>
                              Terms and Conditions
                            </Link>
                          </p>
                          <p>
                            <Link to={"/privacy_policy"}>Privacy Policy</Link>
                          </p>
                          <p>
                            <Link to={"/refund_cancellation"}>
                              Refund & Cancellation
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item-3 mb-lg-0">
                  <div className="fm-item-title">
                    <h4>{newstitle}</h4>
                  </div>
                  <div className="fm-item-content">
                    <p>{newspara}</p>
                    <form onSubmit={postData}>
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          id="item01"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Your email *"
                        />
                      </div>
                      <button type="submit" className="lab-btn">
                        Send Message <i className="icofont-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content text-center d-flex justify-content-between">
                <p>
                  {" "}
                  ©2024 Samsmatrimony India PVT LTD All Rights are reserved
                </p>
                <p>
                  Designed & Developed by:{" "}
                  <a
                    style={{ paddingLeft: "10px" }}
                    href="https://galaxytechsolutions.com/"
                    target="_blank"
                  >
                    Galaxy Tech Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
