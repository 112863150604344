import React, { useState } from "react";
import Instence from "../Utils/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Partner = () => {
  const [isMarriageBureau, setIsMarriageBureau] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    location: "",
    experience: "",
    specialization: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // You can handle form submission here
    const Postdata = async () => {
      try {
        const resp = await Instence.post("User/BecomePartner", {
          userId: 1010,
          ...formData, // Spread the formData object here
        });
        if (resp.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Application Submitted Successfully',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          });
          setFormData({
            name: "",
            phoneNumber: "",
            location: "",
            experience: "",
            specialization: "",
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'Something went wrong. Please try again',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          });
        }
      } catch (error) {
        console.error("Error while posting data:", error);
      }
    };
    Postdata();
  };
  return (
    <div>
      <div className="aiz-user-panel overflow-hidden">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0 text-black h6">Want To be a partner</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-md-3 col-form-label">
                  Are you running a marriage bureau?
                  <span className="text-danger">*</span>
                </label>
                <label className="col-md-1 col-form-label mobile-label">
                  :
                </label>
                <div className="col-md-8 mb-3">
                  <label style={{ marginRight: "30px" }}>
                    <input
                      type="radio"
                      name="runningBereau"
                      value="yes"
                      checked={isMarriageBureau}
                      onChange={() => setIsMarriageBureau(true)}
                    />{" "}
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="runningBereau"
                      value="no"
                      checked={!isMarriageBureau}
                      onChange={() => setIsMarriageBureau(false)}
                    />{" "}
                    No
                  </label>
                </div>
              </div>

              {isMarriageBureau ? (
                <>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Name</label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Phone Number
                    </label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Location</label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Total Experience
                    </label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="experience"
                        value={formData.totalExperience}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Specialization
                    </label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="specialization"
                        value={formData.specialization}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Name</label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Phone Number
                    </label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Location</label>
                    <label className="col-md-1 col-form-label mobile-label">
                      :
                    </label>
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </>
              )}
              <div class="form-group row text-right">
                <div class="col-md-12 d-flex justify-content-end">
                  <button type="submit" class="btn-Interest" id="passSaveBtn">
                    Yes, I want to Become a Partner
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
