import React, { useEffect, useState } from "react";
import "../Matches.css";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Instence from "../../../Utils/api";
import { religionsWithCastes } from "../../../Utils/CasteData";
import { css } from "@emotion/react";
import { PacmanLoader } from "react-spinners";
import userImg from "../../../assets/images/blank-profile.png";
import { toast } from "react-toastify";
import { FaPhone } from "react-icons/fa6";
import Swal from "sweetalert2";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AllMatches = () => {


  //!     States for all matches
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [age, setAge] = useState(0);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [physicalStatus, setPhysicalStatus] = useState("");
  const [religion, setReligion] = useState("");
  const [caste, setCaste] = useState("");
  const [familyStatus, setFamilyStatus] = useState("");
  const [occupation, setOccupation] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const profilesPerPage = 15;

  // ! local storage data for all matches
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userDatagender =
    userData.tblBasicInfos[0].gender === "male" ? "female" : "male";

  // ! data fetching from api using axios
  const fetchUsers = async () => {
    try {
      const response = await Instence.get(`/User?gender=${userDatagender}`);
      const sortedProfiles = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setLoading(false);
      setUsers(sortedProfiles);
      setFilteredUsers(sortedProfiles);
    } catch (error) {
      setError(error);
    }
  };





  // ! handle Search Users


  const handleSearch = async () => {

    try {
      const response = await Instence.post("/User/search", {
        age: age,
        phisicalDisablity: physicalStatus,
        religion: religion,
        caste: caste,
        occupation: occupation,
        martialStatus: maritalStatus,
        familyStatus: familyStatus,
        gender: userDatagender
      })
      setFilteredUsers(response.data)
    } catch (e) {
      console.error(e)
    }
  }





  // ! handle Reset
  const handleReset = () => {
    fetchUsers();
    setMaritalStatus("");
    setOccupation("");
    setReligion("");
    setCaste("");
    setFamilyStatus("");
    setPhysicalStatus("");
    setAge("");
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  //! <====== Pagination code ====>
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //!  Get current profiles based on filtered or all users

  const currentProfiles =
    filteredUsers.slice(
      (currentPage - 1) * profilesPerPage,
      currentPage * profilesPerPage
    )


  //! Calculate total number of pages
  const totalPages = Math.ceil(
    (filteredUsers.length ? filteredUsers.length : users.length) /
    profilesPerPage
  );

  const castes =
    religionsWithCastes.find((rel) => rel.religion === religion)?.castes || [];


  // ! localStorage
  const userId = localStorage.getItem("userData");
  const userDataParsed = JSON.parse(userId);
  const id = userDataParsed.id;

  const [sentInterests, setSentInterests] = useState({});




  // ! handle function for sendInterest 

  const handleSendInterest = async (userId) => {
    try {
      const response = await Instence.post('/User/Intrested', {
        userId: id,
        intrestedUserId: userId
      });
      if (response.data.statusCode === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Interest sent successfully',
          timer: 3000,
          timerProgressBar: true,
          position: 'top-end',
          showConfirmButton: false
        });
        setSentInterests(prevState => ({
          ...prevState,
          [userId]: true
        }));
      }
    } catch (error) {
      console.error('Error sending interest:', error);
      // Handle error scenario
    }
  };




  return (
    <>
      <div className="allmatches">
        <div className="filter-section">
          <div className="myProfile-container">
            <div className="container-fluid my-Profile-divs">
              <h2>Filters</h2>
              <div className="filter_form">
                <div className="input-group">
                  <label htmlFor="height">Age</label>
                  <input
                    type="number"
                    id="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="physicalStatus">Physical Status</label>
                  <select
                    id="physicalStatus"
                    value={physicalStatus}
                    onChange={(e) => setPhysicalStatus(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="No Disability">No Disability</option>
                    <option value="Physical Challenged">
                      Physical Challenged
                    </option>
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="religion">Religion</label>
                  <select
                    id="religion"
                    value={religion}
                    onChange={(e) => setReligion(e.target.value)}
                  >
                    <option value="">Select</option>
                    {religionsWithCastes.map((religionOption, index) => (
                      <option key={index} value={religionOption.religion}>
                        {religionOption.religion}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="caste">Caste</label>
                  <select
                    id="caste"
                    value={caste}
                    onChange={(e) => setCaste(e.target.value)}
                  >
                    <option value="">Select</option>
                    {castes.map((casteOption, index) => (
                      <option key={index} value={casteOption}>
                        {casteOption}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="occupation">Occupation</label>
                  {/* <input type="text" placeholder="Enter occupation" /> */}
                  <select
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    id="occupation"
                  >
                    <option value=""> Select</option>
                    <option value="Administration">Administration</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Architecture & Design">
                      Architecture & Design
                    </option>
                    <option value="Banking-Finance">Banking & Finance</option>
                    <option value="Beauty-Fashion">Beauty & Fashion</option>
                    <option value="BPO-CustomerService">
                      BPO & Customer Service
                    </option>
                    <option value="Corporate-Professionals">
                      Corporate Professionals
                    </option>
                    <option value="Doctor">Doctor</option>
                    <option value="Education-Training">
                      Education & Training
                    </option>
                    <option value="Engineering">Engineering</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="IT-Software">IT & Software</option>
                    <option value="Media-Entertainment">
                      Media & Entertainment
                    </option>
                    <option value="Medical-Healthcare">
                      Medical & Healthcare
                    </option>
                    <option value="Merchant-Navy">Merchant Navy</option>
                    <option value="Others">Others</option>
                    <option value="Not-Working">Not Working</option>
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="highestEducation">Martial Status</label>
                  <select
                    id="highestEducation"
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Never Married">Never Married</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Awaiting Divorce">Awaiting Divorce</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="familyStatus">Family Status</label>
                  <select
                    id="familyStatus"
                    value={familyStatus}
                    onChange={(e) => setFamilyStatus(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Middle Class">Middle Class</option>
                    <option value="Uppeer Middle Class">
                      Uppeer Middle Class
                    </option>
                    <option value="High Class">High Class</option>
                    <option value="Rich ">Rich / Affluent</option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-secondary" onClick={handleReset}>
                  Show All
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => { handleSearch() }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="matches-details">
          {loading && loading ? (
            <>
              <div className="text-center LoadingBlock">
                <h1>Partner Is Loading</h1>
                <PacmanLoader color={"#DF314D"} css={override} size={50} />
                <h6>Please Wait</h6>
              </div>
            </>
          ) : (
            <>
              {currentProfiles.map((user) => {
                const isInterestSent = sentInterests[user.id] || false;
                return (
                  <div key={user.id} className="row profile-card">
                    <div className="col-md-4 profile-photo">
                      <Link to={`/user_profile/${user.id}`}>
                        {user.tblGalleries &&
                          user.tblGalleries.length > 0 &&
                          user.tblGalleries[0].profilePic ? (
                          <img
                            src={`https://sams.drmarins.cloud/${user.tblGalleries[0].profilePic}`}
                            alt=""
                          />
                        ) : (
                          <img src={userImg} alt="" /> // Provide a fallback image if profilePic is not available
                        )}
                      </Link>
                    </div>
                    <div className="col-md-8 profile-vivaralu">
                      <p className="id-verify">
                        {user.tblDocuments && user.tblDocuments.length > 0 ? (
                          <>
                            <RiVerifiedBadgeFill className="verified-coni" />
                            ID verified
                          </>
                        ) : (
                          <p></p>
                        )}
                      </p>
                      <div>
                        <p className="name-p">
                          {user.tblBasicInfos[0].firstName}
                        </p>
                        {/* <p className="last-seen">Last Seen: {user.lastSeen}</p> */}
                        <div className="base-details-flex">
                          <p> {user.tblBasicInfos[0].age} years</p>
                          <p>{user.tblBasicInfos[0].physicalStatus}</p>
                          <p>{user.tblReligions[0].religion}</p>
                          <p>{user.tblReligions[0].caste}</p>
                          <p>{user.tblProffessionInformations[0].occupation}</p>
                        </div>
                        <div className="prof-details-flex">
                          <p>
                            {user.tblBasicInfos[0].martialStatus}
                          </p>
                          <p>{user.tblFamilyInfos[0].familyStatus}</p>
                        </div>
                        <div className="interested">
                          <p>Interested in her/him?</p>
                          {/* <p>Connect now</p> */}
                          <div className="connect-flex">
                            <button className="dont-show-btn">
                              <MdOutlineCancel className="button-coni" />
                              Don't Show
                            </button>
                            {!isInterestSent ? (
                              <button onClick={() => handleSendInterest(user.id)} className="send-interest-btn">
                                <FaRegCheckCircle className="button-coni" />
                                Send Interest
                              </button>
                            ) : (
                              <button className="send-interest-btn">
                                <FaPhone className="button-coni" />
                                Call Now
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              {filteredUsers.length === 0 && (
                <div className="text-center mt-4">
                  <button className="btn btn-primary" onClick={fetchUsers}>
                    Show All Profiles
                  </button>
                </div>
              )}
            </>
          )}
          <div className="paginations">
            <ul className="lab-ul d-flex flex-wrap justify-content-center mb-1">
              <li>
                <a onClick={() => handlePageChange(1)}>
                  <i className="icofont-rounded-double-left"></i>
                </a>
              </li>
              {Array.from({ length: totalPages }).map((_, index) => (
                <li key={index}>
                  <a
                    className={currentPage === index + 1 ? "active" : ""}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li>
                <a onClick={() => handlePageChange(totalPages)}>
                  <i className="icofont-rounded-double-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllMatches;