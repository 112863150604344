import React, { useState } from "react";
import "./CSS/BasicDetails.css";

const EducationDetails = ({ nextStep }) => {
  const [highestEducation, setHighestEducation] = useState("");
  const [employed, setEmployed] = useState("");
  const [occupation, setOccupation] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");

  const formDataString = localStorage.getItem("formData");
  const formeduDataaa = JSON.parse(formDataString);
  console.log(formeduDataaa);

  const handleclicknext = () => {
    const edudetails = {
      highestEducation,
      employed,
      occupation,
      annualIncome,
    };

    const mergedFormData = {
      ...formeduDataaa,
      ...edudetails,
    };

    console.log(mergedFormData);

    localStorage.setItem("formData", JSON.stringify(mergedFormData));

    nextStep();
  };
  return (
    <div className="basic-details-container">
      <div className="container-fluid">
        <div className="row ">
          <div className="container">
            <div className="row basic-screen">
              <div className="col-md-4 loving-companion">
                <img src="../assets/images/banner/couple-logo.png" />
              </div>
              <div className="col-md-8 bg-white right-form">
                <div className="heading-details">
                  <p className="text-black">
                    PROFESSIONAL DETAILS HELP US TO FIND YOUR BEST PARTNER
                  </p>
                </div>
                <div className="basic-input-form">
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="highestEducation">
                        Highest Education
                      </label>
                      <select
                        id="highestEducation"
                        value={highestEducation}
                        onChange={(e) => setHighestEducation(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="SchoolLevel">School Level</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="diploma">Diploma</option>
                        <option value="Graduate">Graduate</option>
                        <option value="Postgraduate">Postgraduate</option>
                        <option value="Doctorate">Doctorate</option>
                        <option value="Doctorate">unEducated</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="employed">Employed</label>
                      <select
                        id="employed"
                        value={employed}
                        onChange={(e) => setEmployed(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="occupation">Occupation</label>
                      {/* <input type="text" placeholder="Enter occupation" /> */}
                      <select
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        id="occupation"
                      >
                        <option value="Administration">Administration</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Architecture & Design">
                          Architecture & Design
                        </option>
                        <option value="Banking-Finance">
                          Banking & Finance
                        </option>
                        <option value="Beauty-Fashion">Beauty & Fashion</option>
                        <option value="BPO-CustomerService">
                          BPO & Customer Service
                        </option>
                        <option value="Corporate-Professionals">
                          Corporate Professionals
                        </option>
                        <option value="Doctor">Doctor</option>
                        <option value="Education-Training">
                          Education & Training
                        </option>
                        <option value="Engineering">Engineering</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="IT-Software">IT & Software</option>
                        <option value="Media-Entertainment">
                          Media & Entertainment
                        </option>
                        <option value="Medical-Healthcare">
                          Medical & Healthcare
                        </option>
                        <option value="Merchant-Navy">Merchant Navy</option>
                        <option value="Others">Others</option>
                        <option value="Not-Working">Not Working</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="annualIncome">Annual Income</label>
                      <input
                        value={annualIncome}
                        onChange={(e) => setAnnualIncome(e.target.value)}
                        type="text"
                        id="annualIncome"
                        placeholder="Enter annual income"
                      />
                    </div>
                  </div>
                  <div className="profile-btn-div">
                    <button onClick={handleclicknext} className="profile-btn">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EducationDetails;
