import React, { useState } from "react";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FooterSection from "../component/layout/footer";
import Instence from "../Utils/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const titel = "Don't Be A Stranger Just Say Hello.";
const desc =
  "We do fast phone repair. In most to repair your device in just minutes, li we’ll normally get con nection inutes, we’ll normally ge.";
const btnText = "Send Our Message";
const infotitle = "Get Information";
const infodesc =
  "Our Contact information Details and Follow us on social media";

const ContactInfoList = [
  {
    imgUrl: "assets/images/contact/01.png",
    imgAlt: "contact info thumb",
    infoTitle: "Office Address",
    infoDesc: "Mansurabad, Hyderabad-500068.",
  },
  {
    imgUrl: "assets/images/contact/02.png",
    imgAlt: "contact info thumb",
    infoTitle: "Phone Number :",
    infoDesc: " +91 7702201206",
  },
  {
    imgUrl: "assets/images/contact/03.png",
    imgAlt: "contact info thumb",
    infoTitle: "Send Mail",
    infoDesc: " samsmatrimony@gmail.com",
  },
];

const ContactPage = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    // Phone validation
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
      valid = false;
    }

    // Subject validation
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      valid = false;
    }

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    const { name, email, phone, subject, message } = formData;

    const formDataToSend = new FormData();

    formDataToSend.append("To", "samsmatrimony@gmail.com");
    formDataToSend.append("Subject", "A new message from client");
    formDataToSend.append(
      "Body",
      `Name: ${formData.name}, <br/> Email: ${formData.email}, <br/> Subject: ${formData.subject}, <br/> Message: ${formData.message}`
    );

    try {
      const response = await Instence.post("Email/SendMail", formDataToSend);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Successfully Posted',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false
        });
        console.log(response.data);
        console.log("Sending Email Success");
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error Posting Details',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
      console.error("Error Sending Email:", error);
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };

  // Validation for mobile number

  const validatePhoneNumber = (value) => {
    if (value.length !== 10) {
      return "Please enter a 10-digit phone number";
    }
    return "";
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedValue = value.replace(/\D/g, "").slice(0, 10); // Remove non-numeric characters and limit to 10 digits
    setFormData({
      ...formData,
      phone: formattedValue,
    });
    const errorMessage = validatePhoneNumber(formattedValue);
    setErrors(errorMessage);
  };

  const handleKeyPress = (e) => {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Header />
      <PageHeader title={"Support"} />
      <div className="contact-section">
        <div className="contact-top padding-tb aside-bg padding-b">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <article className="contact-form-wrapper">
                  <div className="contact-form">
                    <form
                      action="#"
                      method="POST"
                      id="commentform"
                      className="comment-form"
                      onSubmit={handleFormSubmit}
                    >
                      <input
                        type="text"
                        name="name"
                        id="item01"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Your Name *"
                        required
                      />

                      <input
                        type="text"
                        name="email"
                        id="item02"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Your Email *"
                        required
                      />

                      <input
                        type="text"
                        name="phone"
                        id="item04"
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Mobile Number *"
                        required
                      />

                      <input
                        type="text"
                        name="subject"
                        id="item03"
                        value={formData.subject}
                        onChange={handleInputChange}
                        placeholder="Subject"
                        required
                      />

                      <textarea
                        rows="8"
                        type="text"
                        id="item05"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your Message"
                        required
                      ></textarea>
                      {errors.name && (
                        <div className="Terror-msg">{errors.name}</div>
                      )}
                      {errors.email && (
                        <div className="Terror-msg">{errors.email}</div>
                      )}
                      {errors.phone && (
                        <div className="Terror-msg">{errors.phone}</div>
                      )}
                      {errors.subject && (
                        <div className="Terror-msg">{errors.subject}</div>
                      )}
                      {errors.message && (
                        <div className="Terror-msg">{errors.message}</div>
                      )}
                      <button type="submit" className="lab-btn">
                        <span>{btnText}</span>
                      </button>
                    </form>
                  </div>
                </article>
              </div>
              <div className="col-lg-4">
                <div className="contact-info-wrapper">
                  <div className="contact-info-title">
                    <h5>{infotitle}</h5>
                    <p>{infodesc}</p>
                  </div>
                  <div className="contact-info-content">
                    {ContactInfoList.map((val, i) => (
                      <div className="contact-info-item" key={i}>
                        <div className="contact-info-inner">
                          <div className="contact-info-thumb">
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </div>
                          <div className="contact-info-details">
                            <span className="fw-bold">{val.infoTitle}</span>
                            <p>{val.infoDesc}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default ContactPage;
