import React, { useEffect, useState } from "react";
import profile from "../assets/images/member/02.jpg";
import "./css/EditProfile.css";
import Select from "react-select";
import { MdVerified } from "react-icons/md";
import Instence from "../Utils/api";
import BasicProfileEdit from "./EditProfileComp/BasicProfileEdit";
import ReligionEdit from "./EditProfileComp/ReligionEdit";
import FamilyDetailsEdit from "./EditProfileComp/FamilyDetailsEdit";
import EducationalEdit from "./EditProfileComp/EducationalEdit";
import AddressEdit from "./EditProfileComp/AddressEdit";
import userImg from "../assets/images/blank-profile.png";

const EditProfile = () => {
  // ! UserId getting from local storage
  const UserId = JSON.parse(localStorage.getItem("userData"));

  const [userApiData, setUserApiData] = useState();

  //! user data fetching from api

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Instence.get(`User/${UserId.id}`);
        setUserApiData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, []);

  const basicInfo =
    userApiData && userApiData.tblBasicInfos && userApiData.tblBasicInfos[0];
  const religionInfo =
    userApiData && userApiData.tblReligions && userApiData.tblReligions[0];
  const locationInfo =
    userApiData && userApiData.tblLocations && userApiData.tblLocations[0];
  const professionInfo =
    userApiData &&
    userApiData.tblProffessionInformations &&
    userApiData.tblProffessionInformations[0];
  const Galleries =
    userApiData && userApiData.tblGalleries && userApiData.tblGalleries[0];

  return (
    <div className="Edit-Profile-Container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-4 leftie">
                <div className="profile-img-div">
                  <img
                    src={
                      Galleries
                        ? `https://sams.drmarins.cloud/${Galleries.profilePic}`
                        : userImg
                    }
                    alt="profile"
                  />
                </div>
                {/* <div className="profile-btn-div">
                  <button className="profile-btn">Add/Edit Photo</button>
                </div> */}
              </div>
              <div className="col-md-8 rightie">
                <div>
                  <p style={{ fontSize: "25px", fontWeight: "500" }}>
                    {basicInfo &&
                      `${basicInfo.firstName} ${basicInfo.lastName}`}
                  </p>
                  <p>Profile created for {basicInfo && basicInfo.onBehalf}</p>
                  <p>
                    {basicInfo && `${basicInfo.age} years, ${basicInfo.height}`}
                  </p>
                  <p>
                    {religionInfo &&
                      `${religionInfo.religion}, ${religionInfo.caste}`}
                  </p>
                  <p>
                    {locationInfo &&
                      `${locationInfo.native}, ${locationInfo.residingCityDistrict}, ${locationInfo.residingState}, ${locationInfo.countryLivingIn}`}
                  </p>
                  <p>
                    {professionInfo &&
                      `${professionInfo.highestEducation}, ${professionInfo.occupation}`}
                  </p>
                  <div className="number_">
                    <p>
                      <MdVerified className="green-coni" />
                      {userApiData && userApiData.userName}
                    </p>
                    {/* <button>Edit UserId</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BasicProfileEdit userprofileData={userApiData} />
        <ReligionEdit religionData={userApiData} />
        <EducationalEdit eduData={userApiData} />
        <FamilyDetailsEdit familyDetailsData={userApiData} />
        <AddressEdit addressData={userApiData} />
      </div>
    </div>
  );
};

export default EditProfile;
