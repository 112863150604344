import React, { useEffect, useState } from "react";
import "./css/HeaderTabs.css";
import Dashboard from "./Dashboard";
import IgnoredUserList from "./IgnoredUserList";
import Messaging from "./Messaging";
import { Link, useNavigate } from "react-router-dom";
import FooterSection from "../component/layout/footer";
import { IoHomeOutline } from "react-icons/io5";
import { CiCircleList } from "react-icons/ci";
import { GiLovers } from "react-icons/gi";
import { IoNotificationsOutline } from "react-icons/io5";
import Matches from "../pages/Matches/Matches";
import logo from "../assets/images/newLogo.png";
import Instence from "../Utils/api";
import { RiMenu3Line } from "react-icons/ri";
import userImg from "../assets/images/blank-profile.png";
import { MdOutlineLogout } from "react-icons/md";
import { MdPriceChange } from "react-icons/md";
import Package from "../pages/Package";

const HeadTabs = () => {
  //  ! data getting from local storage
  const userLogin = localStorage.getItem("userLogin");

  const [user, setUser] = useState();
  const [error, setError] = useState(null);

  const fetchUser = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/${id}`);
      setUser(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const userfirstName =
    user && user.tblBasicInfos[0] && user.tblBasicInfos[0].firstName;

  const ispackage = user && user.tblPackages && user.tblPackages;

  // ! UseStates
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  // ! images fetching data
  // Define fetchData outside of useEffect
  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/gallery/${id}`);
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData inside useEffect
  useEffect(() => {
    fetchUser();
    fetchData();
  }, []);

  //! data for tabs concepts

  const [activeTab, setActiveTab] = useState("Home");

  const handleUpgradeClick = () => {
    setActiveTab("Packages");
  };

  const tabData = [
    {
      name: "Home",
      component: (
        <Dashboard
          handleUpgradeClick={handleUpgradeClick}
          userImg={images}
          fetchData={fetchData}
          userData={user}
        />
      ),
      icon: <IoHomeOutline />,
    },
    { name: "Matches", component: <Matches />, icon: <GiLovers /> },
    // { name: "Chat", component: <Messaging />, icon: <CiCircleList /> },
    {
      name: "Notifications",
      component: <IgnoredUserList />,
      icon: <IoNotificationsOutline />,
    },
  ];

  if (ispackage && ispackage.length === 0) {
    tabData.push({
      name: "Packages",
      component: <Package />,
      icon: <MdPriceChange />,
    });
  }

  // ! active tab functionality
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setShowTabs(false);
  };

  const [showTabs, setShowTabs] = useState(false);
  const toggleTabs = () => {
    setShowTabs(!showTabs);
  };

  if (!showTabs) {
    document.body.classList.remove("no-scroll");
  } else {
    document.body.classList.add("no-scroll");
  }

  const handleLogoutAccount = () => {
    localStorage.removeItem("userLogin");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <div className="home-HeadTabs">
        <>
          <div className="dashboard-tabmenu container-fluid">
            <div className="dash-head-logo-div">
              {userLogin ? (
                <Link to="/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              ) : (
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              )}
            </div>
            <div className="dash-head-tabs">
              {tabData.map((tab) => (
                <Link
                  key={tab.name}
                  onClick={() => handleTabClick(tab.name)}
                  className={activeTab === tab.name ? "active" : ""}
                >
                  {tab.icon} {tab.name}
                </Link>
              ))}
            </div>

            <div className="dash-head-profile-view">
              <div className="profile-show">
                <p>{userfirstName}</p>
                <div className="img-profile-div">
                  <img
                    className="Profile_img"
                    src={`https://sams.drmarins.cloud/${images}` || userImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="hamburger-menu" onClick={toggleTabs}>
              <RiMenu3Line className="hamburger-menu-icon" />
            </div>
          </div>
        </>

        {/* ****************************************************************** */}
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="text-black p-0">
            {tabData.find((tab) => tab.name === activeTab)?.component}
          </div>
        </div>
      </div>
      <FooterSection />
      {/* ******************************* OffCanvas Code *************************************************** */}

      {showTabs && (
        <>
          <div className="depart-overlay"></div>
          <div
            className={`zorel depart-chat-popup-menu ${showTabs ? "open" : ""}`}
          >
            <div className="chat-header">
              <div className="img-profile-div">
                <img
                  className="Profile_img"
                  src={`https://sams.drmarins.cloud/${images}` || userImg}
                  alt=""
                />
              </div>
              <h5>{userfirstName}</h5>
              <div>
                <button
                  className="close-btn"
                  onClick={() => {
                    setShowTabs(false);
                  }}
                >
                  {" "}
                  &times;
                </button>
              </div>
            </div>
            <div className="mobile-dash-head-tabs text-black">
              {tabData.map((tab) => (
                <Link
                  key={tab.name}
                  onClick={() => handleTabClick(tab.name)}
                  className={activeTab === tab.name ? "active" : ""}
                >
                  {tab.icon} {tab.name}
                </Link>
              ))}

              <div onClick={handleLogoutAccount} className="profile-btn">
                <MdOutlineLogout className="logout-coni" />
                Logout
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default HeadTabs;