import React, { useEffect, useState } from "react";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FooterSection from "../component/layout/footer";
import AllPhotos from "../component/section/photos";
import profile from "../assets/images/profile/Profile.jpg";
import coverImg from "../assets/images/profile/cover.jpg";
import { useParams } from "react-router-dom";
import Instence from "../Utils/api";
import userImg from "../assets/images/blank-profile.png";

const UserProfiles = () => {
  const [user, setUser] = useState();
  const [error, setError] = useState(null);
  const [isPaidMember, setIsPaidMember] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchUser = async () => {
        try {
          const response = await Instence.get(`/User/${id}`);
          setUser(response.data);
          console.log(response.data);
        } catch (error) {
          setError(error);
        }
      };

      fetchUser();
    }
  }, [id]);

  const [loguser, setLogUser] = useState();
  const [logerror, setLogError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userId = localStorage.getItem("userData");
        const userDataParsed = JSON.parse(userId);
        const id = userDataParsed.id;
        const response = await Instence.get(`/User/${id}`);
        setLogUser(response.data);
      } catch (error) {
        setLogError(error);
      }
    };

    fetchUser();
  }, []);


  useEffect(() => {
    const postData = async () => {

      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const viwedid = userDataParsed.id;

      try {
        const response = await Instence.post('/User/createViwed', {
          userId: viwedid,
          viewedUserId: id
        });

        console.log('Response:', response.data);
        // Handle response data here
      } catch (error) {
        console.error('Error:', error);
        // Handle error here
      }
    };

    postData();
  }, []);



  const ispackage = loguser && loguser.tblPackages && loguser.tblPackages;

  return (
    <>
      <div>
        <Header />
        <PageHeader title={"Profile Single"} />
        <section className="profile-section padding-tb">
          <div className="container">
            <div className="section-wrapper">
              <div className="member-profile">
                <div className="profile-item">
                  <div className="profile-cover">
                    <img src={coverImg} alt="cover-pic" />
                  </div>
                  <div className="profile-information">
                    <div className="profile-pic">
                      <img
                        src={
                          user &&
                            user.tblGalleries &&
                            user.tblGalleries[0] &&
                            user.tblGalleries[0].profilePic
                            ? `https://sams.drmarins.cloud/${user.tblGalleries[0].profilePic}`
                            : userImg
                        }
                        alt="profile"
                      />
                      {/* <div className="custom-upload">
                        <div className="file-btn">
                          <span className="d-none d-lg-inline-block">
                            {" "}
                            <i className="icofont-camera"></i>
                            Edit
                          </span>
                          <span className="d-lg-none mr-0">
                            <i className="icofont-plus"></i>
                          </span>
                        </div>
                        <input type="file" />
                      </div> */}
                    </div>
                    <div className="profile-name">
                      <h4>
                        {user &&
                          user.tblBasicInfos &&
                          user.tblBasicInfos[0].firstName}
                      </h4>
                      {/* <p>Active 02 Minutes Ago</p> */}
                    </div>
                  </div>
                </div>
                <div className="profile-item d-none">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <a href="#">
                        <img
                          src="assets/images/profile/Profile.jpg"
                          alt="profile"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="profile-details">
                  <nav className="profile-nav">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className="nav-link active"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        Profile
                      </button>
                      {/* <button
                        className="nav-link"
                        id="nav-photos-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#photos"
                        type="button"
                        role="tab"
                        aria-controls="photos"
                        aria-selected="false"
                      >
                        Photos
                      </button> */}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div>
                        <div className="row">
                          <div className="col-xl-12">
                            <article>
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Basic Information</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name"> First Name</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].firstName}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Last Name</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].lastName}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">On Behalf</p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].onBehalf}
                                      </p>
                                    </li>
                                    {/* <li>
                                      <p className="info-name">Loking for a</p>
                                      <p className="info-details">Men</p>
                                    </li> */}
                                    <li>
                                      <p className="info-name">
                                        Marital Status
                                      </p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].martialStatus}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Age</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].age}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Date of Birth</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          new Date(
                                            user.tblBasicInfos[0].dob
                                          ).toLocaleDateString()}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Mother Tongue</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].motherTonge}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Height</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].height}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Weight</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].weight}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Body Type</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].bodyType}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Physical Status
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].physicalStatus}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Eating Habit</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].eatingHabbit}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Drinking Habit
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].drinkingHabit}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Smoking Habit{" "}
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].smokingHabit}
                                      </p>
                                    </li>

                                    <li>
                                      <p className="info-name">Lives in</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].livesIn}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Citizenship</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblLocations &&
                                          user.tblLocations[0].countryLivingIn}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Hobbies</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblBasicInfos &&
                                          user.tblBasicInfos[0].hobbiesIntrest}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Contact Details</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name">Mobile Number</p>
                                      <p className="">
                                        {ispackage &&
                                          ispackage?.length === 0 ? (
                                          <>
                                            <span className="blurred-number">
                                              +91 98xxxxxxxx
                                            </span>
                                            <span>
                                              Upgrade to see Contact Details
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <p>
                                              {user && user.phone && user.phone}
                                            </p>
                                          </>
                                        )}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* ***************************************************************************************************************** */}
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Religious Details</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name">Religion</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].religion}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Caste</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].caste}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Sub-Caste</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].subCaste}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Gothram</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].gothram}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Rasi</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].starRaasi}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Dosham</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblReligions &&
                                          user.tblReligions[0].dosham}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* ************************************************************************************** */}
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Education Details</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name">
                                        Highest Education
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .highestEducation}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Education Details
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .educationalInDetails}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        College/University
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .collegeInstitution}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Employed</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .employeedIn}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Occupation</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .occupation}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Occupation Details
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .occupationInDetail}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Organization</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .organaization}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Annual Income</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblProffessionInformations &&
                                          user.tblProffessionInformations[0]
                                            .annualIncome}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* ************************************************************************************** */}
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Family Details</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name">Family Value</p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].familyValue}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Family Type</p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].familyType}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Family Status</p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].familyStatus}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Father Occupation
                                      </p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0]
                                            .fatherOccupation}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Mother Occupation
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0]
                                            .motherOccupation}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Number of Brothers
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].noBrothers}
                                      </p>
                                    </li>

                                    <li>
                                      <p className="info-name">
                                        Brothers Married
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].brotherMarried}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Number of Sisters
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].noSisters}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Sisters Married
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].siterMarried}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Family Location
                                      </p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].familyLocation}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">About Family</p>
                                      <p className="info-details">
                                        {" "}
                                        {user &&
                                          user.tblFamilyInfos &&
                                          user.tblFamilyInfos[0].aboutMyFamily}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              {/* ************************************************************************ */}
                              <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Address</h6>
                                </div>
                                <div className="info-card-content">
                                  <ul className="info-list">
                                    <li>
                                      <p className="info-name">Country</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblLocations &&
                                          user.tblLocations[0].countryLivingIn}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Residing State
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblLocations &&
                                          user.tblLocations[0].residingState}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">
                                        Residing City / District
                                      </p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblLocations &&
                                          user.tblLocations[0]
                                            .residingCityDistrict}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="info-name">Native</p>
                                      <p className="info-details">
                                        {user &&
                                          user.tblLocations &&
                                          user.tblLocations[0].native}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="photos"
                      role="tabpanel"
                      aria-labelledby="nav-photos-tab"
                    >
                      <AllPhotos />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterSection />
      </div>
    </>
  );
};

export default UserProfiles;

{
  /* ************************************************************************************************************** */
}
{
  /* <div className="info-card mb-20">
                                <div className="info-card-title">
                                  <h6>Myself Summary</h6>
                                </div>
                                <div className="info-card-content">
                                  <p>
                                    Collaboratively innovate compelling
                                    mindshare after prospective partnerships
                                    Competently sereiz long-term high-impact
                                    internal or "organic" sources via user
                                    friendly strategic themesr areas creat
                                    Dramatically coordinate premium partnerships
                                    rather than standards compliant technologies
                                    ernd Dramatically matrix ethical
                                    collaboration and idea-sharing through
                                    opensource methodologies and Intrinsicly
                                    grow collaborative platforms vis-a-vis
                                    effective scenarios. Energistically
                                    strategize cost effective ideas before the
                                    worke unde.
                                  </p>
                                </div>
                              </div> */
}
{
  /* ******************************************************************************* */
}
