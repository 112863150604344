import React, { useState } from "react";
import "./css/ReferEarn.css";
import Instence from "../Utils/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ReferEarn = () => {

  const [friendName, setFriendName] = useState("");
  const [friendEmail, setFriendEmail] = useState("");

  const verificationMessage = `
    <html>
      <head>
        <style>
          .container {
            max-width: 600px;
            margin: 0 auto;
            font-family: Arial, sans-serif;
            color: #333;
          }
          .message {
            margin-bottom: 20px;
          }
          .footer {
            margin-top: 20px;
            font-size: 14px;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="message">
            <p>Your friend has referred you! Enjoy exclusive benefits and rewards by joining us through your friend's referral.</p>
          </div>
          <div class="footer">
            <p>Thank you.</p>
          </div>
        </div>
      </body>
    </html>
  `;

  const postData = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('To', friendEmail);
      formData.append('Subject', "Refference of friend");
      formData.append('Body', verificationMessage);

      const response = await Instence.post('/Email/SendMail', formData);
      console.log(response.data)
      setFriendEmail("")
      setFriendName("")
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Email sent successfully',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle any errors that occur during the API call
    }
  };


  return (
    <div className="referAndEarnContainer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="share-box">
              <img src="https://i.ibb.co/PtYrLNy/img1.png" alt="img1" />
              <p>Share with your friends</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="share-box">
              <img src="https://i.ibb.co/P5TdfkT/img2.png" alt="img2" />
              <p>Give her to ₹500 Discount</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="share-box">
              <img src="https://i.ibb.co/StC3RWk/img3.png" alt="img3" />
              <p>Get ₹100 for every ₹500</p>
            </div>
          </div>
        </div>
        <div className="row py-5 refer-form-sec">
          <div className="col-md-5">
            <div className="refer-image">
              <img
                src="https://i.ibb.co/72ngXX8/big-image.jpg"
                alt="big-image"
                style={{ borderRadius: "10px", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="refer-form">
              <h2>Friends To Friends</h2>
              <p>
                Talking about friends helps to normalize it. You can start{" "}
                <a href="#">NOW!</a>
              </p>
              <form onSubmit={postData}>
                <input
                  type="text"
                  name="Your Name"
                  placeholder="Your Friend Name"
                  value={friendName}
                  onChange={(e) => setFriendName(e.target.value)}
                />
                <input
                  type="email"
                  name="Your Email"
                  placeholder="Your Friend Email"
                  value={friendEmail}
                  onChange={(e) => setFriendEmail(e.target.value)}
                />
                <p>
                  <label className="checkbox-label">
                    <input type="checkbox" />I have read and accept the T&C and
                    Privacy Policy
                  </label>
                </p>
                <button type="submit">REFER &amp; EARN</button>
              </form>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col">
            <div className="referral-progress-div">
              <h2>YOUR REFERRAL PROGRESS</h2>
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <td>No. of friends who have purchased</td>
                    <td>
                      <strong>INR : 1000.00</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>No. of friends who have purchased</td>
                    <td>
                      <strong>INR : 1000.00</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>No. of friends who have purchased</td>
                    <td>
                      <strong>INR : 1000.00</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferEarn;
