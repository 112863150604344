import React, { useEffect, useState } from "react";
import Instence from "../../Utils/api";
import Select from "react-select";
import { click } from "@testing-library/user-event/dist/click";

const religions = [
  {
    religion: "Hindu",
    castes: [
      "Brahmin",
      "Kshatriya",
      "Vaishya",
      "Shudra",
      "24 Manai Telugu Chettiar",
      "96 Kuli maratha",
      "Achira Pakkam Chettiar",
      "Adi Andhra",
      "Agamudayar/ Arcot/ Tuluva Vellala",
      "Agaram Vellan Chettiar",
      "Agnikula Kshatriya",
      "Ahirwar",
      "Aramari/ Gabit",
      "Arekatika",
      "Arya Vysya",
      "Ayira Vysya",
      "Ayyaraka",
      "Bairwa",
      "Balai",
      "Balija",
      "Balija Naidu",
      "Balija Reddy",
      "Beri Chettiar",
      "Besta",
      "Bhatraju",
      "Brahmin-Anaviln Desai",
      "Brahmin-Baidhiki/Vaidhiki",
      "Brahmin-Bardai",
      "Brahmin-Bhargav",
      "Brahmin-Dravida",
      "Brahmin-Khadayata",
      "Brahmin-Koteshwara/Kota(Madhwa)",
      "Brahmin-Madhwa",
      "Brahmin-Mevada",
      "Brahmin-Niyogi",
      "Brahmin-Others",
      "Brahmin-Rajgor",
      "Brahmin-Rarhi/Radhi",
      "Brahmin-Sarua",
      "Brahmin-Shivalli(Madhwa)",
      "Brahmin-Shri Gaud",
      "Brahmin-Shri Mali",
      "Brahmin-Shukla Yajurvedi",
      "Brahmin-Smartha",
      "Brahmin-Sri Vaishnava",
      "Brahmin-Tapodhan",
      "Brahmin-Vaidiki",
      "Brahmin-Vaikhanasa",
      "Brahmin-Valam",
      "Brahmin-Velanadu",
      "Brahmin-Zalora",
      "Chattada Sri Vaishnava",
      "Chettiar",
      "Chippolu(Mera)",
      "Darji",
      "Deshmukh",
      "Devanga",
      "Devanga Chettiar",
      "Dhanak",
      "Dommala",
      "Dravida Brahmin",
      "Ediga/Goud(Balija)",
      "Elur Chetty",
      "Gajula / Kavarai",
      "Gandla",
      "Gandla/Ganiga",
      "Gavara",
      "Gond",
      "Goud",
      "Gowda",
      "Gowda-Kuruba Gowda",
      "Intercaste",
      "Isai Vellalar",
      "Jaalari",
      "Jandra",
      "Jangam",
      "Jetty/Malla",
      "Jhadav",
      "Julaha",
      "kachara",
      "Kaikaala",
      "Kalinga",
      "Kalinga Vaisya",
      "Kamma",
      "Kamma Naidu",
      "Kanakkan Padanna",
      "Kandara",
      "Kapu",
      "Kapu Naidu",
      "Kapu Reddy",
      "Karakala Bhakthula",
      "Karni Bhakthula",
      "Kasukara",
      "Kavara",
      "Kerala Mudali",
      "Khatik",
      "Kongu Chettiar",
      "Kongu Nadar",
      "Kori / Koli",
      "Kumaoni rajput",
      // Add more caste values here if available
    ],
  },
  { religion: "Muslim", castes: ["Sunni", "Shia"] },
  {
    religion: "Christian",
    castes: [
      "Adventist",
      "Anglican / Episcopal",
      "Anglo-Indian",
      "Apostolic",
      "Assembly of God (AG)",
      "Baptist",
      "Born Again",
      "Brethren",
      "Calvinist",
      "Chaldean Syrian (Assyrian)",
      "Church of Christ",
      "Church of God",
      "Church of North India",
      "Church of South India",
      "Congregational",
      "Evangelist",
      "Jacobite",
      "Jehovah's Witnesses",
      "Knanaya",
      "Knanaya Catholic",
      "Knanaya Jacobite",
      "Malabar Independent Syrian Church",
      "Reformed Baptist",
      "St. Thomas Evangelical",
      "Christian - Others",
      "Don't wish to specify",
      // Add more caste values here if available
    ],
  },
];

const ReligionEdit = ({ religionData }) => {
  console.log(religionData);

  const userId = JSON.parse(localStorage.getItem("userData"));

  const [isEditing, setIsEditing] = useState(false);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedCaste, setSelectedCaste] = useState(null);
  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");
  const [gothram, setGothram] = useState("");
  const [dosham, setDosham] = useState("");
  const [starRaasi, setRasi] = useState("");
  const [division, setDivision] = useState(null);


  console.log(division)

  useEffect(() => {
    if (religionData && religionData.tblReligions.length > 0) {
      const userReligionData = religionData.tblReligions[0];
      setSelectedReligion({
        value: userReligionData.religion,
        label: userReligionData.religion,
      });
      setSelectedCaste({
        value: userReligionData.caste,
        label: userReligionData.caste,
      });
      setCaste(userReligionData.caste);
      setSubCaste(userReligionData.subCaste);
      setGothram(userReligionData.gothram);
      setDosham(userReligionData.dosham);
      setRasi(userReligionData.starRaasi);
      setDivision({
        value: userReligionData.division,
        label: userReligionData.division,
      });;
    }
  }, [religionData]);

  const handleReligionCancel = () => {
    setIsEditing(false);
  };

  const handleReligionToggleEdit = () => {
    setIsEditing(true);
  };

  const handleReligionChange = (selectedReligion) => {
    setSelectedReligion(selectedReligion);
    setSelectedCaste(null); // Reset caste selection when religion changes
    setDivision(null); // Reset caste selection when religion changes
  };

  const handleCasteChange = (selectedCaste) => {
    if (selectedReligion && selectedReligion.value === "Christian") {
      setDivision(selectedCaste);
      console.log(selectedCaste.value)
    } else {
      setSelectedCaste(selectedCaste);
    }
  };

  const getCastesForSelectedReligion = () => {
    if (selectedReligion) {
      const religionObj = religions.find(
        (rel) => rel.religion === selectedReligion.value
      );
      return religionObj
        ? religionObj.castes.map((caste) => ({ value: caste, label: caste }))
        : [];
    }
    return [];
  };

  const handleReligionDetailsUpdate = async () => {
    console.log("Clicked on Update");

    let divisionValue = selectedCaste ? selectedCaste.value : null;
    if (selectedReligion && selectedReligion.value === "Christian") {
      divisionValue = division.value;
    }

    const religionData = {
      religion: selectedReligion ? selectedReligion.value : null,
      caste: selectedCaste ? selectedCaste.value : null,
      subCaste,
      gothram,
      starRaasi,
      dosham,
      division: divisionValue,
    };

    try {
      const response = await Instence.post(
        `User/UpdateReligionInfo/${userId.id}`,
        religionData
      );
      setIsEditing(false);
      console.log("Update successful:", response.data);
    } catch (error) {
      console.error("Update failed:", error);
      // You may want to add error handling here, such as displaying an error message to the user.
    }
  };

  return (
    <div>
      <div className="row">
        {isEditing ? (
          <div className="info-div">
            <div className="first-div">
              <p>Religion Details</p>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="country">
                  Religion<span className="mandatory">*</span>
                </label>
                <Select
                  className="manual-Select-Tag"
                  value={selectedReligion}
                  onChange={handleReligionChange}
                  options={religions.map((religion) => ({
                    value: religion.religion,
                    label: religion.religion,
                  }))}
                  isSearchable
                  placeholder="Select religion.."
                />
              </div>

              {selectedReligion && (
                <div className="input-row">
                  <div className="input-group">
                    <label htmlFor="caste">
                      {selectedReligion.value === "Christian"
                        ? "Division"
                        : "Caste"}
                      <span className="mandatory">*</span>
                    </label>
                    <Select
                      className="manual-Select-Tag"
                      value={selectedReligion.value === "Christian"
                        ? division
                        : selectedCaste}
                      onChange={handleCasteChange}
                      options={getCastesForSelectedReligion()}
                      isSearchable
                      placeholder="Search for caste.."
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="city">
                  {selectedReligion && selectedReligion.value === "Christian"
                    ? "Caste"
                    : "Sub Caste"}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="subCaste"
                  placeholder="Enter Sub Caste"
                  value={
                    selectedReligion && selectedReligion.value === "Christian"
                      ? caste
                      : subCaste
                  }
                  onChange={(e) =>
                    selectedReligion && selectedReligion.value === "Christian"
                      ? setCaste(e.target.value)
                      : setSubCaste(e.target.value)
                  }
                />
              </div>
            </div>
            {/* setCaste(e.target.value) */}
            {selectedReligion && selectedReligion.value === "Hindu" && (
              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="gothram">
                    Gothram<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="gothram"
                    placeholder="Enter gothram"
                    value={gothram}
                    onChange={(e) => setGothram(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="dosham">
                    Dosham<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="dosham"
                    placeholder="Enter dosham"
                    value={dosham}
                    onChange={(e) => setDosham(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="starRaasi">
                    Rasi<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="starRaasi"
                    placeholder="Enter Rasi"
                    value={starRaasi}
                    required
                    onChange={(e) => setRasi(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="profile-btn-div">
              <button className="cancel-btn" onClick={handleReligionCancel}>
                Cancel
              </button>
              <button
                className="profile-btn"
                onClick={handleReligionDetailsUpdate}
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-12">
                <div className="high">
                  <p>RELIGION DETAILS</p>
                  <button
                    className="profile-btn"
                    onClick={handleReligionToggleEdit}
                  >
                    Edit
                  </button>
                </div>
                <div className="low">
                  <div className="left-div">
                    <p>
                      <span>Religion :</span>{" "}
                      <span className="span-two">
                        {selectedReligion && selectedReligion.label}
                      </span>
                    </p>

                    <p>
                      <span>{selectedReligion &&
                        selectedReligion.value === "Hindu" ? "Sub Caste" : "Caste"}</span>{" "}
                      <span className="span-two">  {selectedReligion &&
                        selectedReligion.value === "Hindu" ? subCaste : caste}</span>
                    </p>

                    {selectedReligion &&
                      selectedReligion.value === "Hindu" && (
                        <>
                          <p>
                            <span>Rasi :</span>{" "}
                            <span className="span-two"> {starRaasi}</span>
                          </p>
                        </>
                      )}
                  </div>
                  <div className="right-div">
                    <p>
                      <span>
                        {selectedReligion &&
                          selectedReligion.value === "Christian"
                          ? "Division :"
                          : "Caste :"}
                      </span>{" "}
                      <span className="span-two">
                        {selectedReligion &&
                          selectedReligion.value === "Christian"
                          ? division && division.label : selectedCaste && selectedCaste.label}
                        {/* {selectedCaste && selectedCaste.label} */}
                      </span>
                    </p>

                    {selectedReligion &&
                      selectedReligion.value === "Hindu" && (
                        <>
                          <p>
                            <span>Gothram :</span>{" "}
                            <span className="span-two"> {gothram}</span>
                          </p>
                          <p>
                            <span>Dosham :</span>{" "}
                            <span className="span-two"> {dosham}</span>
                          </p>
                        </>
                      )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReligionEdit;
