import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { GrGallery } from "react-icons/gr";
import { FaCircleUser } from "react-icons/fa6";
import { BiSolidImageAdd } from "react-icons/bi";
import { MdOutlineLogout } from "react-icons/md";
import EditProfile from "./EditProfile";
import EditPreferences from "./EditPreferences";
import Packages from "./Packages";
import ChangePassword from "./ChangePassword";
import Help from "./Help";
import SafeMatrimony from "./SafeMatrimony";
import { CgProfile } from "react-icons/cg";
import { RiGalleryFill } from "react-icons/ri";
import { LuPackage2 } from "react-icons/lu";
import { LuKeyRound } from "react-icons/lu";
import { IoNotificationsOutline } from "react-icons/io5";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { MdOutlineSafetyCheck } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaUserCog } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Instence from "../Utils/api";
import Partner from "./Partner";
import ReferEarn from "./ReferEarn";
import profile from "../assets/images/blank-profile.png";
import SwiperItem from "../component/SwiperItem";
import NewMatchesItem from "../component/NewMatchesItem";
import RecommendationsItem from "../component/RecommendationsItem";
import FAQ from "./FAQ";
import { GrCircleAlert } from "react-icons/gr";
import "../component/SwiperItem.css";
import Swal from "sweetalert2";

const Dashboard = ({ userData, userImg, fetchData, handleUpgradeClick }) => {
  // getting data from local storage
  const userId = JSON.parse(localStorage.getItem("userData"));

  const basicInformation =
    userData && userData.tblBasicInfos[0] && userData.tblBasicInfos[0];

  const ispackage = userData && userData.tblPackages && userData.tblPackages;

  const navigate = useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleOpenModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseModal = () => {
    setShowUploadModal(false);
  };

  const handleLogoutAccount = () => {
    localStorage.removeItem("userLogin");
    navigate("/");
    window.location.reload();
  };

  const [imageFile, setImageFile] = useState("");

  const userID = JSON.parse(localStorage.getItem("userData"));

  const handleImageUpload = async () => {
    if (!imageFile) {
      alert("Please select an image");
      return;
    }

    const formData = new FormData();
    formData.append("UserId", userID.id);
    formData.append("file", imageFile);

    try {
      const response = await Instence.post("User/uploadprofile", formData);

      if (response.data.statusCode === 200) {
        setShowUploadModal(false);
        fetchData();
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [viewedlnth, setViewedlnth] = useState([]);
  console.log(viewedlnth);

  const fetchviewedyouData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/ViewedYou/${id}`);
      setViewedlnth(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const countUniqueViewedUsers = () => {
    const uniqueViewedUsers = new Set();

    viewedlnth.forEach((item) => {
      uniqueViewedUsers.add(item.viewedUserId);
    });

    return uniqueViewedUsers.size;
  };

  const [viewedbylnth, setViewedbylnth] = useState(0);
  console.log(viewedbylnth);

  const fetchviewedByYouData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/ViewedByYou/${id}`);
      const lengthofviewedByyouData = response.data.length;
      setViewedbylnth(lengthofviewedByyouData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchviewedByYouData();
    fetchviewedyouData();
  }, []);

  const [activeTab, setActiveTab] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const verticalTabs = [
    {
      name: "Edit Profile",
      component: <EditProfile />,
      icon: <FaUserEdit />,
    },
    // {
    //   name: "Verify Your Profile",
    //   component: <EditPreferences />,
    //   icon: <IoShieldCheckmark />,
    // },
    // { name: "Happy Story", component: <HappyStory />, icon: <LiaHandshake /> },
    {
      name: "Account Settings",
      component: <ChangePassword />,
      icon: <FaUserCog />,
    },
    {
      name: "Refer and Earn",
      component: <ReferEarn />,
      icon: <FaRupeeSign />,
    },
    {
      name: "Help",
      component: <FAQ />,
      icon: <FaHandsHelping />,
    },
    {
      name: "Want to be a partner",
      component: <Partner />,
      icon: <FaHandshake />,
    },
  ];

  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const toggleDelete = () => {
    setDeleteOpen(!isDeleteOpen);
  };

  const handleDeleteAccount = async () => {
    try {
      // Display confirmation dialog
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover your account!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      // Proceed with account deletion if the user confirms
      if (confirmResult.isConfirmed) {
        const response = await Instence.post(
          `/User/DeleteUser?id=${userId.id}`
        );
        if (response.status === 200) {
          console.log(response.status);
          console.log("User Deleted Successfully !");
          localStorage.removeItem("userData");
          handleLogoutAccount();
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <div class="aiz-user-panel overflow-hidden">
          <div className="row">
            <div className="col-md-4">
              <div className="dashboard-sidebar">
                <div className="profile-div">
                  <div className="img-profile-div2">
                    <img
                      className="profile_img2"
                      src={`https://sams.drmarins.cloud/${userImg}` || userImg}
                      alt=""
                    />
                  </div>

                  <BiSolidImageAdd
                    className="add_image"
                    onClick={handleOpenModal}
                  />

                  <p>{basicInformation && basicInformation.firstName}</p>
                  <span>{basicInformation && basicInformation.userId}</span>
                  <span>
                    Membership:{" "}
                    <h6
                      style={{ display: "inline-block" }}
                      className="text-black"
                    >
                      {ispackage && ispackage?.length == 0 ? "Free" : "Premium"}
                    </h6>
                  </span>
                  {ispackage && ispackage?.length == 0 ? (
                    <span>Become a paid member now</span>
                  ) : (
                    <span>You are Paid member now</span>
                  )}

                  {ispackage && ispackage?.length == 0 ? (
                    <button onClick={handleUpgradeClick}>Upgrade</button>
                  ) : null}
                </div>
                <div className="sidebar-tabs">
                  {/* vertical tabs here */}
                  {verticalTabs.map((tab) => (
                    <div key={tab.name} className="vertical-tab">
                      <button
                        onClick={() => handleTabClick(tab.name)}
                        className={activeTab === tab.name ? "active" : ""}
                      >
                        {tab.icon} {tab.name}
                      </button>
                    </div>
                  ))}

                  <div
                    onClick={handleLogoutAccount}
                    className="profile-logout-btn"
                  >
                    <MdOutlineLogout className="logout-coni" />
                    Logout
                  </div>
                </div>
              </div>
              <div className="dashboard-sidebar" style={{ marginTop: "10px" }}>
                <div className="profile-btn" onClick={handleDeleteAccount}>
                  <RiDeleteBin6Line className="logout-coni" />
                  Delete Account
                </div>
              </div>
            </div>
            <div className="col-md-8 ">
              {activeTab &&
                verticalTabs.find((tab) => tab.name === activeTab)?.component}
              {!activeTab && (
                <>
                  <div class="row gutters-5">
                    <div class="col-md-4 mb-4">
                      <div class="bg-light rounded overflow-hidden text-center p-5">
                        <FaRegHeart class="Icons_chat" />
                        <div class="h4 fw-700 text-primary-grad">
                          {countUniqueViewedUsers()}
                        </div>
                        <div class="opacity-50">Viewed by you</div>
                      </div>
                    </div>
                    <div class="col-md-4  mb-4">
                      <div class="bg-light rounded overflow-hidden text-center p-5">
                        <FaRegHeart class="Icons_chat" />
                        <div class="h4 fw-700 text-primary-grad">
                          {viewedbylnth}
                        </div>
                        <div class="opacity-50 ">Viewed you</div>
                      </div>
                    </div>
                  </div>
                  <div class="row pb-5">
                    <div class="col-md-12 bg-white-color">
                      <div class="card-header pb-3">
                        <h6 class="fsize-24  mb-0">
                          Your Daily Recommendations
                        </h6>
                      </div>
                      <div className="d-flex swiper-div">
                        <RecommendationsItem />
                      </div>
                    </div>
                  </div>
                  {ispackage && ispackage?.length === 0 ? (
                    <div className="row m-3 bg-banner_img">
                      <div class="col-md-12 d-flex justify-content-end ">
                        <button
                          onClick={handleUpgradeClick}
                          class="btn btn-danger"
                        >
                          UpGrade
                        </button>
                      </div>
                    </div>
                  ) : undefined}
                  <div class="row  ">
                    <div class="col-md-12 bg-white-color">
                      <div class="card-header pb-3">
                        <h6 class="fsize-24  mb-0">All Matches</h6>
                        <p>Members who match your partner preferences</p>
                      </div>
                      <div className="d-flex swiper-div">
                        <SwiperItem />
                      </div>
                    </div>
                  </div>
                  <div class="row pt-5">
                    <div class="col-md-12 bg-white-color">
                      <div class="card-header pb-3">
                        <h6 class="fsize-24  mb-0">New Matches</h6>
                        <p>
                          Members who match your preferences and have joined in
                          the last 30 days
                        </p>
                      </div>
                      <div className="d-flex swiper-div">
                        <NewMatchesItem />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ********************************************************************************************************* */}

      {showUploadModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="text-black">Upload Image</h6>
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              {/* Your upload button for the image */}
              <input
                className="input"
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger" onClick={handleImageUpload}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ******************** */}
      {/* ************************** Delete Account Offcanvas ******************************** */}
      {isDeleteOpen && (
        <>
          <div className="depart-overlay"></div>
          <div
            className={`depart-delete-offcanvas-menu ${
              isDeleteOpen ? "open" : ""
            }`}
          >
            <div className="depart-delete-offcanvas-content">
              <div className="red-alert-div">
                <GrCircleAlert className="red-alert" />
              </div>
              <h1>Are you sure ?</h1>
              <p>Once deleted you will not be able to recover the record!</p>
            </div>
            <div className="depart-delete-offcanvas-footer">
              <button className="btn-cancel" onClick={toggleDelete}>
                Cancel
              </button>
              <button className="btn-delete">Delete</button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
