import React, { useState } from "react";
import "./mycss.css";
import Instence from "../../Utils/api";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
const bannertitle = "Create a Matrimony Profile";
const bannerdesc = (
  <p className="ban-desc">
    <strong>Find Your Best Match.</strong>
  </p>
);
const BannerSection = () => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [onBehalf, setOnBehalf] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    fullName: "",
    gender: "",
    onBehalf: "",
    phone: "",
  });

  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Full name is required")
      .min(2, "Enter your full name"),
    gender: Yup.string().required("Gender is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number is not valid")
      .required("A phone number is required"),
    onBehalf: Yup.string().required("This field is required"),
    termsAccepted: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await formSchema.validate(
        { fullName, gender, phone, onBehalf },
        { abortEarly: false }
      );

      const formData = {
        fullName,
        gender,
        phone,
        onBehalf,
      };
      navigate("/detailsform");
      localStorage.setItem("formData", JSON.stringify(formData));
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      } else {
        console.error("Validation error:", error);
      }
    }
  };

  const validatePhoneNumber = (value) => {
    if (value.length < 10) {
      return "Please enter a 10-digit phone number";
    }
    return "";
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (formattedValue.length > 10) return; // Limit to 10 digits
    const errorMessage = validatePhoneNumber(formattedValue);
    setErrors({ phone: errorMessage });
    setPhone(formattedValue);
  };

  const handleKeyPress = (e) => {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <section className="banner-section">
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="banner-content">
                <div className="intro-form">
                  <div className="intro-form-inner">
                    <h3 style={{ fontSize: "27px", fontWeight: "bolder" }}>
                      {bannertitle}
                    </h3>
                    {bannerdesc}
                    <form className="banner-form" onSubmit={handleSubmit}>
                      <div className="gender">
                        <label className="left">On Behalf</label>
                        <div className="custom-select right">
                          <select
                            value={onBehalf}
                            onChange={(e) => setOnBehalf(e.target.value)}
                            name="gender"
                            id="gender"
                            className=""
                          >
                            <option value="0">Select Profile</option>
                            <option value="Myself">Myself</option>
                            <option value="Friend">Friend</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Daughter/Son">Daughter/Son</option>
                          </select>
                          {errors.onBehalf && (
                            <div className="error-message">
                              {errors.onBehalf}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="gender">
                        <label className="left">Full Name</label>
                        <div className="right">
                          <input
                            className="w-100"
                            type="text"
                            id="city"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Enter Full Name.."
                          />
                          {errors.fullName && (
                            <div className="error-message">
                              {errors.fullName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="gender">
                        <label className="left">I am a </label>
                        <div className="custom-select right">
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            name="gender"
                            id="gender"
                            className=""
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="others">Others</option>
                          </select>
                          {errors.gender && (
                            <div className="error-message">{errors.gender}</div>
                          )}
                        </div>
                      </div>
                      <div className="gender">
                        <label className="left">Phone </label>
                        <div className="right">
                          <input
                            className="w-100"
                            type="text"
                            id="city"
                            value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                            onChange={handlePhoneChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Enter Phone Number.."
                          />
                          {errors.phone && (
                            <div className="error-message">{errors.phone}</div>
                          )}
                        </div>
                      </div>
                      <button type="submit">Register Free</button>
                      <div className="chk">
                        <span className="chk2">
                          *By signing up you agree to our terms and conditions.
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumb">
                <img src="assets/images/banner/couple.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-shapes">
        <img
          src="assets/images/banner/banner-shapes/01.png"
          alt="shape"
          className="banner-shape shape-1"
        />
        <img
          src="assets/images/banner/banner-shapes/02.png"
          alt="shape"
          className="banner-shape shape-2"
        />
        <img
          src="assets/images/banner/banner-shapes/03.png"
          alt="shape"
          className="banner-shape shape-3"
        />
        <img
          src="assets/images/banner/banner-shapes/04.png"
          alt="shape"
          className="banner-shape shape-4"
        />
        <img
          src="assets/images/banner/banner-shapes/05.png"
          alt="shape"
          className="banner-shape shape-5"
        />
        <img
          src="assets/images/banner/banner-shapes/are.png"
          alt="shape"
          className="banner-shape shape-6"
        />
        <img
          src="assets/images/banner/banner-shapes/07.png"
          alt="shape"
          className="banner-shape shape-7"
        />
        <img
          src="assets/images/banner/banner-shapes/08.png"
          alt="shape"
          className="banner-shape shape-8"
        />
      </div>
    </section>
  );
};
export default BannerSection;
