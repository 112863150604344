import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Instence from "../../Utils/api";

import userImg from "../../assets/images/blank-profile.png";
import { GiSonicLightning } from "react-icons/gi";

const MemberTwoSection = () => {
  const [profiles, setProfiles] = useState([]);
  const [maleUsers, setMaleUsers] = useState([]);
  const [femaleUsers, setFemaleUsers] = useState([]);

  // ! get all members profiles Data

  const fetchProfiles = async () => {
    try {
      const response = await Instence.get("/User/GetAllProfiles");
      if (response.status === 200) {
        // Assuming response.data is an array of profiles
        const sortedProfiles = response.data.sort((a, b) => {
          // Assuming there's a property named 'joinDate' in each profile
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        // Set only the six newest profiles
        setProfiles(sortedProfiles.slice(0, 10));
      } else {
        console.error("Failed to fetch profiles");
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  // ! get male profiles from the api

  const fetchMaleData = async () => {
    try {
      const response = await Instence.get("/User", {
        params: {
          gender: "male",
        },
      });
      const sortedUsers = response.data.sort((a, b) => {
        console.log(a, b);
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const slicedUsers = sortedUsers.slice(0, 5);
      setMaleUsers(slicedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  // ! get female profiles from the api

  const fetchFemaleData = async () => {
    try {
      const response = await Instence.get("/User", {
        params: {
          gender: "female",
        },
      });
      const sortedUsers = response.data.sort((a, b) => {
        console.log(a, b);
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const slicedUsers = sortedUsers.slice(0, 5);
      setFemaleUsers(slicedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProfiles();
    fetchMaleData();
    fetchFemaleData();
  }, []);

  return (
    <section className="top-member-section padding-tb">
      <div className="container">
        <div className="section-header">
          <h4 className="theme-color">Top Members</h4>
          <h2>Sam's Matrimony Members</h2>
        </div>
        <div className="section-wrapper">
          <ul
            className="nav nav-tab sbutton-group filters-button-group w-100 d-flex flex-wrap justify-content-center"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="filter-btn active"
                id="boygirl-tab"
                data-bs-toggle="tab"
                data-bs-target="#boygirl"
                type="button"
                role="tab"
                aria-controls="boygirl"
                aria-selected="true"
              >
                <i className="icofont-heart-alt"></i> Show all
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="filter-btn"
                id="girl-tab"
                data-bs-toggle="tab"
                data-bs-target="#girl"
                type="button"
                role="tab"
                aria-controls="girl"
                aria-selected="true"
              >
                <i className="icofont-girl"></i> new Female member
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="filter-btn"
                id="boy-tab"
                data-bs-toggle="tab"
                data-bs-target="#boy"
                type="button"
                role="tab"
                aria-controls="boy"
                aria-selected="true"
              >
                <i className="icofont-hotel-boy"></i> New Male Member
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="boygirl"
              role="tabpanel"
              aria-labelledby="boygirl-tab"
            >
              <div className="grid-memberlist d-flex flex-wrap">
                {profiles.map((member) => (
                  <div className="grid-member filter-item" key={member.id}>
                    <Link to="/login">
                      <div className="lab-item member-item style-1 style-2">
                        <div className="lab-inner">
                          <div className="lab-thumb">
                            <img
                              className="Card_Profile_Imges"
                              src={userImg}
                              alt="member-img"
                            />

                          </div>
                          <div className="lab-content">
                            <h6>
                              <Link>{member.tblBasicInfos[0].firstName}</Link>{" "}
                            </h6>
                            <p>
                              {member.tblProffessionInformations[0].occupation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {/* girls section */}
            <div
              className="tab-pane fade"
              id="girl"
              role="tabpanel"
              aria-labelledby="girl-tab"
            >
              <div className="grid-memberlist d-flex flex-wrap">
                {femaleUsers.map((member) => (
                  <div className="grid-member filter-item" key={member.id}>
                    <Link to="/login">
                      <div className="lab-item member-item style-1 style-2">
                        <div className="lab-inner">
                          <div className="lab-thumb">
                            <img
                              className="Card_Profile_Imges"
                              src={userImg}
                              alt="member-img"
                            />

                          </div>
                          <div className="lab-content">
                            <h6>
                              <Link>{member.tblBasicInfos[0].firstName}</Link>{" "}
                            </h6>
                            <p>
                              {member.tblProffessionInformations[0].occupation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {/* Boys Section */}
            <div
              className="tab-pane fade"
              id="boy"
              role="tabpanel"
              aria-labelledby="boy-tab"
            >
              <div className="grid-memberlist d-flex flex-wrap">
                {maleUsers.map((member) => (
                  <div className="grid-member filter-item" key={member.id}>
                    <Link to="/login">
                      <div className="lab-item member-item style-1 style-2">
                        <div className="lab-inner">
                          <div className="lab-thumb">
                            <img
                              className="Card_Profile_Imges"
                              src={userImg}
                              alt="member-img"
                            />

                          </div>
                          <div className="lab-content">
                            <h6>
                              <Link>{member.tblBasicInfos[0].firstName}</Link>{" "}
                            </h6>
                            <p>
                              {member.tblProffessionInformations[0].occupation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemberTwoSection;
