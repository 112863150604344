import React from "react";
import FooterSection from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const Refund_Cancellation = () => {
  return (
    <>
      <Header />
      <PageHeader title={"Refund Cancellation"} />
      <section className="m-5 privacy-policy-container">
        <div className="container">
          <div className="p-4  rounded shadow-sm overflow-hidden mw-100 text-left">
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
                fontSize: 19,
              }}
            >
              <p style={{ fontWeight: 700, fontSize: 24 }}>
                Returns and Cancellation Policy
              </p>
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              Thank you for using the services of&nbsp; Samsmatrimony India PVT
              LTD. This page outlines our Returns and Cancellation Policy.
              Please read and understand the following terms governing returns
              and cancellations. By using our platform, you agree to comply with
              these policies.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <h5>1. Cancellation of Services:</h5>
              &nbsp;As a user of&nbsp; Samsmatrimony India PVT LTD, you have the
              right to cancel your subscription or any paid services at any
              time. To initiate the cancellation process, please contact our
              support team or follow the cancellation instructions provided on
              our website.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <h5>2. Refunds:</h5>
              &nbsp;Refunds for cancelled subscriptions or services will be
              processed according to the following guidelines:
            </p>
            <p>
              If you request a cancellation within 5 days from the date of
              purchase, you will be eligible for a full refund.
            </p>
            <p>
              If you request a cancellation after 10 days from the date of
              purchase, a pro-rata refund may be applicable, depending on the
              services used and the remaining duration of your subscription.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <h5>3. Refund Process:</h5>
              &nbsp;To request a refund, please contact our support team through
              the provided contact details. Our team will verify your request
              and initiate the refund process within 10 business days. The
              refunded amount will be credited to the original payment method
              used during the purchase.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <p style={{ fontWeight: 700 }}>4. Non-Refundable Services:</p>
              &nbsp;Certain services or features may be marked as
              non-refundable. These services will not be eligible for a refund
              upon cancellation. Please refer to the specific terms and
              conditions associated with each service to understand its refund
              status.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <p style={{ fontWeight: 700 }}>5. Discontinuation of Services:</p>
              &nbsp;
              <p
                style={{
                  color: "rgb(255 , 255, 255)",

                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Samsmatrimony India PVT LTD
              </p>
              &nbsp;reserves the right to discontinue or modify any services or
              features offered on our platform without prior notice. In such
              cases, if you have an active subscription for a service that is
              being discontinued, you may be offered an alternative or a
              pro-rated refund for the remaining duration of the service.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <p style={{ fontWeight: 700 }}>
                6. Service Quality and Satisfaction:
              </p>
              &nbsp;We strive to provide high-quality services to all our users.
              If you encounter any issues or have concerns regarding our
              services, please contact our support team, and we will make every
              effort to resolve the matter to your satisfaction.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <p style={{ fontWeight: 700 }}>7. Changes to the Policy:</p>
              &nbsp;
              <p
                style={{
                  color: "rgb(255 , 255, 255)",

                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Samsmatrimony India PVT LTD
              </p>
              &nbsp;may update or modify the Returns and Cancellation Policy
              from time to time. Any changes will be communicated through the
              website, and the revised policy will be effective immediately upon
              posting.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              <p style={{ fontWeight: 700 }}>8. Contact Us:</p>&nbsp;If you have
              any questions or require assistance with returns, cancellations,
              or refunds, please contact our support team at
              Samsmatrimony@gmail.com or the provided contact details.
            </p>
            <p
              style={{
                border: 0,
                fontSize: 16,
                marginRight: 0,
                marginBottom: "1.6em",
                marginLeft: 0,
                outline: 0,
                padding: 0,
                verticalAlign: "baseline",
                color: "rgb(255 , 255, 255)",
              }}
            >
              By using&nbsp;
              <p
                style={{
                  color: "rgb(255 , 255, 255)",

                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Samsmatrimony India PVT LTD
              </p>
              , you acknowledge that you have read and understood our Returns
              and Cancellation Policy. We value your trust and are committed to
              providing a transparent and customer-centric experience on our
              platform.
            </p>
          </div>
        </div>
      </section>

      <FooterSection />
    </>
  );
};

export default Refund_Cancellation;
