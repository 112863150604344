import React, { useEffect, useState } from "react";
import { ImSad } from "react-icons/im";
import "../Dashboard/css/Notifications.css";
import Instence from "../Utils/api";
import userImg from "../assets/images/blank-profile.png";
import { Link } from "react-router-dom";
const IgnoredUserList = () => {
  const [users, setUsers] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [error, setError] = useState("");

  const filterId = userdata && userdata;
  const intrestedUserIds = filterId && filterId.map((item) => item.userId);

  // Filter users based on intrestedUserIds if it's defined
  const matchedUsers = intrestedUserIds
    ? users.filter((user) => intrestedUserIds.includes(user.id))
    : [];
  console.log(matchedUsers);

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/ShortlistedYou/${id}`);
      setUserData(response.data);
      console.log(response.data);
    } catch (error) {
      setError(error);
    }
  };

  // ! local storage data for all matches
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userDatagender =
    userData.tblBasicInfos[0].gender === "male" ? "female" : "male";

  // ! data fetching from api using axios
  const fetchUsers = async () => {
    try {
      const response = await Instence.get(`/User?gender=${userDatagender}`);
      setUsers(response.data);
      console.log(response.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchUserData();
  }, []);
  // Function to format timestamp into a more readable format
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div>
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0 text-black h6">Notifications</h5>
        </div>
        <div class="card-body">
          <div className="table-div-box">
            <div className="CardsDiv">
              {matchedUsers.length > 0 ? (
                matchedUsers.map((user) => (
                  <div className="notification_main" key={user.id}>
                    <div className="notification_leftdiv">
                      {user.tblGalleries &&
                      user.tblGalleries.length > 0 &&
                      user.tblGalleries[0].profilePic ? (
                        <img
                          src={`https://sams.drmarins.cloud/${user.tblGalleries[0].profilePic}`}
                          alt=""
                          className="notificationImg"
                        />
                      ) : (
                        <img src={userImg} alt="" /> // Provide a fallback image if profilePic is not available
                      )}
                      <div className="text">
                        <p>
                          <span> {user.tblBasicInfos[0].firstName} </span>
                          has viewed your profile.
                        </p>
                        <Link to={`/user_profile/${user.id}`}>
                          <button className="outline-button">
                            view Profile
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <p>{formatDate(user.createdAt)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div class="aiz-pagination">
                  <div class=" text-center pt-10 text-black">
                    <ImSad className="Icons_chat" />
                    <h4 className="text-black">Nothing Found</h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgnoredUserList;
