import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FooterSection from "../component/layout/footer";
import Instance from "../Utils/api.js";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import * as Yup from "yup";
import Swal from "sweetalert2";

const title = "Login";

const EmailLogin = ({ onUpdateUserLogin }) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [otp, setOTP] = useState("");
    const [generatedOTP, setGeneratedOTP] = useState("");
    const [showGenerateOTP, setShowGenerateOTP] = useState(false);
    const [validationError, setValidationError] = useState("");

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required"),
    });

    // Function to clear validation error after 5 seconds
    useEffect(() => {
        if (validationError) {
            const timeout = setTimeout(() => {
                setValidationError("");
            }, 3000); // 5000 milliseconds = 5 seconds

            return () => clearTimeout(timeout);
        }
    }, [validationError]);

    const generateOTP = async () => {
        try {
            const newOTP = Math.floor(1000 + Math.random() * 9000);
            setGeneratedOTP(newOTP);
            setShowGenerateOTP(true); // Show the OTP input field after OTP is generated
            console.log(newOTP);

            const verificationMessage = `
            <html>
                <head>
                    <style>
                        .container {
                            max-width: 600px;
                            margin: 0 auto;
                            font-family: Arial, sans-serif;
                            color: #333;
                        }
                        .message {
                            margin-bottom: 20px;
                        }
                        .code {
                            font-size: 24px;
                            font-weight: bold;
                        }
                        .footer {
                            margin-top: 20px;
                            font-size: 14px;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="message">
                            <p>Hi there,</p>
                            <p>Your verification code is: <span class="code">${newOTP}</span>.</p>
                            <p>Please use this code to verify your email address.</p>
                        </div>
                        <div class="footer">
                            <p>Thank you.</p>
                        </div>
                    </div>
                </body>
            </html>
        `;



            const res = await Instance.get(`/User/LoginWithMail?email=${email}`);

            console.log(res)


            const formData = new FormData();
            formData.append('To', email);
            formData.append('Subject', "Verification Code");
            formData.append('Body', verificationMessage);

            const response = await Instance.post('/Email/SendMail', formData);

            console.log(response.data)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Otp sent successfully',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false
            });

        } catch (error) {
            console.error('Error sending verification email:', error);
        }
    };


    const handleSubmitEmail = async () => {
        try {
            await validationSchema.validate({ email });
            const res = await Instance.get(`/User/LoginWithMail?email=${email}`);
            if (res.data) {
                generateOTP();
            }
        } catch (error) {
            setValidationError(error.message);
        }
    };

    const handleLogin = async () => {
        if (otp === generatedOTP.toString()) {
            try {
                const response = await Instance.get(`/User/LoginWithMail?email=${email}`);
                if (response.data) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Login successful',
                        text: 'You have successfully logged in',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                    localStorage.setItem("userLogin", true);
                    onUpdateUserLogin(true);
                    navigate("/dashboard");
                    localStorage.setItem("userData", JSON.stringify(response.data));
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Login failed',
                        text: 'An error occurred during login. Please try again later.',
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: 'An error occurred during login. Please try again later.',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Password Mismatch',
                text: 'The password provided does not match. Please try again.',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false
            });
        }
    };


    return (
        <div>
            <Header />
            <PageHeader title={"Log In"} />
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email *"
                                />
                                {validationError && <p className="error-message">{validationError}</p>}
                            </div>
                            {showGenerateOTP && (
                                <div className="Enter_otp">
                                    <h6>Enter OTP Here</h6>
                                    <OTPInput
                                        className="otp-input"
                                        value={otp}
                                        onChange={(otp) => setOTP(otp)}
                                        numInputs={4}
                                        renderInput={(props, index) => (
                                            <input
                                                {...props}
                                                key={index}
                                                style={{
                                                    width: "50px", // Adjust the width as needed
                                                    height: "50px", // Adjust the height as needed
                                                    fontSize: "18px", // Adjust the font size as needed
                                                    margin: "0 5px", // Adjust the margin as needed
                                                    textAlign: "center", // Center the text inside the input
                                                    borderRadius: "10px",
                                                    border: "1px solid gray",
                                                    backgroundColor: "#32125a"
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                            )}
                            {!showGenerateOTP && (
                                <div className="form-group">
                                    <button
                                        className="d-block lab-btn"
                                        type="button"
                                        onClick={handleSubmitEmail}
                                    >
                                        <span>Generate OTP</span>
                                    </button>
                                </div>
                            )}
                            {showGenerateOTP && (
                                <div className="form-group">
                                    <button
                                        className="d-block lab-btn"
                                        type="button"
                                        onClick={handleLogin}
                                    >
                                        <span>Login</span>
                                    </button>
                                </div>
                            )}
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-5">
                                Don’t Have any Account? <Link to="/signup"> Sign Up</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
};

export default EmailLogin;
