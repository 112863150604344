import React, { Component } from 'react';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import FilterSearchSection from '../component/section/filtersearch';
import WidgetLikeSection from '../component/section/widgetlike';
import WidgetGroupSection from '../component/section/widgetgroup';
import CommentSection from '../component/section/comments';
import CommentRespond from '../component/section/commentrespond';

class BlogSinglePage extends Component {
    render() {
        return (
            <div>
                <Header />
                <PageHeader title={'Blog Details'} />
                <section className="blog-section padding-tb pb-lg-110">
                    <div className="container">
                        <div className="main-blog">
                            <div className="row">
                                <div className="col-lg-8 col-12">
                                    <div className="blog-wrapper">
                                        <div className="post-item">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <img src="assets/images/blog/03.jpg" alt="blog" />
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <a href="#">Admin</a> March 24, 2021</span>
                                                    <h3>A good marriage is like a secret pact where each partner quietly believes they've won the jackpot.</h3>
                                                    <p>Marriage, they say, is the union of two souls, but what often goes unsaid is the subtle competition that exists within this sacred bond. It's a peculiar paradox - the belief that you've somehow lucked out while your partner feels the same way. This sentiment, though kept hidden behind smiles and gestures of love, is what truly defines a good marriage.

                                                    </p>
                                                    <blockquote>
                                                        <p>In many ways, marriage is portrayed as an act of selflessness, where partners prioritize the needs and happiness of the other above their own. Yet, beneath this facade lies a quiet acknowledgment - a feeling that in choosing each other, they've somehow come out on top.

                                                            Embracing Imperfections
                                                            One of the hallmarks of a successful marriage is the ability to see past flaws and imperfections. It's in this acceptance that partners begin to realize the true value of their union. Each quirk and idiosyncrasy becomes a badge of honor, a reminder of the uniqueness of their chosen companion.

                                                            The Beauty of Mutual Admiration
                                                            In a good marriage, admiration flows freely, each partner in awe of the other's strengths and qualities. Yet, amidst this mutual admiration lies a subtle undercurrent of disbelief - a feeling that they've somehow managed to land the better half of the deal.</p>
                                                    </blockquote>
                                                    <p>In many ways, marriage is portrayed as an act of selflessness, where partners prioritize the needs and happiness of the other above their own. Yet, beneath this facade lies a quiet acknowledgment - a feeling that in choosing each other, they've somehow come out on top.

                                                        Embracing Imperfections
                                                        One of the hallmarks of a successful marriage is the ability to see past flaws and imperfections. It's in this acceptance that partners begin to realize the true value of their union. Each quirk and idiosyncrasy becomes a badge of honor, a reminder of the uniqueness of their chosen companion.

                                                        The Beauty of Mutual Admiration
                                                        In a good marriage, admiration flows freely, each partner in awe of the other's strengths and qualities. Yet, amidst this mutual admiration lies a subtle undercurrent of disbelief - a feeling that they've somehow managed to land the better half of the deal.
                                                    </p>
                                                </div>
                                                <div className="tags-section">
                                                    <ul className="tags">
                                                        <li><span><i className="icofont-tags"></i></span></li>
                                                        <li><a href="#">Dantal</a></li>
                                                        <li><a href="#">Health Care</a></li>
                                                        <li><a href="#">Company</a></li>
                                                        <li><a href="#">Corporate</a></li>
                                                    </ul>
                                                    <ul className="social-link-list d-flex flex-wrap">
                                                        <li><a href="#" className="facebook"><i className="icofont-facebook"></i></a></li>
                                                        <li><a href="#" className="dribble"><i className="icofont-dribble"></i></a></li>
                                                        <li><a href="#" className="twitter"><i className="icofont-twitter"></i></a></li>
                                                        <li><a href="#" className="linkedin"><i className="icofont-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <CommentSection />
                                        <CommentRespond />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <aside className="mt-5 mt-lg-0">
                                        <FilterSearchSection />
                                        <WidgetLikeSection />
                                        <WidgetGroupSection />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default BlogSinglePage;