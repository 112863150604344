import React, { useEffect, useState } from "react";
import Instence from "../../Utils/api";

const FamilyDetailsEdit = ({ familyDetailsData }) => {
  console.log(familyDetailsData);

  const [family, setFamily] = useState(false);
  const [formData, setFormData] = useState({
    familyValue: "",
    familyType: "",
    familyStatus: "",
    fatherOccupation: "",
    motherOccupation: "",
    noBrothers: "",
    brotherMarried: "",
    noSisters: "",
    siterMarried: "",
    familyLocation: "",
    aboutMyFamily: "",
  });

  useEffect(() => {
    if (familyDetailsData && familyDetailsData.tblFamilyInfos.length > 0) {
      const familyData = familyDetailsData.tblFamilyInfos[0];
      setFormData({
        familyValue: familyData.familyValue || "",
        familyType: familyData.familyType || "",
        familyStatus: familyData.familyStatus || "",
        fatherOccupation: familyData.fatherOccupation || "",
        motherOccupation: familyData.motherOccupation || "",
        noBrothers: familyData.noBrothers || "",
        brotherMarried: familyData.brotherMarried || "",
        noSisters: familyData.noSisters || "",
        siterMarried: familyData.siterMarried || "",
        familyLocation: familyData.familyLocation || "",
        aboutMyFamily: familyData.aboutMyFamily || "",
      });
    }
  }, [familyDetailsData]);

  const handleFamilyCancel = () => {
    setFamily(false);
  };

  const handleFamilyToggleEdit = () => {
    setFamily(true);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleFamilyDetailsUpdate = async () => {
    try {
      const UserId = JSON.parse(localStorage.getItem("userData"));
      const response = await Instence.post(
        `User/UpdateFamilyInfo/${UserId.id}`,
        formData
      );
      console.log("Update successful:", response.data);
      setFamily(false);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div>
      <div className="row">
        {family ? (
          <div className="info-div">
            {/* Input fields */}
            <div className="first-div">
              <p>Family Details</p>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="familyValue">Family Value</label>
                <select
                  id="familyValue"
                  onChange={handleInputChange}
                  value={formData.familyValue}
                >
                  <option value="">Select</option>
                  <option value="Traditional">Traditional</option>
                  <option value="Modern">Modern</option>
                  <option value="Liberal">Liberal</option>
                  <option value="Conservative">Conservative</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="familyType">Family Type</label>
                <select
                  id="familyType"
                  onChange={handleInputChange}
                  value={formData.familyType}
                >
                  <option value="">Select</option>
                  <option value="Joint">Joint</option>
                  <option value="Nuclear">Nuclear</option>
                </select>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="familyStatus">Family Status</label>
                <select
                  id="familyStatus"
                  onChange={handleInputChange}
                  value={formData.familyStatus}
                >
                  <option value="">Select</option>
                  <option value="Rich">Rich</option>
                  <option value="Affluent">Affluent</option>
                  <option value="Middle Class">Middle Class</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="fatherOccupation">Father's Occupation</label>
                <input
                  type="text"
                  id="fatherOccupation"
                  placeholder="Enter father occupation"
                  onChange={handleInputChange}
                  value={formData.fatherOccupation}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="motherOccupation">Mother's Occupation</label>
                <input
                  type="text"
                  id="motherOccupation"
                  placeholder="Enter mother occupation"
                  onChange={handleInputChange}
                  value={formData.motherOccupation}
                />
              </div>
              <div className="input-group">
                <label htmlFor="noBrothers">Number of Brothers</label>
                <input
                  type="number"
                  id="noBrothers"
                  placeholder="Enter number of brothers"
                  onChange={handleInputChange}
                  value={formData.noBrothers}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="brotherMarried">Brothers Married</label>
                <input
                  type="number"
                  id="brotherMarried"
                  placeholder="Enter brothers married"
                  onChange={handleInputChange}
                  value={formData.brotherMarried}
                />
              </div>
              <div className="input-group">
                <label htmlFor="noSisters">Number of Sisters</label>
                <input
                  type="number"
                  id="noSisters"
                  placeholder="Enter number of sisters"
                  onChange={handleInputChange}
                  value={formData.noSisters}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="siterMarried">Sisters Married</label>
                <input
                  type="number"
                  id="siterMarried"
                  placeholder="Enter sisters married"
                  onChange={handleInputChange}
                  value={formData.siterMarried}
                />
              </div>
              <div className="input-group">
                <label htmlFor="familyLocation">Family Location</label>
                <input
                  type="text"
                  id="familyLocation"
                  placeholder="Enter family location"
                  onChange={handleInputChange}
                  value={formData.familyLocation}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label htmlFor="aboutMyFamily">About Family</label>
                <textarea
                  className="w-100"
                  id="aboutMyFamily"
                  placeholder="Enter about family"
                  onChange={handleInputChange}
                  value={formData.aboutMyFamily}
                ></textarea>
              </div>
            </div>
            <div className="profile-btn-div">
              <button className="cancel-btn" onClick={handleFamilyCancel}>
                Cancel
              </button>
              <button
                className="profile-btn"
                onClick={handleFamilyDetailsUpdate}
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 info-div">
            <div className="row">
              <div className="col-md-12">
                <div className="high">
                  <p>FAMILY DETAILS</p>
                  <button
                    className="profile-btn"
                    onClick={handleFamilyToggleEdit}
                  >
                    Edit
                  </button>
                </div>

                <div className="low">
                  <div className="left-div">
                    <p>
                      <span>Family Value :</span>
                      <span className="span-two"> {formData.familyValue}</span>
                    </p>
                    <p>
                      <span>Family Status :</span>
                      <span className="span-two">
                        {" "}
                        {formData.familyStatus}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Mother's Occupation :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.motherOccupation}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Brothers Married :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.brotherMarried}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Sisters Married :</span>{" "}
                      <span className="span-two"> {formData.siterMarried}</span>{" "}
                    </p>
                  </div>
                  <div className="right-div">
                    <p>
                      <span>Family Type :</span>{" "}
                      <span className="span-two"> {formData.familyType}</span>{" "}
                    </p>
                    <p>
                      <span> Father's Occupation :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.fatherOccupation}
                      </span>{" "}
                    </p>
                    <p>
                      <span>Number of Brothers :</span>{" "}
                      <span className="span-two"> {formData.noBrothers}</span>{" "}
                    </p>
                    <p>
                      <span>Number of Sisters : </span>{" "}
                      <span className="span-two"> {formData.noSisters}</span>{" "}
                    </p>
                    <p>
                      <span>Family Location :</span>{" "}
                      <span className="span-two">
                        {" "}
                        {formData.familyLocation}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div className="low">
                  <p>
                    <span>About family :</span>{" "}
                    <span className="span-two"> {formData.aboutMyFamily}</span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FamilyDetailsEdit;
