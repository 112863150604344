import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import Pagination from '../component/section/pagination';
import FilterSearchSection from '../component/section/filtersearch';
import WidgetLikeSection from '../component/section/widgetlike';
import WidgetGroupSection from '../component/section/widgetgroup';

class BlogPage extends Component {
    render() {
        return (
            <div>
                <Header />
                <PageHeader title={'Blog'} />
                <section className="blog-section padding-tb">
                    <div className="container">
                        <div className="main-blog">
                            <div className="row">
                                <div className="col-lg-8 col-12">
                                    <div className="blog-wrapper">
                                        <div className="post-item">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <Link to="/blog-single"><img src="assets/images/blog/01.jpg" alt="blog" /></Link>
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <a href="#">Admin</a> March 24, 2021</span>
                                                    <h3><Link to="/blog-single">A good marriage is one where each partner secretly suspects they got the better deal.</Link></h3>
                                                    <p>Marriage, they say, is the union of two souls, but what often goes unsaid is the subtle competition that exists within this sacred bond. It's a peculiar paradox - the belief that you've somehow lucked out while your partner feels the same way. This sentiment, though kept hidden behind smiles and gestures of love, is what truly defines a good marriage.</p>
                                                </div>
                                                <div className="blog-footer">
                                                    <Link to="/blog-single" className="viewall">Read More <i
                                                        className="icofont-double-right"></i></Link>
                                                    <div className="right">
                                                        <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> 12 <span
                                                            className="d-none d-sm-inline-block">Like</span> </a>
                                                        <a href="#" className="blog-comment"><i className="icofont-comment"></i> 24
                                                            <span className="d-none d-sm-inline-block">Comments</span> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-item post-slider">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <div className="blog-slider">
                                                        <Swiper
                                                            spaceBetween={30}
                                                            centeredSlides={true}
                                                            autoplay={{
                                                                delay: 5000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            navigation={{
                                                                prevEl: '.blog-slider-prev',
                                                                nextEl: '.blog-slider-next',
                                                            }}
                                                            loop={true}
                                                            modules={[Autoplay, Navigation]}
                                                            className="mySwiper"
                                                        >
                                                            <SwiperSlide>
                                                                <img src="assets/images/blog/03.jpg" alt="blog" />
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <img src="assets/images/blog/02.jpg" alt="blog" />
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <img src="assets/images/blog/01.jpg" alt="blog" />
                                                            </SwiperSlide>
                                                        </Swiper>
                                                        <div className="blog-slider-next">
                                                            <i className="icofont-play"></i>
                                                        </div>
                                                        <div className="blog-slider-prev">
                                                            <i className="icofont-play"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <Link to="#">Admin</Link> March 24, 2021</span>
                                                    <h3><Link to="/blog-single">A perfect marriage is just two imperfect people who refuse to give up on each other.</Link></h3>
                                                    <p>In a good marriage, admiration flows freely, each partner in awe of the other's strengths and qualities. Yet, amidst this mutual admiration lies a subtle undercurrent of disbelief - a feeling that they've somehow managed to land the better half of the deal.</p>
                                                </div>
                                                <div className="blog-footer">
                                                    <Link to="/blog-single" className="viewall">Read More <i
                                                        className="icofont-double-right"></i></Link>
                                                    <div className="right">
                                                        <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> 12 <span
                                                            className="d-none d-sm-inline-block">Like</span> </a>
                                                        <a href="#" className="blog-comment"><i className="icofont-comment"></i> 24
                                                            <span className="d-none d-sm-inline-block">Comments</span> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-item video-post">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <div className="embed-responsive">
                                                        <iframe src="https://www.youtube.com/embed/ViOZGtS8FG4"></iframe>
                                                    </div>
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <a href="#">Admin</a> March 24, 2021</span>
                                                    <h3><Link to="/blog-single">True love is finding someone whose flaws you can embrace and whose virtues you admire.</Link></h3>
                                                    <p>One of the hallmarks of a successful marriage is the ability to see past flaws and imperfections. It's in this acceptance that partners begin to realize the true value of their union. Each quirk and idiosyncrasy becomes a badge of honor, a reminder of the uniqueness of their chosen companion.</p>
                                                </div>
                                                <div className="blog-footer">
                                                    <Link to="/blog-single" className="viewall">Read More <i
                                                        className="icofont-double-right"></i></Link>
                                                    <div className="right">
                                                        <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> 12 <span
                                                            className="d-none d-sm-inline-block">Like</span> </a>
                                                        <a href="#" className="blog-comment"><i className="icofont-comment"></i> 24
                                                            <span className="d-none d-sm-inline-block">Comments</span> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-item code-post">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <div className="code-content">
                                                        <img src="assets/images/blog/icon/code.png" alt="blog" />
                                                        <p>In many ways, marriage is portrayed as an act of selflessness, where partners prioritize the needs and happiness of the other above their own. Yet, beneath this facade lies a quiet acknowledgment - a feeling that in choosing each other, they've somehow come out on top.</p>
                                                    </div>
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <a href="#">Admin</a> March 24, 2021</span>
                                                    <h3><Link to="/blog-single">The Beauty of Mutual Admiration</Link></h3>
                                                    <p>In a good marriage, admiration flows freely, each partner in awe of the other's strengths and qualities. Yet, amidst this mutual admiration lies a subtle undercurrent of disbelief - a feeling that they've somehow managed to land the better half of the deal.</p>
                                                </div>
                                                <div className="blog-footer">
                                                    <Link to="/blog-single" className="viewall">Read More <i
                                                        className="icofont-double-right"></i></Link>
                                                    <div className="right">
                                                        <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> 12 <span
                                                            className="d-none d-sm-inline-block">Like</span> </a>
                                                        <a href="#" className="blog-comment"><i className="icofont-comment"></i> 24
                                                            <span className="d-none d-sm-inline-block">Comments</span> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <aside className="mt-5 mt-lg-0">
                                        <FilterSearchSection />
                                        <WidgetLikeSection />
                                        {/* <WidgetGroupSection /> */}
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default BlogPage;