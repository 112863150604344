import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import * as Yup from 'yup';
import "../external.css"

const title = "Register Now";
const subtitle = "Register With Social Media";



const SignInPage = () => {

    const [fullName, setFullName] = useState("");
    const [gender, setGender] = useState("");
    const [onBehalf, setOnBehalf] = useState("");
    const [phone, setPhone] = useState(false);
    const [errors, setErrors] = useState({
        fullName: "",
        gender: "",
        onBehalf: "",
        phone: "",
    });

    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        fullName: Yup.string()
            .required("Full name is required")
            .min(2, "Enter your full name"),
        gender: Yup.string().required("Gender is required"),
        phone: Yup.string()
            .matches(/^[0-9]+$/, "Phone number is not valid")
            .required("A phone number is required"),
        onBehalf: Yup.string().required("This field is required"),
        termsAccepted: Yup.boolean().oneOf(
            [true],
            "You must accept the terms and conditions"
        ),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await formSchema.validate(
                { fullName, gender, phone, onBehalf },
                { abortEarly: false }
            );

            const formData = {
                fullName,
                gender,
                phone,
                onBehalf,
            }
            navigate("/detailsform")
            localStorage.setItem('formData', JSON.stringify(formData));
        } catch (error) {
            if (error.inner) {
                const validationErrors = {};
                error.inner.forEach((e) => {
                    validationErrors[e.path] = e.message;
                });
                setErrors(validationErrors);
            } else {
                console.error("Validation error:", error);
            }
        }
    };


    return (
        <div>
            <Header />
            <PageHeader title={'Sign Up'} />
            <div className="login-section padding-tb">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 offset-3">
                            <div className="banner-content">
                                <div className="intro-form">
                                    <div className="intro-form-inner">
                                        <h3 className='p-3' style={{ fontSize: "27px", fontWeight: "bolder", textAlign: "center" }}>
                                            {title}
                                            <p>{subtitle}</p>
                                        </h3>

                                        <form className="banner-form py-2" onSubmit={handleSubmit}>
                                            <div className="gender">
                                                <label className="left">On Behalf</label>
                                                <div className="custom-select right">
                                                    <select
                                                        value={onBehalf}
                                                        onChange={(e) => setOnBehalf(e.target.value)}
                                                        name="gender"
                                                        id="gender"
                                                        className=""
                                                    >
                                                        <option value="0">Select Profile</option>
                                                        <option value="Myself">Myself</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Daughter/Son">Daughter/Son</option>
                                                    </select>
                                                    {errors.onBehalf && (
                                                        <div className="error-message">
                                                            {errors.onBehalf}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="gender">
                                                <label className="left">Full Name</label>
                                                <div className="right">
                                                    <input
                                                        className="w-100"
                                                        type="text"
                                                        id="city"
                                                        value={fullName}
                                                        onChange={(e) => setFullName(e.target.value)}
                                                        placeholder="Enter Full Name.."
                                                    />
                                                    {errors.fullName && (
                                                        <div className="error-message">
                                                            {errors.fullName}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="gender">
                                                <label className="left">I am a </label>
                                                <div className="custom-select right">
                                                    <select
                                                        value={gender}
                                                        onChange={(e) => setGender(e.target.value)}
                                                        name="gender"
                                                        id="gender"
                                                        className=""
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                    {errors.gender && (
                                                        <div className="error-message">{errors.gender}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="gender">
                                                <label className="left">Phone </label>
                                                <div className="right">
                                                    <input
                                                        className="w-100"
                                                        type="number"
                                                        id="city"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        placeholder="Enter Phone Number.."
                                                    />
                                                    {errors.phone && (
                                                        <div className="error-message">{errors.phone}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit">Register Free</button>
                                            <div className="chk">
                                                <span className="chk2">
                                                    *By signing up you agree to our terms and conditions.
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
};

export default SignInPage;
