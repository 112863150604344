import React, { Component, useEffect, useState } from "react";
import Header from "../component/layout/header";
import FooterSection from "../component/layout/footer";
import BannerSection from "../component/section/banner";
import MemberSection from "../component/section/member";
import AboutSection from "../component/section/about";
import StorySection from "../component/section/storysection";
import WorkSection from "../component/section/work";
import MemberTwoSection from "../component/section/memberTwo";
import GroupSection from "../component/section/group";
import ClientSection from "../component/section/clints";
import AppsSection from "../component/section/apps";
import { useNavigate } from "react-router";

const HomePages = () => {

  const navigate = useNavigate()

  const userLoggedIn = localStorage.getItem("userLogin");
  console.log(userLoggedIn)
  if (userLoggedIn) {
    navigate("/dashboard")
  }

  useEffect(() => {
    // Check if user is logged i
    if (userLoggedIn) {
      navigate("/dashboard")
    }
  }, [localStorage.getItem("userLogin")]);


  return (
    <div>
      <Header />
      <BannerSection />
      <MemberSection />
      <AboutSection />
      <WorkSection />
      {/* <StorySection /> */}
      <MemberTwoSection />
      {/* <GroupSection /> */}
      <ClientSection />
      <AppsSection />
      <FooterSection />
    </div>
  );
}


export default HomePages;
