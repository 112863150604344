import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FooterSection from "../component/layout/footer";
import Instance from "../Utils/api.js";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const title = "Login";
const subtitle = "Login With Social Media";

const LoginPage = ({ onUpdateUserLogin }) => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const [saveUserData, setSaveUserData] = useState("");

  const generateOTP = () => {
    const otp = Math.floor(1000 + Math.random() * 9000);
    console.log(otp);
    return otp;
  };

  generateOTP();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await Instance.get(
        `/User/login?username=${encodeURIComponent(
          userName
        )}&password=${encodeURIComponent(userPass)}`
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Login successful",
          text: "You have successfully logged in",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        localStorage.setItem("userLogin", true);
        onUpdateUserLogin(true);
        navigate("/dashboard");
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: "Login failed",
        text: error.response.data,
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <Header />
      <PageHeader title={"Log In"} />
      <div className="login-section padding-tb">
        <div className=" container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="item01"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="User Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  id="item02"
                  value={userPass}
                  onChange={(e) => {
                    setUserPass(e.target.value);
                  }}
                  placeholder="Password *"
                />
              </div>
              <div className="form-group">
                {/* <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <a href="#">Forget Password?</a>
                </div> */}
              </div>
              <div className="form-group">
                <button
                  className="d-block lab-btn"
                  type="submit"
                  onClick={handleLogin}
                >
                  <span>Login Now</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-5">
                <Link to="/email">Login With OTP</Link>
              </span>
              <span className="d-block cate pt-5">
                Don’t Have any Account? <Link to="/signup"> Sign Up</Link>
              </span>
              {/* <span className="or">
                <span>or</span>
              </span>
              <h5 className="subtitle">{subtitle}</h5> */}
              {/* <ul className="social-media social-color lab-ul d-flex justify-content-center">
                                <li>
                                    <a href="#" className="facebook"><i className="icofont-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="twitter"><i className="icofont-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="linkedin"><i className="icofont-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="instagram"><i className="icofont-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="pinterest"><i className="icofont-pinterest"></i></a>
                                </li>
                            </ul> */}
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default LoginPage;
