import React, { useState } from "react";
import "./CSS/BasicDetails.css";
import { basicDetailsSchema } from "../Utils/YupSchema";

const BasicDetails = ({ nextStep }) => {
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState(0);
  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const formDataString = localStorage.getItem("formData");
  const formDataaa = JSON.parse(formDataString);
  console.log(formDataaa);

  const handleDateChange = (e) => {
    e.preventDefault();
    const dob = e.target.value;
    setDateOfBirth(dob);
    setAge(calculateAge(dob));
  };

  const handleLanguageChange = (event) => {
    event.preventDefault();
    setLanguage(event.target.value);
  };

  const handleClicknext = (e) => {
    e.preventDefault();
    console.log("Button clicked");
    const formData = {
      dateOfBirth,
      age,
      language,
      email,
      password,
    };
    console.log(formData);
    basicDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const mergedFormData = {
          ...formDataaa,
          ...formData,
        };

        console.log(mergedFormData);

        localStorage.setItem("formData", JSON.stringify(mergedFormData));
        console.log(mergedFormData); // Check if merged form data is correct
        nextStep();
      })
      .catch((err) => {
        console.log("Validation error object:", err);
        // Handle the error
      });
  };

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <div className="basic-details-container">
      <div className="container-fluid">
        <div className="row ">
          <div className="container">
            <div className="row basic-screen">
              <div className="col-md-4 loving-companion">
                <img src="../assets/images/banner/couple-logo.png" />
              </div>
              <div className="col-md-8 bg-white right-form">
                <div className="heading-details">
                  <p style={{ fontSize: "25px" }} className="text-black">
                    SHARE YOUR BASIC DETAILS
                  </p>
                </div>
                <div className="basic-input-form">
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="dob">
                        Date of Birth <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        id="dob"
                        required
                        value={dateOfBirth}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="phoneNumber">
                        Age <span className="mandatory">*</span>
                      </label>
                      <input
                        type="age"
                        id="Age"
                        placeholder="Enter your phone number"
                        value={age}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="email">
                        Email<span className="mandatory">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email"
                        required
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="password">
                        Password<span className="mandatory">*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="numberOfChildren">
                        Mother Tongue<span className="mandatory">*</span>
                      </label>
                      <select
                        id="Lanngue"
                        value={language}
                        onChange={handleLanguageChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Telugu">Telugu</option>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Kannada">Kannada</option>
                        <option value="Malayalam">Malayalam</option>
                        <option value="urdu">Urdu</option>
                      </select>
                    </div>
                  </div>
                  <div className="profile-btn-div">
                    <button onClick={handleClicknext} className="profile-btn">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BasicDetails;
