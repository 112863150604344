import React from "react";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FooterSection from "../component/layout/footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <PageHeader title={"Privacy Policy"} />
      <>
        <section className="mb-4 privacy-policy-container">
          <div className="container">
            <div className="p-4  rounded shadow-sm overflow-hidden mw-100 text-left">
              <h1>Privacy Policy</h1>
              <h2>
                <p>Updated at 2022-11-30 samsmatrimony.com.</p>
                <p>
                  ("we," "our," or "us") is committed to protecting your
                  privacy. This Privacy Policy explains how your personal
                  information is collected, used, and disclosed by
                </p>

                <p>samsmatrimony.com.</p>

                <div>
                  <p style={{ fontSize: "16px" }}>
                    This Privacy Policy applies to our website,&nbsp;
                    <a style={{ fontSize: "16px" }}>
                      https://samsmatrimony.com/
                    </a>
                    , and its associated subdomains (collectively, our
                    "Service"&nbsp; alongside our application,
                    samsmatrimony.com.
                  </p>
                </div>

                <p>
                  By accessing or using our Service, you signify that you have
                  read, understood, and agree to our collection, storage, use,
                  and disclosure of your personal information as described in
                  this Privacy Policy and our Terms of Service.
                </p>
              </h2>
              <h3>Definitions and key terms</h3>
              <h2>
                <p>
                  <p>For this Privacy Policy:</p>
                </p>
                <ul>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Cookie:</p>
                      &nbsp;small amount of data generated by a website and
                      saved by your web browser. It is used to identify your
                      browser, provide analytics, remember information about you
                      such as your language preference or login information.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Company:</p>
                      &nbsp;when this policy mentions “Company,” “we,” “us,” or
                      “our,” it refers to &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      ,&nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        India
                      </a>
                      &nbsp;that is responsible for your information under this
                      Privacy Policy.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Country:</p>
                      &nbsp;where &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;or the owners/founders of &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;are based, in this case is &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        India
                      </a>
                      .<br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Customer:</p>
                      &nbsp;refers to the company, organization or person that
                      signs up to use the &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;Service to manage the relationships with your
                      consumers or service users.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Device:</p>
                      &nbsp;any internet connected device such as a phone,
                      tablet, computer or any other device that can be used to
                      visit &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;and use the services.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>IP address:</p>
                      &nbsp;Every device connected to the Internet is assigned a
                      number known as an Internet protocol (IP) address. These
                      numbers are usually assigned in geographic blocks. An IP
                      address can often be used to identify the location from
                      which a device is connecting to the Internet.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Personnel:</p>
                      &nbsp;refers to those individuals who are employed by
                      &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;or are under contract to perform a service on behalf
                      of one of the parties.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Personal Data:</p>
                      &nbsp;any information that directly, indirectly, or in
                      connection with other information — including a personal
                      identification number — allows for the identification or
                      identifiability of a natural person.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Service:</p>
                      &nbsp;refers to the service provided by &nbsp;
                      <a
                        style={{
                          textDecorationLine: "underline",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "linear",
                          boxShadow: "none",
                        }}
                      >
                        samsmatrimony.com
                      </a>
                      &nbsp;as described in the relative terms (if available)
                      and on this platform.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Third-party service:</p>
                      &nbsp;refers to advertisers, contest sponsors, promotional
                      and marketing partners, and others who provide our content
                      or whose products or services we think may interest you.
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>Website:</p>
                      &nbsp;
                      <a>samsmatrimony.com &nbsp;</a>
                      &nbsp;site, which can be accessed via this URL:&nbsp;
                      <a>https://samsmatrimony.com/.</a>
                      <br />
                    </p>
                  </li>
                  <li>
                    <p>
                      <p style={{ fontWeight: 700 }}>You:</p>
                      &nbsp;a person or entity that is registered with &nbsp;
                      <a>samsmatrimony.com</a>
                      &nbsp;to use the Services.
                      <br />
                    </p>
                  </li>
                </ul>
              </h2>
              <h3>Information automatically collected</h3>
              <h2>
                <div>
                  <p>
                    <p>
                      There is some information like your Internet Protocol (IP)
                      address and/or browser and device characteristics — is
                      collected automatically when you visit our platform. This
                      information may be used to connect your computer to the
                      Internet. Other information collected automatically could
                      be a login, e-mail address, password, computer and
                      connection information such as browser plug-in types and
                      versions and time zone setting, operating systems and
                      platforms, purchase history, (we sometimes aggregate with
                      similar information from other Users), the full Uniform
                      Resource Locator (URL) clickstream to, through and from
                      our Website that may include date and time; cookie number;
                      parts of the site you viewed or searched for; and the
                      phone number you used to call our Customer Services. We
                      may also use browser data such as cookies, Flash cookies
                      (also known as Flash Local Shared Objects) or similar data
                      on certain parts of our Website for fraud prevention and
                      other purposes. During your visits, we may use software
                      tools such as JavaScript to measure and collect session
                      information including page response times, download
                      errors, length of visits to certain pages, page
                      interaction information (such as scrolling, clicks, and
                      mouse-overs), and methods used to browse away from the
                      page. We may also collect technical information to help us
                      identify your device for fraud prevention and diagnostic
                      purposes.
                      <br />
                      <br />
                      We automatically collect certain information when you
                      visit, use or navigate the platform. This information does
                      not reveal your specific identity (like your name or
                      contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about who and when you use our and other
                      technical information. This information is primarily
                      needed to maintain the security and operation of our , and
                      for our internal analytics and reporting purposes.
                    </p>
                  </p>
                </div>
              </h2>
              <h3>How Do We Use The Information We Collect?</h3>
              <h2>
                <p>
                  <p>
                    Any of the information we collect from you may be used in
                    one of the following ways:
                  </p>
                </p>
                <ul>
                  <li>
                    <p className="s3">
                      To personalize your experience (your information helps us
                      to better respond to your individual needs)
                    </p>
                  </li>
                  <li>
                    <p className="s3">
                      To improve our service (we continually strive to improve
                      our service offerings based on the information and
                      feedback we receive from you)
                    </p>
                  </li>
                  <li>
                    <p className="s3">
                      To improve customer service (your information helps us to
                      more effectively respond to your customer service requests
                      and support needs)
                    </p>
                  </li>
                  <li>
                    <p className="s3">To process transactions</p>
                  </li>
                  <li>
                    <p className="s3">
                      To administer a contest, promotion, survey or other site
                      feature
                    </p>
                  </li>
                  <li>
                    <p className="s3">To send periodic emails</p>
                  </li>
                </ul>
              </h2>
              <h3>When do we use customer information from third parties?</h3>
              <h2>
                <p>
                  <p>
                    We receive some information from the third parties when you
                    contact us. For example, when you submit your email address
                    to us to show interest in becoming our customer, we receive
                    information from a third party that provides automated fraud
                    detection services to us. We also occasionally collect
                    information that is made publicly available on social media
                    websites. You can control how much of your information
                    social media websites make public by visiting these websites
                    and changing your privacy settings.
                  </p>
                </p>
              </h2>
              <h4>
                Do we share the information we collect with third parties?
              </h4>
              <h2>
                <p>
                  <p>
                    We may share the information that we collect, both personal
                    and non-personal, with third parties such as advertisers,
                    contest sponsors, promotional and marketing partners, and
                    others who provide our content or whose products or services
                    we think may interest you. We may also share it with our
                    current and future affiliated companies and business
                    partners, and if we are involved in a merger, asset sale or
                    other business reorganization, we may also share or transfer
                    your personal and non-personal information to our
                    successors-in-interest.
                    <br />
                    <br />
                    We may engage trusted third party service providers to
                    perform functions and provide services to us, such as
                    hosting and maintaining our servers and our service,
                    database storage and management, e-mail management, storage
                    marketing, credit card processing, customer service and
                    fulfilling orders for products and services you may purchase
                    through our platform. We will likely share your personal
                    information, and possibly some non-personal information,
                    with these third parties to enable them to perform these
                    services for us and for you
                    <br />
                    <br />
                    We may share portions of our log file data, including IP
                    addresses, for analytics purposes with third parties such as
                    web analytics partners, application developers, and ad
                    networks. If your IP address is shared, it may be used to
                    estimate general location and other technographics such as
                    connection speed, whether you have visited the service in a
                    shared location, and type of device used to visit the
                    service. They may aggregate information about our
                    advertising and what you see on the service and then provide
                    auditing, research and reporting for us and our advertisers.
                    <br />
                    <br />
                    We may also disclose personal and non-personal information
                    about you to government or law enforcement officials or
                    private parties as we, in our sole discretion, believe
                    necessary or appropriate in order to respond to claims,
                    legal process (including subpoenas), to protect our rights
                    and interests or those of a third party, the safety of the
                    public or any person, to prevent or stop any illegal,
                    unethical, or legally actionable activity, or to otherwise
                    comply with applicable court orders, laws, rules and
                    regulations.
                  </p>
                </p>
              </h2>
              <h3>
                Where and when is information collected from customers and end
                users?
              </h3>
              <h2>
                <p>
                  <p>
                    We will collect personal information that you submit to us.
                    We may also receive personal information about you from
                    third parties as described above.
                  </p>
                </p>
              </h2>
              <h3>Could my information be transferred to other countries?</h3>
              <h2>
                <p>
                  <p>
                    We are incorporated in India. Information collected via our
                    website, through direct interactions with you, or from use
                    of our help services may be transferred from time to time to
                    our offices or personnel, or to third parties, located
                    throughout the world, and may be viewed and hosted anywhere
                    in the world, including countries that may not have laws of
                    general applicability regulating the use and transfer of
                    such data. To the fullest extent allowed by applicable law,
                    by using any of the above, you voluntarily consent to the
                    trans- border transfer and hosting of such information.
                  </p>
                </p>
              </h2>
              <h3>Is the information collected through our service secure?</h3>
              <h2>
                <p>
                  <p>
                    We take precautions to protect the security of your
                    information. We have physical, electronic, and managerial
                    procedures to help safeguard, prevent unauthorized access,
                    maintain data security, and correctly use your information.
                    However, neither people nor security systems are foolproof,
                    including encryption systems. In addition, people can commit
                    intentional crimes, make mistakes or fail to follow
                    policies. Therefore, while we use reasonable efforts to
                    protect your personal information, we cannot guarantee its
                    absolute security. If applicable law imposes any
                    non-disclaimable duty to protect your personal information,
                    you agree that intentional misconduct will be the standards
                    used to measure our compliance with that duty.
                  </p>
                </p>
              </h2>
              <h3>Can I update or correct my information?</h3>
              <h2>
                <p>
                  <p>
                    The rights you have to request updates or corrections to the
                    information we collect depend on your relationship with us.
                    Personnel may update or correct their information as
                    detailed in our internal company employment policies.
                    Customers have the right to request the restriction of
                    certain uses and disclosures of personally identifiable
                    information as follows. You can contact us in order to (1)
                    update or correct your personally identifiable information,
                    (2) change your preferences with respect to communications
                    and other information you receive from us, or (3) delete the
                    personally identifiable information maintained about you on
                    our systems (subject to the following paragraph), by
                    cancelling your account. Such updates, corrections, changes
                    and deletions will have no effect on other information that
                    we maintain, or information that we have provided to third
                    parties in accordance with this Privacy Policy prior to such
                    update, correction, change or deletion. To protect your
                    privacy and security, we may take reasonable steps (such as
                    requesting a unique password) to verify your identity before
                    granting you profile access or making corrections. You are
                    responsible for maintaining the secrecy of your unique
                    password and account information at all times. You should be
                    aware that it is not technologically possible to remove each
                    and every record of the information you have provided to us
                    from our system. The need to back up our systems to protect
                    information from inadvertent loss means that a copy of your
                    information may exist in a non-erasable form that will be
                    difficult or impossible for us to locate. Promptly after
                    receiving your request, all personal information stored in
                    databases we actively use, and other readily searchable
                    media will be updated, corrected, changed or deleted, as
                    appropriate, as soon as and to the extent reasonably and
                    technically practicable. If you are an end user and wish to
                    update, delete, or receive any information we have about
                    you, you may do so by contacting the organization of which
                    you are a customer.
                  </p>
                </p>
              </h2>
              <h3>Personnel</h3>
              <h2>
                <p>
                  <p>
                    If you are one of our workers or applicants, we collect
                    information you voluntarily provide to us. We use the
                    information collected for Human Resources purposes in order
                    to administer benefits to workers and screen applicants. You
                    may contact us in order to (1) update or correct your
                    information, (2) change your preferences with respect to
                    communications and other information you receive from us, or
                    (3) receive a record of the information we have relating to
                    you. Such updates, corrections, changes and deletions will
                    have no effect on other information that we maintain, or
                    information that we have provided to third parties in
                    accordance with this Privacy Policy prior to such update,
                    correction, change or deletion.
                  </p>
                </p>
              </h2>
              <h3>Sale of Business</h3>
              <h2>
                <p>
                  <p>
                    We reserve the right to transfer information to a third
                    party in the event of a sale, merger or other transfer of
                    all or substantially all of the assets of us or any of its
                    Corporate Affiliates (as defined herein), or that portion of
                    us or any of its Corporate Affiliates to which the Service
                    relates, or in the event that we discontinue our business or
                    file a petition or have filed against us a petition in
                    bankruptcy, reorganization or similar proceeding, provided
                    that the third party agrees to adhere to the terms of this
                    Privacy Policy.
                  </p>
                </p>
              </h2>
              <h3>Affiliates</h3>
              <h2>
                <p>
                  <p>
                    We may disclose information (including personal information)
                    about you to our Corporate Affiliates. For purposes of this
                    Privacy Policy, "Corporate Affiliate" means any person or
                    entity which directly or indirectly controls, is controlled
                    by or is under common control with us, whether by ownership
                    or otherwise. Any information relating to you that we
                    provide to our Corporate Affiliates will be treated by those
                    Corporate Affiliates in accordance with the terms of this
                    Privacy Policy.
                  </p>
                </p>
              </h2>
              <h3>How Long Do We Keep Your Information?</h3>
              <h2>
                <p>
                  <p>
                    We keep your information only so long as we need it to
                    provide service to you and fulfill the purposes described in
                    this policy. This is also the case for anyone that we share
                    your information with and who carries out services on our
                    behalf. When we no longer need to use your information and
                    there is no need for us to keep it to comply with our legal
                    or regulatory obligations, we'll either remove it from our
                    systems or depersonalize it so that we can't identify you.
                  </p>
                </p>
              </h2>
              <h3>How Do We Protect Your Information?</h3>
              <h2>
                <p>
                  <p>
                    We implement a variety of security measures to maintain the
                    safety of your personal information when you place an order
                    or enter, submit, or access your personal information. We
                    offer the use of a secure server. All supplied
                    sensitive/credit information is transmitted via Secure
                    Socket Layer (SSL) technology and then encrypted into our
                    Payment gateway providers database only to be accessible by
                    those authorized with special access rights to such systems,
                    and are required to keep the information confidential. After
                    a transaction, your private information (credit cards,
                    social security numbers, financials, etc.) is never kept on
                    file. We cannot, however, ensure or warrant the absolute
                    security of any information you transmit to us or guarantee
                    that your information on the Service may not be accessed,
                    disclosed, altered, or destroyed by a breach of any of our
                    physical, technical, or managerial safeguards.
                  </p>
                </p>
              </h2>
              <h3>Governing Law</h3>
              <h2>
                <p>
                  <p>
                    The laws of India, excluding its conflicts of law rules,
                    shall govern this Agreement and your use of our service.
                    Your use of our service may also be subject to other local,
                    state, national, or international laws.
                  </p>
                </p>
              </h2>
              <h3>Your Consent</h3>
              <h2>
                <p>
                  <p>
                    By using our service, registering an account, or making a
                    purchase, you consent to this Privacy Policy.
                  </p>
                </p>
              </h2>
              <h3>Links to Other Websites</h3>
              <h2>
                <p>
                  <p>
                    This Privacy Policy applies only to the Services. The
                    Services may contain links to other websites not operated or
                    controlled by samsmatrimony.com. We are not responsible for
                    the content, accuracy or opinions expressed in such
                    websites, and such websites are not investigated, monitored
                    or checked for accuracy or completeness by us. Please
                    remember that when you use a link to go from the Services to
                    another website, our Privacy Policy is no longer in effect.
                    Your browsing and interaction on any other website,
                    including those that have a link on our platform, is subject
                    to that website’s own rules and policies. Such third parties
                    may use their own cookies or other methods to collect
                    information about you.
                  </p>
                </p>
              </h2>
              <h3>Cookies</h3>
              <h2>
                <p>
                  <p>
                    We use "Cookies" to identify the areas of our website that
                    you have visited. A Cookie is a small piece of data stored
                    on your computer or mobile device by your web browser. We
                    use Cookies to personalize the Content that you see on our
                    website. Most web browsers can be set to disable the use of
                    Cookies. However, if you disable Cookies, you may not be
                    able to access functionality on our website correctly or at
                    all. We never place Personally Identifiable Information in
                    Cookies.
                  </p>
                </p>
              </h2>
              <h3>Payment Details</h3>
              <h2>
                <p>
                  <p>
                    In respect to any credit card or other payment processing
                    details you have provided us, we commit that this
                    confidential information will be stored in the most secure
                    manner possible.
                  </p>
                </p>
              </h2>
              <h3>Kids' Privacy</h3>
              <h2>
                <p>
                  <p>
                    We do not address anyone under the age of 13. We do not
                    knowingly collect personally identifiable information from
                    anyone under the age of 13. If You are a parent or guardian
                    and You are aware that Your child has provided Us with
                    Personal Data, please contact Us. If We become aware that We
                    have collected Personal Data from anyone under the age of 13
                    without verification of parental consent, We take steps to
                    remove that information from our servers.
                  </p>
                </p>
              </h2>
              <h3>Changes To Our Privacy Policy</h3>
              <h2>
                <p>
                  <p>
                    If we decide to change our privacy policy, we will post
                    those changes on this page, and/or update the Privacy Policy
                    modification date below.
                  </p>
                </p>
              </h2>
              <h3>Third-Party Services</h3>
              <h2>
                <p>
                  <p>
                    We may display, include or make available third-party
                    content (including data, information, applications and other
                    products services) or provide links to third-party websites
                    or services ("Third- Party Services").
                  </p>
                </p>
                <p>
                  <p>
                    You acknowledge and agree that we shall not be responsible
                    for any Third-Party Services, including their accuracy,
                    completeness, timeliness, validity, copyright compliance,
                    legality, decency, quality or any other aspect thereof. We
                    do not assume and shall not have any liability or
                    responsibility to you or any other person or entity for any
                    Third-Party Services.
                  </p>
                </p>
                <p>
                  <p>
                    Third-Party Services and links thereto are provided solely
                    as a convenience to you and you access and use them entirely
                    at your own risk and subject to such third parties' terms
                    and conditions.
                  </p>
                </p>
              </h2>
              <h3>Facebook Pixel</h3>
              <h2>
                <p>
                  <p>
                    Facebook pixel is an analytics tool that allows you to
                    measure the effectiveness of your advertising by
                    understanding the actions people take on your website. You
                    can use the pixel to: Make sure your ads are shown to the
                    right people. Facebook pixel may collect information from
                    your device when you use the service. Facebook pixel
                    collects information that is held in accordance with its
                    Privacy Policy.&nbsp;
                  </p>
                  <a
                    className="sc-bJHhxl kkRCtf"
                    href="https://termify.io/privacy-policy/1669799761"
                  >
                    samsmatrimony.com
                  </a>
                </p>
              </h2>
            </div>
          </div>
        </section>
      </>
      <FooterSection />
    </>
  );
};

export default PrivacyPolicy;
