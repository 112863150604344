import React, { useState } from "react";
import AllMatches from "./TabComponents/AllMatches";
import ViewedYou from "./TabComponents/ViewedYou";
import ViewedByYou from "./TabComponents/ViewedByYou";
import Newly_joined from "./TabComponents/Newly_joined";
import ShortlistedYou from "./TabComponents/ShortlistedYou";
import ShortlistedByYou from "./TabComponents/ShortlistedByYou";
import {
  IoHomeOutline,
  IoMailOutline,
  IoNotificationsOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { GiLovers } from "react-icons/gi";
import { CiCircleList } from "react-icons/ci";
import { Link } from "react-router-dom";

const Matches = () => {
  const [activeTab, setActiveTab] = useState("All Matches");

  // ? matches Tabs
  const MatchestabsData = [
    { name: "All Matches", component: <AllMatches />, icon: <IoHomeOutline /> },
    {
      name: "Newly Joined",
      component: <Newly_joined />,
      icon: <IoMailOutline />,
    },
    { name: "Viewed By You", component: <ViewedYou />, icon: <GiLovers /> },
    {
      name: "Viewed You",
      component: <ViewedByYou />,
      icon: <IoNotificationsOutline />,
    },
    {
      name: "ShortListed You",
      component: <ShortlistedYou />,
      icon: <CiCircleList />,
    },
    {
      name: "ShortListed By You",
      component: <ShortlistedByYou />,
      icon: <IoSearchOutline />,
    },
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="matches_sec">
        <div className="dash-matches-tabs mobile-dash-matches-tabs">
          {MatchestabsData.map((tab) => (
            <Link
              key={tab.name}
              onClick={() => handleTabClick(tab.name)}
              className={activeTab === tab.name ? "active" : ""}
            >
              {tab.icon} {tab.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="dashboard-content-bar">
            {MatchestabsData.find((tab) => tab.name === activeTab)?.component}
          </div>
        </div>
      </div>
    </>
  );
};

export default Matches;
