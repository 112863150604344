import React, { useEffect, useState } from "react";
import Instence from "../Utils/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [phone, setPhone] = useState("");
  //   console.log(UserData.id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Instence.get(`User/${UserData.id}`);
        setPhone(response.data.phone);
        // console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  //   console.log(phone);
  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    if (name === "confirm_password") {
      setPasswordMatch(formData.password === value);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await Instence.post("/User/ChangePassword", {
        id: UserData.id,
        oldPassword: formData.old_password,
        newPassword: formData.password,
      });

      if (resp.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Password updated successfully',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Failed to update password',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred while updating password',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
      console.error("Error updating password:", error);
    }
  };

  const handleUpdate = async () => {
    console.log(phone);
    try {
      const response = await Instence.post(`User/UpdatePhone`, {
        id: UserData.id,
        phone: phone,
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <div className="aiz-user-panel overflow-hidden">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0 text-black h6">Change Password</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-md-3 col-form-label">
                  Old Password<span className="text-danger">*</span>
                </label>
                <div className="col-md-9 mb-3">
                  <input
                    type="password"
                    name="old_password"
                    id="old_password"
                    className="form-control"
                    placeholder="Old Password"
                    value={formData.old_password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-3 col-form-label">
                  Password<span className="text-danger">*</span>
                </label>
                <div
                  className="col-md-9 input-group"
                  style={{ position: "relative" }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="new_password"
                    className={`form-control ${!passwordMatch && "is-invalid"}`}
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <div
                    className="input-group-append"
                    style={{ position: "absolute", right: "20px" }}
                  >
                    <button
                      className=""
                      type="button"
                      onClick={handleTogglePassword}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      {showPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          style={{ color: "red" }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye"
                          style={{ color: "purple" }}
                        ></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-3 col-form-label">
                  Confirm Password<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className={`form-control ${!passwordMatch && "is-invalid"}`}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    required
                  />
                  {!passwordMatch && (
                    <div className="invalid-feedback">
                      Passwords do not match
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group mt-5 row text-right">
                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn-Interest"
                    id="passSaveBtn"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="aiz-user-panel overflow-hidden py-4">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0 text-black h6">Update Contact Details</h5>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-md-3 col-form-label">Phone Number</label>
              <div className="col-md-9 mb-3">
                <input
                  type="text"
                  name="old_password"
                  id="old_password"
                  className="form-control"
                  placeholder="Update Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group row text-right">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn-Interest"
                  id="passSaveBtn"
                  onClick={handleUpdate}
                >
                  Update Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
