import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation"; // Import Swiper navigation styles
import SwiperCore, { Navigation, A11y } from "swiper";
import "./SwiperItem.css";

import userImg from "../assets/images/blank-profile.png";
import Instence from "../Utils/api";
import { Link } from "react-router-dom";

// Install Swiper modules
SwiperCore.use([Navigation, A11y]);

const NewMatchesItem = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  // ! local storage data for all matches

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userDatagender =
    userData.tblBasicInfos[0].gender === "male" ? "female" : "male";

  // ! data fetching from api using axios

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await Instence.get(`/User?gender=${userDatagender}`);

        if (response.status === 200) {
          // Assuming response.data is an array of profiles
          const sortedProfiles = response.data.sort((a, b) => {
            // Assuming there's a property named 'joinDate' in each profile
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          // Set only the six newest profiles
          setUsers(sortedProfiles.slice(0, 7));
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <Swiper
      spaceBetween={40}
      slidesPerView={1}
      navigation
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      breakpoints={{
        // when window width is <= 640px
        640: {
          slidesPerView: 1, // Set to 1 slide per view for mobile view
          spaceBetween: 20,
        },
        // when window width is <= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is <= 1024px
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
    >
      {users.map((user, index) => (
        <SwiperSlide key={index}>
          <div>
            <Link key={index} to={`/user_profile/${user.id}`}>
              <img
                className="recent_matches"
                src={
                  (user.tblGalleries[0] &&
                    `https://sams.drmarins.cloud/${user.tblGalleries[0].profilePic}`) ||
                  userImg
                }
                alt={user.imgAlt || "Alternative Image"}
              />
            </Link>
            <div className="blcak_bend">
              <h6>{user.tblBasicInfos[0].firstName}</h6>
              <p>{`${user.tblBasicInfos[0].age} Yrs, ${user.tblBasicInfos[0].height}`}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default NewMatchesItem;
