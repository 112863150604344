import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import userImg from "../../assets/images/blank-profile.png";

import logo from "../../assets/images/newLogo.png";
import Instence from "../../Utils/api";

const contactnumber = "+91 7702201206 | 040 49507030";
const location = "Hyderabad";

const Header = () => {
  const navigate = useNavigate();

  const userLogin = localStorage.getItem("userLogin");

  useEffect(() => {
    const handleScroll = () => {
      var value = window.scrollY;
      var headerSection = document.querySelector(".header-section");
      if (headerSection !== null) {
        if (value > 200) {
          headerSection.classList.add("header-fixed", "fadeInUp");
        } else {
          headerSection.classList.remove("header-fixed", "fadeInUp");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ! UseStates
  const [images, setImages] = useState([]);
  // ! images fetching data
  // Define fetchData outside of useEffect
  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userData");
      const userDataParsed = JSON.parse(userId);
      const id = userDataParsed.id;

      const response = await Instence.get(`/User/gallery/${id}`);
      setImages(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData inside useEffect
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <header className="header-section">
      <div className="header-top">
        <div className="container">
          <div className="header-top-area">
            <ul className="left">
              <li>
                <i className="icofont-ui-call"></i> <span>{contactnumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {location}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo logo-div">
              {userLogin ? (
                <Link to="/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              ) : (
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              )}
            </div>
            <div className="menu-area">
              {/* <ul className="menu">
                <li>
                  <NavLink to="/">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/community">Matches</NavLink>
                </li>
                <li>
                  <NavLink to="/community">Chat</NavLink>
                </li>
                <li>
                  <NavLink to="/community">Notifications</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard">Profile</NavLink>
                </li>
              </ul> */}

              {userLogin ? (
                <div
                  onClick={() => navigate("/dashboard")}
                  className="profile_section"
                >
                  Dashboard
                  <div className="img-profile-div">
                    <img
                      className="Profile_img"
                      src={`https://sams.drmarins.cloud/${images}` || userImg}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div className="menu-area">
                  <h6>Already a member? </h6>
                  <NavLink to="/login">
                    <button className="login-bttn">LOGIN</button>
                  </NavLink>
                </div>
              )}
              {/* <div className="header-bar d-lg-none" onClick={menuTrigger}>
                <span></span>
                <span></span>
                <span></span>
              </div> */}
              {/* <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                <i className="icofont-info-square"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
