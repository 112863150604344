import React, { Component } from 'react';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import AllPhotos from '../component/section/photos';


class ProfilePage extends Component {
    render() {
        return (
            <div>
                <Header />
                <PageHeader title={'Profile Single'} />
                <section className="profile-section padding-tb">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="member-profile">
                                <div className="profile-item">
                                    <div className="profile-cover">
                                        <img src="assets/images/profile/cover.jpg" alt="cover-pic" />
                                        <div className="edit-photo custom-upload">
                                            <div className="file-btn"><i className="icofont-camera"></i>
                                                Edit Photo</div>
                                            <input type="file" />
                                        </div>
                                    </div>
                                    <div className="profile-information">
                                        <div className="profile-pic">
                                            <img src="assets/images/profile/Profile.jpg" alt="DP" />
                                            <div className="custom-upload">
                                                <div className="file-btn">
                                                    <span className="d-none d-lg-inline-block"> <i className="icofont-camera"></i>
                                                        Edit</span>
                                                    <span className="d-lg-none mr-0"><i className="icofont-plus"></i></span></div>
                                                <input type="file" />
                                            </div>
                                        </div>
                                        <div className="profile-name">
                                            <h4>Ramana Bandapu</h4>
                                            <p>Active 02 Minutes Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-item d-none">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <a href="#"><img src="assets/images/profile/Profile.jpg" alt="profile" /></a>
                                        </div>
                                        <div className="lab-content">
                                            <div className="profile-name">
                                                <div className="p-name-content">
                                                    <h4>Ramana Bandapu</h4>
                                                    <p>Active 02 Minutes Ago</p>
                                                </div>
                                                <div className="contact-button">
                                                    <button className="contact-btn">
                                                        <i className="icofont-info-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <ul className="profile-contact">
                                                <li>
                                                    <a href="#">
                                                        <div className="icon"><i className="icofont-user"></i></div>
                                                        <div className="text">
                                                            <p>Add Friends</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <div className="icon"><i className="icofont-envelope"></i></div>
                                                        <div className="text">
                                                            <p>Publice Message</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <div className="icon"><i className="icofont-envelope"></i></div>
                                                        <div className="text">
                                                            <p>Private Message</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-details">
                                    <nav className="profile-nav">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="true">Profile</button>
                                            <button className="nav-link" id="nav-photos-tab" data-bs-toggle="tab"
                                                data-bs-target="#photos" type="button" role="tab" aria-controls="photos"
                                                aria-selected="false">Photos</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <article>
                                                            <div className="info-card mb-20">
                                                                <div className="info-card-title">
                                                                    <h6>Base Info</h6>
                                                                </div>
                                                                <div className="info-card-content">
                                                                    <ul className="info-list">
                                                                        <li>
                                                                            <p className="info-name">Name</p>
                                                                            <p className="info-details">Ramana Bandapu</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">I'm a</p>
                                                                            <p className="info-details">Woman</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Loking for a</p>
                                                                            <p className="info-details">Men</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Marital Status</p>
                                                                            <p className="info-details">Single</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Age</p>
                                                                            <p className="info-details">36</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Date of Birth</p>
                                                                            <p className="info-details">27-02-1996</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Address</p>
                                                                            <p className="info-details">Streop Rd, Peosur, Inphodux,
                                                                                USA.</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="info-card mb-20">
                                                                <div className="info-card-title">
                                                                    <h6>Myself Summary</h6>
                                                                </div>
                                                                <div className="info-card-content">
                                                                    <p>Collaboratively innovate compelling mindshare after
                                                                        prospective partnerships Competently sereiz long-term
                                                                        high-impact internal or "organic" sources via user friendly
                                                                        strategic themesr areas creat Dramatically coordinate
                                                                        premium partnerships rather than standards compliant
                                                                        technologies ernd Dramatically matrix ethical collaboration
                                                                        and idea-sharing through opensource methodologies and
                                                                        Intrinsicly grow collaborative platforms vis-a-vis effective
                                                                        scenarios. Energistically strategize cost effective ideas
                                                                        before the worke unde.</p>
                                                                </div>
                                                            </div>
                                                            <div className="info-card mb-20">
                                                                <div className="info-card-title">
                                                                    <h6>Looking For</h6>
                                                                </div>
                                                                <div className="info-card-content">
                                                                    <ul className="info-list">
                                                                        <li>
                                                                            <p className="info-name">Things I'm looking for</p>
                                                                            <p className="info-details">I want a funny person</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Whatever I like</p>
                                                                            <p className="info-details">I like to travel a lot</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="info-card mb-20">
                                                                <div className="info-card-title">
                                                                    <h6>Lifestyle</h6>
                                                                </div>
                                                                <div className="info-card-content">
                                                                    <ul className="info-list">
                                                                        <li>
                                                                            <p className="info-name">Interest</p>
                                                                            <p className="info-details">Dogs,Cats</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Favorite vocations spot</p>
                                                                            <p className="info-details">Maldives, Bangladesh</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Looking for</p>
                                                                            <p className="info-details">Serious Relationshiop,Affair</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Smoking</p>
                                                                            <p className="info-details">Casual Smoker</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Language</p>
                                                                            <p className="info-details">English, French, Italian</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="info-card">
                                                                <div className="info-card-title">
                                                                    <h6>Physical info</h6>
                                                                </div>
                                                                <div className="info-card-content">
                                                                    <ul className="info-list">
                                                                        <li>
                                                                            <p className="info-name">Height</p>
                                                                            <p className="info-details">5'8 ft</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Weight</p>
                                                                            <p className="info-details">72 kg</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Hair Color</p>
                                                                            <p className="info-details">Black</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Eye Color</p>
                                                                            <p className="info-details">Brown</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Body Type</p>
                                                                            <p className="info-details">Tall</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="info-name">Ethnicity</p>
                                                                            <p className="info-details">Middle Eastern</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="nav-photos-tab">
                                            <AllPhotos />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default ProfilePage;