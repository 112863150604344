import React, { useState } from "react";
import Instence from "../Utils/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const FamilyDetails = ({ onUpdateUserLogin }) => {
  const navigate = useNavigate();

  console.log(onUpdateUserLogin);

  const [maritalStatus, setMaritalStatus] = useState("");
  const [height, setHeight] = useState("");
  const [familyStatus, setFamilyStatus] = useState("");
  const [familyType, setFamilyType] = useState("");
  const [familyValues, setFamilyValues] = useState("");
  const [disability, setDisability] = useState("");

  const formDataString = localStorage.getItem("formData");
  const formfamilyDataaa = JSON.parse(formDataString);
  console.log(formfamilyDataaa);

  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  const postData = async (event) => {
    event.preventDefault();
    try {
      const response = await Instence.post("/User/signup", {
        userName: formfamilyDataaa.email,
        password: formfamilyDataaa.password,
        phone: formfamilyDataaa.phone,
        status: true,
        tblBasicInfos: [
          {
            onBehalf: formfamilyDataaa.onBehalf,
            firstName: formfamilyDataaa.fullName,
            lastName: "",
            gender: formfamilyDataaa.gender,
            age: formfamilyDataaa.age,
            dob: formfamilyDataaa.dateOfBirth,
            physicalStatus: disability,
            height: height,
            weight: "",
            bodyType: "",
            martialStatus: maritalStatus,
            motherTonge: formfamilyDataaa.language,
            eatingHabbit: "",
            smokingHabit: true,
            drinkingHabit: true,
            livesIn: "",
            citizenship: "",
            hobbiesIntrest: "",
          },
        ],
        tblFamilyInfos: [
          {
            familyValue: familyValues,
            familyType: familyType,
            familyStatus: familyStatus,
            fatherOccupation: "",
            motherOccupation: "",
            noBrothers: 0,
            brotherMarried: 0,
            noSisters: 0,
            siterMarried: 0,
            familyLocation: "",
            aboutMyFamily: "",
          },
        ],
        tblLocations: [
          {
            countryLivingIn: "",
            residingState: "",
            residingCityDistrict: "",
            native: "",
          },
        ],
        tblProffessionInformations: [
          {
            highestEducation: formfamilyDataaa.highestEducation,
            educationalInDetails: "",
            collegeInstitution: "",
            employeedIn: "",
            occupation: formfamilyDataaa.occupation,
            occupationInDetail: "",
            organaization: "",
            annualIncome: formfamilyDataaa.annualIncome,
          },
        ],
        tblReligions: [
          {
            religion: formfamilyDataaa.religion.value,
            caste: formfamilyDataaa.religion.value === "Christian" ? formfamilyDataaa.caste : formfamilyDataaa.selectedCaste.value,
            subCaste: formfamilyDataaa.subCaste,
            gothram: formfamilyDataaa.gothram,
            starRaasi: "",
            dosham: formfamilyDataaa.dosham,
            division: formfamilyDataaa.religion.value === "Christian" ?  formfamilyDataaa.selectedCaste.value : null,
          },
        ],
      });
      setResponseData(response.data);
      console.log(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data saved successfully!',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
      navigate("/dashboard");
      onUpdateUserLogin(true);
      localStorage.setItem("userLogin", true);
      if (response.data.statusCode === 200) {
        localStorage.removeItem("formData");
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.statusData)
        );
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message); // Assuming the error response has a "message" field
      } else {
        setError('An error occurred while processing your request.');
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred!',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    }
  };
  return (
    <div className="basic-details-container">
      <div className="container-fluid">
        <div className="row ">
          <div className="container">
            <div className="row basic-screen">
              <div className="col-md-4 loving-companion">
                <img src="../assets/images/banner/couple-logo.png" />
              </div>
              <div className="col-md-8 bg-white right-form">
                <div className="heading-details">
                  <p className="text-black">
                    FAMILY DETAILS HELP US TO FIND YOUR BEST PARTNER
                  </p>
                </div>
                <div className="basic-input-form">
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="highestEducation">Martial Status</label>
                      <select
                        id="highestEducation"
                        value={maritalStatus}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Never Married">Never Married</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Awaiting Divorce">
                          Awaiting Divorce
                        </option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="highestEducation">
                        Height (Feet/Inches)
                      </label>
                      <select
                        id="highestEducation"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="4ft6in">4ft 6In / 137cm</option>
                        <option value="4ft7in">4ft 7In / 139cm</option>
                        <option value="4ft8in">4ft 8In / 142cm</option>
                        <option value="4ft9in">4ft 9In / 144cm</option>
                        <option value="4ft10in">4ft 10In / 147cm</option>
                        <option value="4ft11in">4ft 11In / 150cm</option>
                        <option value="5ft0in">5ft 0In / 152cm</option>
                        <option value="5ft1in">5ft 1In / 155cm</option>
                        <option value="5ft2in">5ft 2In / 157cm</option>
                        <option value="5ft3in">5ft 3In / 160cm</option>
                        <option value="5ft4in">5ft 4In / 163cm</option>
                        <option value="5ft5in">5ft 5In / 165cm</option>
                        <option value="5ft6in">5ft 6In / 168cm</option>
                        <option value="5ft7in">5ft 7In / 170cm</option>
                        <option value="5ft8in">5ft 8In / 173cm</option>
                        <option value="5ft9in">5ft 9In / 175cm</option>
                        <option value="5ft10in">5ft 10In / 178cm</option>
                        <option value="5ft11in">5ft 11In / 180cm</option>
                        <option value="6ft0in">6ft 0In / 183cm</option>
                        <option value="6ft1in">6ft 1In / 185cm</option>
                        <option value="6ft2in">6ft 2In / 188cm</option>
                        <option value="6ft3in">6ft 3In / 190cm</option>
                        <option value="6ft4in">6ft 4In / 193cm</option>
                        <option value="6ft5in">6ft 5In / 196cm</option>
                        <option value="6ft6in">6ft 6In / 198cm</option>
                        <option value="6ft7in">6ft 7In / 201cm</option>
                        <option value="6ft8in">6ft 8In / 203cm</option>
                        <option value="6ft9in">6ft 9In / 206cm</option>
                        <option value="6ft10in">6ft 10In / 208cm</option>
                        <option value="6ft11in">6ft 11In / 211cm</option>
                        <option value="7ft0in">7ft 0In / 213cm</option>
                        <option value="7ft1in">7ft 1In / 216cm</option>
                        <option value="7ft2in">7ft 2In / 218cm</option>
                        <option value="7ft3in">7ft 3In / 221cm</option>
                        <option value="7ft4in">7ft 4In / 224cm</option>
                        <option value="7ft5in">7ft 5In / 226cm</option>
                        <option value="7ft6in">7ft 6In / 229cm</option>
                        <option value="7ft7in">7ft 7In / 231cm</option>
                        <option value="7ft8in">7ft 8In / 234cm</option>
                        <option value="7ft9in">7ft 9In / 236cm</option>
                        <option value="7ft10in">7ft 10In / 239cm</option>
                        <option value="7ft11in">7ft 11In / 241cm</option>
                        <option value="8ft0in">8ft 0In / 244cm</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="employed">Family Status</label>
                      <select
                        id="employed"
                        value={familyStatus}
                        onChange={(e) => setFamilyStatus(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Middle Class">Middle Class</option>
                        <option value="Uppeer Middle Class">
                          Uppeer Middle Class
                        </option>
                        <option value="High Class">High Class</option>
                        <option value="Rich ">Rich / Affluent</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="employed">Family Type</label>
                      <select
                        id="employed"
                        value={familyType}
                        onChange={(e) => setFamilyType(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Joint">Joint</option>
                        <option value="Nuclear">Nuclear</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="employed">Family Values</label>
                      <select
                        id="employed"
                        value={familyValues}
                        onChange={(e) => setFamilyValues(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Orthodox">Orthodox</option>
                        <option value="Traditional">Traditional</option>
                        <option value="Moderate">Moderate</option>
                        <option value="Liberal">Liberal</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <label htmlFor="employed">Any Disability</label>
                      <select
                        id="employed"
                        value={disability}
                        onChange={(e) => setDisability(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="No Disability">No Disability</option>
                        <option value="Physical Challenged">
                          Physical Challenged
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="profile-btn-div">
                    <button className="profile-btn" onClick={postData}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FamilyDetails;
