import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePages from "./pages/home";
import MembersPage from "./pages/members";
import ProfilePage from "./pages/profile";
import LoginPage from "./pages/login";
import SignInPage from "./pages/signin";
import PricingPlanPage from "./pages/pricingplan";
import ForeZeroPage from "./pages/forezero";
import CommunityPage from "./pages/community";
import BlogPage from "./pages/blog";
import BlogSinglePage from "./pages/blogsingle";
import ContactPage from "./pages/contact";
import ScrollToTop from "./component/layout/scralltop";
import HeadTabs from "./Dashboard/HeadTabs";
import StepperForm from "./Dashboard/MyProfilecomp/StepperForm";
import ErrorBoundary from "./Utils/ErrorBoundary";
import { useEffect, useState } from "react";
import UserProfiles from "./pages/UserProfiles";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms_Conditions from "./pages/Terms_Conditions";
import Refund_Cancellation from "./pages/Refund_Cancellation";
import EmailLogin from "./pages/EmailLogin";

function App() {
  const [userLogin, setUserLogin] = useState(false);

  useEffect(() => {
    // Check if user is logged in
    const userLoggedIn = localStorage.getItem("userLogin");
    if (userLoggedIn) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
  }, []);

  const handleUserLoginUpdate = (isLoggedIn) => {
    setUserLogin(isLoggedIn);
  };

  return (
    <ErrorBoundary>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePages />} />
            <Route path="members" element={<MembersPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="user_profile/:id" element={<UserProfiles />} />
            {!userLogin &&
              <Route
                path="login"
                element={<LoginPage onUpdateUserLogin={handleUserLoginUpdate} />}
              />}
            {!userLogin &&
              <Route
                path="email"
                element={<EmailLogin onUpdateUserLogin={handleUserLoginUpdate} />}
              />}
            <Route path="signup" element={<SignInPage />} />
            {/* <Route path="pricing" element={<PricingPlanPage />} /> */}
            <Route path="*" element={<ForeZeroPage />} />
            <Route path="community" element={<CommunityPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog-single" element={<BlogSinglePage />} />
            <Route path="support" element={<ContactPage />} />

            {userLogin && <Route path="dashboard" element={<HeadTabs />} />}
            {!userLogin && (
              <Route
                path="detailsform"
                element={
                  <StepperForm onUpdateUserLogin={handleUserLoginUpdate} />
                }
              />
            )}

            <Route path="terms_conditions" element={<Terms_Conditions />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route
              path="refund_cancellation"
              element={<Refund_Cancellation />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
