import React, { Component } from "react";

const subtitle = "What our Customers Say";
const title = "Client’s Feed back Latest Reviews From My Clients";

let ClientsListContent = [
  {
    Desc: "Using Sams matrimony site was a game-changer; it helped me find my perfect match effortlessly.",
    Name: "Joseph Samuel",
    Digi: "Doctor",
    imgUrl: "assets/images/group/group-mem/01.jpg",
    imgAlt: "Client Thumb",
  },
  {
    Desc: "Thanks to Sams matrimony site, I found my soulmate and couldnt be happier",
    Name: "Teresa Suhasini",
    Digi: "Founder",
    imgUrl: "assets/images/group/group-mem/02.jpg",
    imgAlt: "Client Thumb",
  },
  {
    Desc: "This matrimony site provided the perfect platform for me to find my life partner; highly recommended!",
    Name: "Michael David",
    Digi: "Senior Software Developer",
    imgUrl: "assets/images/group/group-mem/03.jpg",
    imgAlt: "Client Thumb",
  },
  {
    Desc: "Finding love was made easy with Sams matrimony site; grateful for the wonderful experience!",
    Name: "Rose Maria",
    Digi: "Influencer",
    imgUrl: "assets/images/group/group-mem/04.jpg",
    imgAlt: "Client Thumb",
  },
  {
    Desc: "This matrimony site exceeded my expectations; I found my forever love here!",
    Name: "Vincent Paul",
    Digi: "Business",
    imgUrl: "assets/images/group/group-mem/05.jpg",
    imgAlt: "Client Thumb",
  },
  {
    Desc: "Using Sams matrimony site was the best decision I made; it led me to my happily ever after.",
    Name: "Abraham Mili",
    Digi: "Defence Lawyer",
    imgUrl: "assets/images/group/group-mem/06.jpg",
    imgAlt: "Client Thumb",
  },
];

class ClientSection extends Component {
  state = {};
  render() {
    return (
      <section className="clints-section padding-tb">
        <div className="container">
          <div className="section-header">
            <h4 className="theme-color">{subtitle}</h4>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="clients">
              {ClientsListContent.map((val, i) => (
                <div className="client-list" key={i}>
                  <div className="client-content">
                    <p> {val.Desc} </p>
                    <div className="client-info">
                      <div className="name-desi">
                        <h6>{val.Name}</h6>
                        <span>{val.Digi}</span>
                      </div>
                      <div className="rating">
                        <ul>
                          <li>
                            <i className="icofont-star"></i>
                          </li>
                          <li>
                            <i className="icofont-star"></i>
                          </li>
                          <li>
                            <i className="icofont-star"></i>
                          </li>
                          <li>
                            <i className="icofont-star"></i>
                          </li>
                          <li>
                            <i className="icofont-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="client-thumb">
                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ClientSection;
